export const NO_POSITIONAL = 'False';
export const WEEKLY_POSITIONAL = 'Positional.Weekly';
export const MONTHLY_POSITIONAL = 'Positional.Monthly';

export const PositionalKeys = ['Weekly Expiry', 'Monthly Expiry'];

export const weeklyPositional = 'Weekly';
export const monthlyPositional = 'Monthly';

export const PositionalMapping = {
    'Weekly Expiry': weeklyPositional,
    'Monthly Expiry': monthlyPositional,
};
