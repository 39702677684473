import React, { ChangeEvent, useEffect, useRef } from 'react';

type SliderProps = {
    label?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
    step?: number;
    rangeState: number;
};

export const Slider = ({ label, onChange, min, max, step, rangeState }: SliderProps) => {
    const rangeRef = useRef<HTMLInputElement>(null);

    const dummyChange = () => {};
    const handleChange = onChange || dummyChange;

    useEffect(() => {
        rangeRef.current!.value = rangeState.toString();
    }, [rangeState]);

    const newVal = Number(((rangeState - 0) * 100) / (100 - 0));

    return (
        <div className='relative w-full py-1'>
            {label && <label className='text-xsm font-normal leading-6'>{label}</label>}
            <input
                title='input-range'
                type='range'
                ref={rangeRef}
                min={min || 1}
                max={max || 99}
                onChange={handleChange}
                step={step || 1}
                className='h-1 w-full cursor-pointer appearance-none rounded-lg bg-secondaryBlue-900'
            />
            <div className='absolute top-0 flex ' style={{ left: `${newVal}%` }}>
                <div className='relative w-1'>
                    <div className='bottom-100 absolute -left-5  mb-2 min-w-full '>
                        <div className='relative shadow-md'>
                            <div className='-mt-4 truncate rounded bg-primaryBlack-800 px-3 py-1 text-xs text-white'>
                                {rangeRef?.current?.value || rangeState}
                            </div>
                            <svg
                                className='top-100 absolute left-0 h-2 w-full text-primaryBlack-800'
                                x='0px'
                                y='0px'
                                viewBox='0 0 255 255'
                                xmlSpace='preserve'>
                                <polygon className='fill-current' points='0,0 127.5,127.5 255,0'></polygon>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 left-0 -mb-6 -ml-1 font-semibold text-primaryBlack-750'> {min || 1}</div>
            <div className='absolute bottom-0 right-0 -mb-6 -mr-1 font-semibold text-primaryBlack-750'>{max || 99}</div>
        </div>
    );
};
