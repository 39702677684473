import { INSTRUMENT_KIND_MAPPING } from 'constants/Execution';
import { NSETickers, Tickers, TickersMap } from 'constants/Tickers';
import { Dispatch } from 'react';
import { configActions } from 'store/configStore';
import { IndexSymbolType, SymbolDataType, SymbolType } from 'types/Chart';
import { dateToInt } from './Date';
import { uniqueElements } from './ArrayUtils';

const months: string[] = ['NA', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const instrumentOptions: string[] = Object.values(INSTRUMENT_KIND_MAPPING);

export const parseSymbol = (symbol: string, fullInfo: boolean = false): SymbolType | IndexSymbolType => {
    if (Object.values(TickersMap).includes(symbol)) {
        return { Ticker: symbol + ' (Index)', InstrumentType: '' };
    }
    symbol = symbol?.toUpperCase();
    const parsedData: SymbolType = {
        Symbol: '',
        Expiry: '',
        InstrumentType: '',
        Strike: 0,
        Ticker: '',
    };
    Tickers.map((ticker) => {
        if (symbol.startsWith(ticker.toUpperCase())) {
            parsedData.Ticker = ticker.toUpperCase();
        }
    });
    instrumentOptions.map((option) => {
        if (symbol.endsWith(option)) {
            parsedData.InstrumentType = option;
        }
    });

    if (fullInfo) {
        const tickerLength = parsedData.Ticker.length;
        parsedData.Expiry = parseExpiryFull(symbol.substring(tickerLength, tickerLength + 5));
        parsedData.Strike = parseFloat(symbol.substring(tickerLength + 5, symbol.length - parsedData.InstrumentType.length));
    }
    return parsedData;
};

const parseExpiryFull = (expiry: string) => {
    const year = '20' + expiry.substring(0, 2);
    let month: string, date;
    month = expiry.substring(2, 5);
    if (parseInt(month)) {
        month = expiry.substring(2, 3);
        if (month == 'O') {
            month = '10';
        } else if (month == 'N') {
            month = '11';
        } else if (month == 'D') {
            month = '12';
        }
        date = expiry.substring(3, 5);
        return `${date}-${months[parseInt(month)]}-${year}`;
    }
    return `${month}-${year}`;
};

export const FetchTodayExpiries = (Data: SymbolDataType[], dispatch: Dispatch<any>) => {
    const date = new Date();
    const today = dateToInt(date);
    const todayData = Data.filter((item) => !isNaN(item.Expiry) && item.Expiry === today);
    const NSEFutures = todayData.filter((item) => item.InstrumentType === undefined && NSETickers.includes(item.Underlying));

    const todayExpiries = todayData.reduce((acc: Record<string, string>, item) => {
        const ticker = item.Underlying.trim();
        if (acc[ticker]) return acc;
        const isNSE = NSETickers.includes(item.Underlying);

        if (isNSE) {
            if (NSEFutures.find((fut) => fut.Expiry === item.Expiry)) {
                acc[ticker] = ticker + '(M)';
            } else {
                acc[ticker] = ticker + '(W)';
            }
        } else {
            acc[ticker] = ticker;
        }
        return acc;
    }, {});

    dispatch(configActions.updateTodayExpiries(Object.values(todayExpiries)));
};

export const createCEPEComboSymbols = (symbols: string[]) => {
    const newSymbols: string[] = [];
    symbols.forEach((symbol) => {
        if (symbol.includes('FUT')) return;
        newSymbols.push(`${symbol.slice(0, -2)}CE`);
        newSymbols.push(`${symbol.slice(0, -2)}PE`);
    });

    return uniqueElements(newSymbols);
};
