import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY, CLIENT_ID_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Set up your API key and Vendor code by logging into',
                        },
                        {
                            type: 'link',
                            text: ' key generation ',
                            url: 'https://connect.thefirstock.com/login',
                        },
                        {
                            type: 'text',
                            text: 'and generate a vendor code and API key.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Paste the generated vendor code and API key below',
                        },
                    ],
                },
            },
        ],
    },
];

export const FIRSTOCK_INPUT_FIELD_LIST = [
    {
        name: CLIENT_ID_IDENTITY,
        label: 'Vendor Code (Case-sensitive, should be all capital letters and Without _API)',
        placeholder: 'Enter Firstock Vendor Code',
        defaultValue: '',
    },
    {
        name: API_KEY_IDENTITY,
        label: 'API Key',
        placeholder: 'API Key',
        defaultValue: '',
    },
];

export default instructions;
