import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useRef } from 'react';

type DateInputProps = {
    defaultValue?: string;
    disabled?: boolean;
    label?: string;
    inputClass?: string;
    vertical?: boolean;
    onChange?: () => void;
};

export const DateInput = React.forwardRef(({ defaultValue, disabled, label, vertical, inputClass, onChange }: DateInputProps, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const getValue = () => {
        const date = new Date(inputRef.current!.value);
        return {
            Year: date.getFullYear(),
            Month: 1 + date.getMonth(),
            Day: date.getDate(),
        };
    };

    useEffect(() => {
        inputRef.current!.value = defaultValue as string;
    }, [defaultValue]);

    useImperativeHandle(ref, () => {
        return {
            getValue: getValue,
        };
    });

    const handleChange = () => {
        if (onChange) {
            onChange();
        }
    };

    return (
        <div
            className={classNames('flex items-center justify-between gap-5', {
                'pointer-events-none opacity-80': disabled,
                'flex-col !items-start !gap-2': vertical,
            })}>
            {label && <label className='text-xsm font-medium leading-6'>{label}</label>}
            <input
                disabled={disabled}
                className={classNames('rounded border border-primaryBlue-200 px-2 py-1 text-xs', inputClass)}
                type='date'
                ref={inputRef}
                step='1'
                title='date-input'
                onChange={handleChange}
            />
        </div>
    );
});
