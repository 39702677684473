import { MOTILAL_TAG } from 'constants/ConfirmBroker';
import history from 'history';
import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY, CLIENT_ID_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'You will need to provide consent to use open API. Go to ',
                        },
                        {
                            type: 'link',
                            text: 'onlinetrade.motilaloswal.com/emailers/TGS/2022/Mailer/Jun22/14Jun2022/API-consent-Mailer.html',
                            url: 'https://onlinetrade.motilaloswal.com/emailers/TGS/2022/Mailer/Jun22/14Jun2022/API-consent-Mailer.html',
                        },
                        {
                            type: 'text',
                            text: ' and follow the given instructions.',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'https://algotest.in/confirm_broker/' + MOTILAL_TAG,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `You also need setup TOTP for your account. Learn more here  `,
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Copy your API key and Client ID and paste them here`,
                        },
                    ],
                },
            },
        ],
    },
];

export const MOTILAL_INPUT_FIELD_LIST = [
    {
        name: CLIENT_ID_IDENTITY,
        label: 'Client ID',
        placeHolder: 'Enter Client ID',
        defaultValue: '',
    },
    {
        name: API_KEY_IDENTITY,
        label: 'API Key',
        placeHolder: 'Enter API Key',
        defaultValue: '',
    },
];

export const MOTILAL_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    history.push(`https://invest.motilaloswal.com/OpenAPI/Login.aspx?apikey=${encodeURIComponent(appKey)}`);
};

export default instructions;
