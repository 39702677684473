import { createSlice } from '@reduxjs/toolkit';
import { defaultComparison } from 'constants/ComparisonType';
import { monthlyExpiry } from 'constants/ExpiryType';
import { findHighestExpiry } from 'utils/ResultUtils';

const initialPortfolioState = {
    portfolioList: [],
    activePortfolio: null,
    progress: 0,
};

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState: initialPortfolioState,
    reducers: {
        updatePortfolioList(state, action) {
            state.portfolioList = action.payload;
        },
        addPortfolio(state, action) {
            state.portfolioList.push(action.payload);
        },
        deletePortfolio(state, action) {
            state.portfolioList = state.portfolioList.filter((portfolio) => portfolio._id !== action.payload);
        },
        resetActivePortfolio(state) {
            state.activePortfolio = null;
        },
        setActivePortfolio(state, action) {
            const name = action.payload.name;
            const id = action.payload.id;
            const selectedStrategies = action.payload.strategies.map((strategy) => ({
                ...strategy,
                highestExpiry: findHighestExpiry(strategy.legs_expiries) ?? monthlyExpiry,
                DTE: { enabled: false, value: 0, comparator: defaultComparison },
            }));
            state.activePortfolio = { name, id, selectedStrategies };
        },
        resetProgress(state) {
            state.progress = 0;
        },
        setProgress(state, action) {
            state.progress = action.payload;
        },
    },
});

export const portfolioActions = portfolioSlice.actions;
