import { AppKeysType, BrokerSettingsRequestType, BrokerType, EdelweissConsentSuccessType } from 'types/Broker';
import { axiosInstance, handleAuthenticatedError } from 'utils/API';

export const GetBroker = async () => {
    const getBrokerEndpoint = '/brokers';
    try {
        const response = await axiosInstance.get(getBrokerEndpoint);
        return { Status: true, Data: response.data.brokers as BrokerType[] } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const DeleteBroker = async (id: string) => {
    const deleteBrokerEndpoint = '/broker/' + id;
    try {
        await axiosInstance.delete(deleteBrokerEndpoint);
        return { Status: true, Msg: 'Broker Successfully Deleted' } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const PostBroker = async (broker: string, alias: string) => {
    const postBrokerEndpoint = `/broker`;
    const postBrokerObject = { broker, alias };
    try {
        const response = await axiosInstance.post(postBrokerEndpoint, postBrokerObject);
        // Change _id to id
        return { Status: true, Data: response.data._id as string } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const PutBroker = async (brokerId: string, app_keys: AppKeysType) => {
    const postBrokerEndpoint = `/broker/${brokerId}`;
    const postBrokerObject = { app_keys };
    try {
        const response = await axiosInstance.put(postBrokerEndpoint, postBrokerObject);
        return { Status: true, Data: response.data, Msg: 'Broker Successfully Created' } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const BrokerConfirm = async (path: string) => {
    const endpoint = path;
    try {
        const response = await axiosInstance.get(endpoint);
        return { Status: true, Msg: response.data.msg as string } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const BrokerSettings = async (broker_id: string, params: BrokerSettingsRequestType) => {
    const endpoint = `/update_broker_settings/${broker_id}`;
    try {
        const response = await axiosInstance.put(endpoint, params);
        return { Status: true, Msg: response.data.msg as string } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const DhanBrokerConsent = async (brokerId: string) => {
    const endpoint = `/broker_login/dhan_consent/${brokerId}`;
    try {
        const response = await axiosInstance.get(endpoint);
        return { Status: true, Data: response.data.consent_id as string } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const KotakOTP = async (phoneNumber: string, password: string, brokerId: string) => {
    const endpoint = `/broker_login/kotak_auth/${brokerId}`;
    try {
        const response = await axiosInstance.get(endpoint, { params: { phoneNumber, password } });
        return { Status: true, Msg: response.data.msg as string } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const EdelweissBrokerConsent = async (brokerId: string) => {
    const endpoint = `/broker_login/edelweiss_consent/${brokerId}`;
    try {
        const response = await axiosInstance.get(endpoint);
        return { Status: true, Data: response.data } as EdelweissConsentSuccessType;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};

export const ChoiceOTP = async (userId: string, password: string, brokerId: string) => {
    const endpoint = `/broker_login/choice_otp/${brokerId}`;
    try {
        const response = await axiosInstance.get(endpoint, { params: { user_id: userId, password } });
        return { Status: true, Msg: response.data.msg as string } as const;
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};
