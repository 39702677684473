import classNames from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import { ulid } from 'ulid';
import { ExposeGetValue } from 'utils/ExposeGetValue';
import { Tooltip } from '../Tooltip';

type RadioButtonProps = {
    items: string[];
    defaultValue?: string;
    onChange?: (e: string) => void;
    valueMap: { [key: string]: string };
    display?: boolean;
    type?: string;
    disabled?: boolean;
    label?: string;
    tooltipContent?: string;
    className?: string;
    isSecondary?: boolean;
};

export const RadioButtons = React.forwardRef(
    ({ items, defaultValue, onChange, valueMap, display, type, disabled, label, tooltipContent, className, isSecondary }: RadioButtonProps, ref) => {
        const length = items.length;
        const { current: name } = useRef<any>(ulid());
        const [selected, setSelected] = useState(defaultValue);

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            setSelected(valueMap![e.target.value]);
            if (onChange) {
                onChange(valueMap![e.target.value]);
            }
        };

        const getValue = () => {
            return selected;
        };
        ExposeGetValue(ref, getValue);

        return (
            <div
                className={classNames('flex flex-wrap items-center justify-between gap-2', {
                    hidden: !display,
                    'flex-col !items-start': type === 'vertical',
                    'pointer-events-none opacity-80': disabled,
                })}>
                {label && (
                    <label className={classNames('flex items-center gap-2 text-xsm font-normal leading-6')}>
                        {label}
                        {tooltipContent && <Tooltip info={tooltipContent} />}
                    </label>
                )}

                <div className='flex'>
                    {items.map((item, index) => (
                        <label
                            key={index}
                            htmlFor={`toggle--input__${index}_${name}`}
                            className={classNames('cursor-pointer border border-tertiaryBlue-900  px-2 py-2 text-xs ', ' md:text-xs/4', className, {
                                'border-secondaryBlue-900': isSecondary,
                                'bg-tertiaryBlue-900 text-white': selected === valueMap[item] && !isSecondary,
                                'bg-white text-primaryBlack-500': selected !== valueMap[item] && isSecondary,
                                'bg-secondaryBlue-900 text-white': selected === valueMap[item] && isSecondary,
                                'rounded-l': index == 0,
                                'rounded-r': index == length - 1,
                            })}>
                            <input
                                hidden
                                onChange={handleChange}
                                id={`toggle--input__${index}_${name}`}
                                type='radio'
                                checked={selected === valueMap![item]}
                                disabled={disabled}
                                value={item}
                                className='hidden'
                            />
                            {item}
                        </label>
                    ))}
                </div>
            </div>
        );
    }
);
