import history from 'history';
import { InstructionType } from 'types/Broker';
import { UPSTOX_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'login.upstox.com ',
                            url: 'https://login.upstox.com/',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Go to "My Account" section, choose the App tab, then click on New App',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Enter any App Name.',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'https://algotest.in/confirm_broker/' + UPSTOX_TAG,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on App Details. Copy your API Key and Secret, and paste them below.',
                        },
                    ],
                },
            },
        ],
    },
];

export const UPSTOX_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'API key',
        placeHolder: 'Enter API Key',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'API Secret',
        placeHolder: 'Enter Secret Key',
        defaultValue: '',
    },
];

export const UPSTOX_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    const base_uri = window.location.origin;
    const redirect_uri = `${base_uri}/confirm_broker/${UPSTOX_TAG}/${broker_id}`;
    const payload = new URLSearchParams({ response_type: 'code', client_id: appKey, redirect_uri: redirect_uri, state: appKey }).toString();
    history.push(`https://api-v2.upstox.com/login/authorization/dialog?${payload}`);
};

export default instructions;
