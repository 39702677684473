// import LandingHeader from 'components/Navigation/LandingHeader';
import DefaultLayout from './DefaultLayout';
import dynamic from 'next/dynamic';
const LandingHeader = dynamic(() => import('components/Navigation/LandingHeader'), {
    ssr: false,
});

const LandingLayout = ({ children, ...props }) => {
    return (
        <div>
            <LandingHeader />
            <DefaultLayout {...props}>{children}</DefaultLayout>
        </div>
    );
};

export default LandingLayout;
