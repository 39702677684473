export const StrategyTypeLabels = ['Intraday', 'BTST', 'Positional'];

export const StrategyTypeMap = {
    Intraday: 'StrategyType.IntradaySameDay',
    BTST: 'StrategyType.IntradayBTST',
    Positional: 'StrategyType.IntradaySameDay-Positional',
} as const;

export const intradayStrategy = StrategyTypeMap['Intraday'];

export const defaultStrategyType = intradayStrategy;
