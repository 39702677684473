import { FinvasiaLoginFields } from 'components/Broker/BrokerLoginFields/Finvasia';
import { FirstockLoginFields } from 'components/Broker/BrokerLoginFields/Firstock';
import { KotakLoginFields } from 'components/Broker/BrokerLoginFields/Kotak';
import { ZebuLoginFields } from 'components/Broker/BrokerLoginFields/Zebu';
import { ChoiceLoginFields } from 'components/Broker/BrokerLoginFields/Choice';
import history from 'history';
import ACAgarwalInstructions from './BrokerInstructions/ACAgarwal';
import AliceblueInstructions, { ALICEBLUE_INPUT_FIELD_LIST, ALICEBLUE_LOGIN_METHOD } from './BrokerInstructions/AliceBlue';
import AngelOneInstructions, { ANGELONE_INPUT_FIELD_LIST, ANGELONE_LOGIN_METHOD } from './BrokerInstructions/Angelone';
import DhanInstructions, { DHAN_INPUT_FIELD_LIST, DHAN_LOGIN_METHOD } from './BrokerInstructions/Dhan';
import EdelweissInstructions, { EDELWEISS_INPUT_FIELD_LIST, EDELWEISS_LOGIN_METHOD } from './BrokerInstructions/Edelweiss';
import FinvasiaInstructions, { FINVASIA_INPUT_FIELD_LIST } from './BrokerInstructions/Finvasia';
import FirstockInstructions, { FIRSTOCK_INPUT_FIELD_LIST } from './BrokerInstructions/Firstock';
import FivePaisaInstructions from './BrokerInstructions/FivePaisa';
import JMFLInstructions from './BrokerInstructions/JMFL';
import FlattradeInstructions, { FLATTRADE_INPUT_FIELD_LIST, FLATTRADE_LOGIN_METHOD } from './BrokerInstructions/Flattrade';
import FyersInstruction, { FYERS_INPUT_FIELD_LIST, FYERS_LOGIN_METHOD } from './BrokerInstructions/Fyers';
import ICICInstructions, { ICICI_INPUT_FIELD_LIST, ICICI_LOGIN_METHOD } from './BrokerInstructions/ICICIDirect';
import IIFLInstructions from './BrokerInstructions/IIFL';
import JainamInstructions, { JAINAM_INPUT_FIELD_LIST, JAINAM_LOGIN_METHOD } from './BrokerInstructions/Jainam';
import JainamPropInstructions from './BrokerInstructions/JainamProp';
import KotakInstructions, { KOTAK_INPUT_FIELD_LIST } from './BrokerInstructions/Kotak';
import MastertrustInstructions, { MASTERTRUST_INPUT_FIELD_LIST, MASTERTRUST_LOGIN_METHOD } from './BrokerInstructions/Mastertrust';
import MotilalInstructions, { MOTILAL_INPUT_FIELD_LIST, MOTILAL_LOGIN_METHOD } from './BrokerInstructions/Motilal';
import UpstoxInstructions, { UPSTOX_INPUT_FIELD_LIST, UPSTOX_LOGIN_METHOD } from './BrokerInstructions/Upstox';
import PaytmInstructions, { PAYTM_INPUT_FIELD_LIST, PAYTM_LOGIN_METHOD } from './BrokerInstructions/Paytm';
import WisdomInstructions from './BrokerInstructions/Wisdom';
import { XTS_INPUT_FIELD_LIST } from './BrokerInstructions/XTS';
import ZebuInstructions, { ZEBU_INPUT_FIELD_LIST } from './BrokerInstructions/Zebu';
import ZerodhaInstructions, { ZERODHA_INPUT_FIELD_LIST, ZERODHA_LOGIN_METHOD } from './BrokerInstructions/Zerodha';
import ChoiceInstructions, { CHOICE_INPUT_FIELD_LIST } from './BrokerInstructions/Choice';
import {
    ALICEBLUE_TAG,
    ANGELONE_TAG,
    DHAN_TAG,
    EDELWEISS_TAG,
    FINVASIA_TAG,
    FIRSTOCK_TAG,
    FLATTRADE_TAG,
    FYERS_TAG,
    ICICI_TAG,
    JAINAM_TAG,
    KOTAK_TAG,
    MASTERTRUST_TAG,
    MOTILAL_TAG,
    UPSTOX_TAG,
    XTS_TAG,
    ZEBU_TAG,
    ZERODHA_TAG,
    PAYTM_TAG,
    CHOICE_TAG,
} from './ConfirmBroker';
import { BSE, NSE } from './Tickers';
import { BrokerObject } from 'types/Broker';

export const PAPER_TRADE_BROKER = 'Broker.Dummy';
export const ZERODHA_BROKER = 'Broker.Zerodha';
export const IIFL_BROKER = 'Broker.IIFL';
export const AC_AGRAWAL_BROKER = 'Broker.AcAgrawal';
export const FINVASIA_BROKER = 'Broker.Finvasia';
export const FIVE_PAISA_BROKER = 'Broker.FivePaisa';
export const KOTAK_BROKER = 'Broker.Kotak';
export const DHAN_BROKER = 'Broker.Dhan';
export const EDELWEISS_BROKER = 'Broker.Edelweiss';
export const FYERS_BROKER = 'Broker.Fyers';
export const JAINAM_BROKER = 'Broker.Jainam';
export const FLATTRADE_BROKER = 'Broker.FlatTrade';
export const ALICEBLUE_BROKER = 'Broker.AliceBlue';
export const MASTERTRUST_BROKER = 'Broker.MasterTrust';
export const ICICI_BROKER = 'Broker.ICICI';
export const MOTILAL_BROKER = 'Broker.Motilal';
export const ANGELONE_BROKER = 'Broker.AngelOne';
export const UPSTOX_BROKER = 'Broker.Upstox';
export const WISDOM_BROKER = 'Broker.WisdomCapital';
export const FIRSTOCK_BROKER = 'Broker.Firstock';
export const JAINAM_PROP_BROKER = 'Broker.JainamPro';
export const ZEBU_BROKER = 'Broker.Zebu';
export const JMFL_BROKER = 'Broker.JMFL';
export const PAYTM_BROKER = 'Broker.Paytm';
export const CHOICE_BROKER = 'Broker.Choice';

export const RedirectUrlBrokers = [
    ZERODHA_BROKER,
    FLATTRADE_BROKER,
    FYERS_BROKER,
    ICICI_BROKER,
    UPSTOX_BROKER,
    ANGELONE_BROKER,
    MOTILAL_BROKER,
    PAYTM_BROKER,
];
export const RedirectUrlBrokerTags = [ZERODHA_TAG, FLATTRADE_TAG, FYERS_TAG, ICICI_TAG, UPSTOX_TAG, ANGELONE_TAG, MOTILAL_TAG, PAYTM_TAG];

const XTS_LOGIN_PREFIX = (broker_id: string, broker: string) => `/confirm_broker/${XTS_TAG}/${broker_id}?xts_broker=${broker}`;
const XTS_LOGIN_METHOD = (broker_id: string, broker: string) => {
    history.push(XTS_LOGIN_PREFIX(broker_id, broker));
};

export const BrokerMapping: Record<string, BrokerObject> = {
    [PAPER_TRADE_BROKER]: {
        name: 'Forward Test',
        tag: null,
        instructions: [],
        InputFieldList: [],
        logo: '',
        MISExitTime: 15 * 60 + 29,
        exchanges: [NSE, BSE],
    },
    [EDELWEISS_BROKER]: {
        name: 'Nuvama Wealth',
        tag: EDELWEISS_TAG,
        on_login: EDELWEISS_LOGIN_METHOD,
        instructions: EdelweissInstructions,
        logo: '/brokers/nuvama.svg',
        InputFieldList: EDELWEISS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 19,
        exchanges: [NSE],
    },
    [IIFL_BROKER]: {
        name: 'IIFL',
        tag: XTS_TAG,
        on_login: (_, broker_id) => XTS_LOGIN_METHOD(broker_id, IIFL_BROKER),
        instructions: IIFLInstructions,
        logo: '/brokers/IIFL.svg',
        InputFieldList: XTS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 19,
        exchanges: [NSE, BSE],
    },
    [AC_AGRAWAL_BROKER]: {
        name: 'A.C. Agrawal Shares',
        tag: XTS_TAG,
        on_login: (_, broker_id) => XTS_LOGIN_METHOD(broker_id, AC_AGRAWAL_BROKER),
        instructions: ACAgarwalInstructions,
        logo: '/brokers/ACA.svg',
        InputFieldList: XTS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 24,
        exchanges: [NSE, BSE],
    },
    [ZERODHA_BROKER]: {
        name: 'Kite Connect',
        tag: ZERODHA_TAG,
        on_login: ZERODHA_LOGIN_METHOD,
        logo: '/brokers/zerodha2.svg',
        instructions: ZerodhaInstructions,
        InputFieldList: ZERODHA_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 24,
        exchanges: [NSE, BSE],
    },
    [FINVASIA_BROKER]: {
        name: 'Finvasia',
        tag: FINVASIA_TAG,
        login_popup: FinvasiaLoginFields,
        logo: '/brokers/finvasia.svg',
        instructions: FinvasiaInstructions,
        InputFieldList: FINVASIA_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 24,
        exchanges: [NSE, BSE],
    },
    [FIVE_PAISA_BROKER]: {
        name: '5Paisa',
        tag: XTS_TAG,
        on_login: (_, broker_id) => XTS_LOGIN_METHOD(broker_id, FIVE_PAISA_BROKER),
        instructions: FivePaisaInstructions,
        logo: '/brokers/5paisa.svg',
        InputFieldList: XTS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 19,
        exchanges: [NSE, BSE],
    },
    [KOTAK_BROKER]: {
        name: 'Kotak Neo',
        tag: KOTAK_TAG,
        login_popup: KotakLoginFields,
        logo: '/brokers/kotak.svg',
        instructions: KotakInstructions,
        InputFieldList: KOTAK_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 19,
        exchanges: [NSE, BSE],
    },
    [DHAN_BROKER]: {
        name: 'Dhan',
        tag: DHAN_TAG,
        on_login: DHAN_LOGIN_METHOD,
        instructions: DhanInstructions,
        logo: '/brokers/dhan.svg',
        InputFieldList: DHAN_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 18,
        exchanges: [NSE, BSE],
    },
    [FYERS_BROKER]: {
        name: 'Fyers',
        tag: FYERS_TAG,
        on_login: FYERS_LOGIN_METHOD,
        logo: '/brokers/fyers.svg',
        instructions: FyersInstruction,
        InputFieldList: FYERS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 9,
        exchanges: [NSE, BSE],
    },
    [JAINAM_BROKER]: {
        name: 'Jainam (Duck)',
        tag: JAINAM_TAG,
        on_login: JAINAM_LOGIN_METHOD,
        logo: '/brokers/jainam.svg',
        instructions: JainamInstructions,
        InputFieldList: JAINAM_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 19,
        exchanges: [NSE, BSE],
    },
    [FLATTRADE_BROKER]: {
        name: 'FlatTrade',
        tag: FLATTRADE_TAG,
        on_login: FLATTRADE_LOGIN_METHOD,
        logo: '/brokers/flattrade.svg',
        instructions: FlattradeInstructions,
        InputFieldList: FLATTRADE_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE, BSE],
    },
    [ALICEBLUE_BROKER]: {
        name: 'Alice Blue',
        tag: ALICEBLUE_TAG,
        on_login: ALICEBLUE_LOGIN_METHOD,
        logo: '/brokers/aliceblue.svg',
        instructions: AliceblueInstructions,
        InputFieldList: ALICEBLUE_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE, BSE],
    },
    [MASTERTRUST_BROKER]: {
        name: 'Mastertrust',
        tag: MASTERTRUST_TAG,
        on_login: MASTERTRUST_LOGIN_METHOD,
        logo: '/brokers/mastertrust.svg',
        instructions: MastertrustInstructions,
        InputFieldList: MASTERTRUST_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE],
    },
    [ICICI_BROKER]: {
        name: 'ICICI Direct',
        tag: ICICI_TAG,
        on_login: ICICI_LOGIN_METHOD,
        logo: '/brokers/icici.svg',
        instructions: ICICInstructions,
        InputFieldList: ICICI_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE],
    },
    [MOTILAL_BROKER]: {
        name: 'Motilal Oswal',
        tag: MOTILAL_TAG,
        on_login: MOTILAL_LOGIN_METHOD,
        logo: '/brokers/motilal.svg',
        instructions: MotilalInstructions,
        InputFieldList: MOTILAL_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE],
    },
    [ANGELONE_BROKER]: {
        name: 'AngelOne',
        tag: ANGELONE_TAG,
        on_login: ANGELONE_LOGIN_METHOD,
        logo: '/brokers/angel.svg',
        instructions: AngelOneInstructions,
        InputFieldList: ANGELONE_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE, BSE],
    },
    [UPSTOX_BROKER]: {
        name: 'Upstox',
        tag: UPSTOX_TAG,
        on_login: UPSTOX_LOGIN_METHOD,
        logo: '/brokers/upstox.svg',
        instructions: UpstoxInstructions,
        InputFieldList: UPSTOX_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 19,
        exchanges: [NSE, BSE],
    },
    [WISDOM_BROKER]: {
        name: 'Wisdom Capital',
        tag: XTS_TAG,
        on_login: (_, broker_id) => XTS_LOGIN_METHOD(broker_id, WISDOM_BROKER),
        logo: '/brokers/wisdom.svg',
        instructions: WisdomInstructions,
        InputFieldList: XTS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 9,
        exchanges: [NSE, BSE],
    },
    [FIRSTOCK_BROKER]: {
        name: 'Firstock',
        tag: FIRSTOCK_TAG,
        login_popup: FirstockLoginFields,
        logo: '/brokers/firstock.svg',
        instructions: FirstockInstructions,
        InputFieldList: FIRSTOCK_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 24,
        exchanges: [NSE, BSE],
    },
    [JAINAM_PROP_BROKER]: {
        name: 'Jainam (XTS Dealer)',
        tag: XTS_TAG,
        on_login: (_, broker_id) => XTS_LOGIN_METHOD(broker_id, JAINAM_PROP_BROKER),
        logo: '/brokers/jainamprop.svg',
        instructions: JainamPropInstructions,
        InputFieldList: XTS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 9,
        exchanges: [NSE, BSE],
    },
    [ZEBU_BROKER]: {
        name: 'Zebu',
        tag: ZEBU_TAG,
        login_popup: ZebuLoginFields,
        logo: '/brokers/zebu.svg',
        instructions: ZebuInstructions,
        InputFieldList: ZEBU_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 24,
        exchanges: [NSE, BSE],
    },
    [JMFL_BROKER]: {
        name: 'JM Financial',
        tag: XTS_TAG,
        on_login: (_, broker_id) => XTS_LOGIN_METHOD(broker_id, JMFL_BROKER),
        instructions: JMFLInstructions,
        logo: '/brokers/JMFL.svg',
        InputFieldList: XTS_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE, BSE],
    },
    [PAYTM_BROKER]: {
        name: 'Paytm Money',
        tag: PAYTM_TAG,
        on_login: PAYTM_LOGIN_METHOD,
        logo: '/brokers/paytm.svg',
        instructions: PaytmInstructions,
        InputFieldList: PAYTM_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 15,
        exchanges: [NSE],
    },
    [CHOICE_BROKER]: {
        name: 'Choice',
        tag: CHOICE_TAG,
        login_popup: ChoiceLoginFields,
        logo: '/brokers/choice.svg',
        instructions: ChoiceInstructions,
        InputFieldList: CHOICE_INPUT_FIELD_LIST,
        MISExitTime: 15 * 60 + 14,
        exchanges: [NSE],
    },
};

export const AvailableBrokersMap: Record<string, string> = {
    'Kite Connect': ZERODHA_BROKER,
    '5Paisa': FIVE_PAISA_BROKER,
    'A.C. Agrawal Shares': AC_AGRAWAL_BROKER,
    'Alice Blue': ALICEBLUE_BROKER,
    AngelOne: ANGELONE_BROKER,
    Choice: CHOICE_BROKER,
    Dhan: DHAN_BROKER,
    Finvasia: FINVASIA_BROKER,
    Firstock: FIRSTOCK_BROKER,
    Fyers: FYERS_BROKER,
    FlatTrade: FLATTRADE_BROKER,
    'ICICI Direct': ICICI_BROKER,
    IIFL: IIFL_BROKER,
    'Jainam (Duck)': JAINAM_BROKER,
    'Jainam (XTS Dealer)': JAINAM_PROP_BROKER,
    'JM Financial': JMFL_BROKER,
    'Kotak Neo': KOTAK_BROKER,
    Mastertrust: MASTERTRUST_BROKER,
    'Motilal Oswal': MOTILAL_BROKER,
    'Nuvama Wealth': EDELWEISS_BROKER,
    'Paytm Money': PAYTM_BROKER,
    Upstox: UPSTOX_BROKER,
    'Wisdom Capital': WISDOM_BROKER,
    Zebu: ZEBU_BROKER,
};
export const AvailableBrokers = Object.keys(AvailableBrokersMap);

export const IST_OFFSET = 330;
const LOGIN_HOUR_START_HR_IST = 8;
const LOGIN_HOUR_START_MIN_IST = 30;
const STG_HOUR_START_HR_IST = 8;
const STG_HOUR_START_MIN_IST = 45;
const SQOFF_HOUR_START_HR_IST = 9;
const SQOFF_HOUR_START_MIN_IST = 15;
const TRADING_HOUR_END_HR_IST = 15;
const TRADING_HOUR_END_MIN_IST = 30;

const LOGIN_HOUR_START_IST = LOGIN_HOUR_START_HR_IST * 60 + LOGIN_HOUR_START_MIN_IST;
const STG_HOUR_START_IST = STG_HOUR_START_HR_IST * 60 + STG_HOUR_START_MIN_IST;
const SQOFF_HOUR_START_IST = SQOFF_HOUR_START_HR_IST * 60 + SQOFF_HOUR_START_MIN_IST;
const TRADING_HOUR_END_IST = TRADING_HOUR_END_HR_IST * 60 + TRADING_HOUR_END_MIN_IST;
export const MINUTES_IN_A_DAY = 1440;

export const LOGIN_HOUR_START_UTC = (LOGIN_HOUR_START_IST - IST_OFFSET + MINUTES_IN_A_DAY) % MINUTES_IN_A_DAY;
export const STG_HOUR_START_UTC = (STG_HOUR_START_IST - IST_OFFSET + MINUTES_IN_A_DAY) % MINUTES_IN_A_DAY;
export const SQOFF_HOUR_START_UTC = (SQOFF_HOUR_START_IST - IST_OFFSET + MINUTES_IN_A_DAY) % MINUTES_IN_A_DAY;
export const TRADING_HOUR_END_UTC = (TRADING_HOUR_END_IST - IST_OFFSET + MINUTES_IN_A_DAY) % MINUTES_IN_A_DAY;

export const TRADING_HOUR_STG_MSG = `Strategies can only be deployed during
            ${STG_HOUR_START_HR_IST.toString().padStart(2, '0')}:${STG_HOUR_START_MIN_IST.toString().padStart(2, '0')} IST to
            ${TRADING_HOUR_END_HR_IST.toString().padStart(2, '0')}:${TRADING_HOUR_END_MIN_IST.toString().padStart(2, '0')} IST.`;

export const TRADING_HOUR_LOGIN_MSG = `Login is enabled only during
            ${LOGIN_HOUR_START_HR_IST.toString().padStart(2, '0')}:${LOGIN_HOUR_START_MIN_IST.toString().padStart(2, '0')} IST to
            ${TRADING_HOUR_END_HR_IST.toString().padStart(2, '0')}:${TRADING_HOUR_END_MIN_IST.toString().padStart(2, '0')} IST.`;
