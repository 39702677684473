import axios from 'axios';
import { AxiosConfigWithRetry } from 'types/Global';

export const baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT;
const priceServerBaseURL = `https://${process.env.NEXT_PUBLIC_PRICE_FEED_ENDPOINT}`;

export const axiosInstance = axios.create({
    timeout: 15000,
    baseURL: baseURL,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    xsrfHeaderName: 'X-CSRF-TOKEN-ACCESS',
    retry: 3,
    retryDelay: 1500,
} as AxiosConfigWithRetry);

export const axiosPriceInstance = axios.create({
    timeout: 15000,
    baseURL: priceServerBaseURL,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    xsrfHeaderName: 'X-CSRF-TOKEN-ACCESS',
    retry: 3,
    retryDelay: 1500,
} as AxiosConfigWithRetry);

const FailedAxiosInterceptor = async (err: any) => {
    const { config, message } = err;
    if (!config || !config.retry) {
        return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
        return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => setTimeout(() => resolve(config), config.retryDelay || 1000));
    await delayRetryRequest;
    return await axios(config);
};

axiosInstance.interceptors.response.use(undefined, FailedAxiosInterceptor);
axiosPriceInstance.interceptors.response.use(undefined, FailedAxiosInterceptor);

export const handleAuthenticatedError = (err: any): null | { Status: false; Msg: string; Data?: string | string[] } => {
    const resp = err.response;
    if (resp === undefined) {
        // network error
        return { Status: false, Msg: 'Network error!, please retry after some time' };
    }
    if (resp.status === 401) {
        // unauthorized
        return null;
    }
    return { Status: false, Msg: resp.data.msg };
};

export const handlePostRequest = async (endpoint: string, payload: object) => {
    try {
        const response = await axiosInstance.post(endpoint, payload);
        return { Status: true, Msg: response.data.msg };
    } catch (err: any) {
        return { Status: false, Msg: err?.response?.data?.msg };
    }
};

export const handleAuthenticatedPostRequest = async (endpoint: string, payload: null | object) => {
    try {
        const response = payload ? await axiosInstance.post(endpoint, payload) : await axiosInstance.post(endpoint);
        return { Status: true, Msg: response.data.msg };
    } catch (err) {
        return handleAuthenticatedError(err);
    }
};
