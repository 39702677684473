import { createSlice } from '@reduxjs/toolkit';
import { uniqueElements } from 'utils/ArrayUtils';
import { IS_IN_ACTIVATE_STRATEGY_HOUR } from 'utils/BrokerUtils';

const initialLivePriceState = {
    prices: {},
    prevDayPrices: {},
    loading: false,
    symbolData: [],
    liveSymbols: [],
    paperSymbols: [],
    liveIndex: {},
    expiredSymbols: [],
};

export const livepriceSlice = createSlice({
    name: 'liveprice',
    initialState: initialLivePriceState,
    reducers: {
        updatePrices(state, action) {
            const areActiveTradingHours = IS_IN_ACTIVATE_STRATEGY_HOUR();
            const { isPaper, prices } = action.payload;
            state.prices = Object.keys(prices).reduce(
                (acc, key) => {
                    if (prices[key] == null) {
                        return acc;
                    }
                    const oldVal = acc[key] || [];
                    if (!areActiveTradingHours || oldVal.length == 0) {
                        acc[key] = [prices[key], prices[key]];
                    } else if (isPaper) {
                        acc[key] = [prices[key], oldVal[1]];
                    } else {
                        acc[key] = [oldVal[0], prices[key]];
                    }
                    return acc;
                },
                { ...state.prices }
            );
            state.loading = false;
        },
        updateLoading: (state, action) => {
            state.loading = action.payload;
        },
        updateSymbolData: (state, action) => {
            state.symbolData = action.payload;
        },
        subscribeTokens: (state, action) => {
            if (action.payload.isExpired) {
                state.expiredSymbols = uniqueElements([...action.payload.symbols, ...state.expiredSymbols]);
            } else if (action.payload.isPaper) {
                state.paperSymbols = uniqueElements([...action.payload.symbols, ...state.paperSymbols]);
            } else {
                state.liveSymbols = uniqueElements([...action.payload.symbols, ...state.liveSymbols]);
            }
        },
        subscribeIndex: (state, action) => {
            state.liveIndex = action.payload;
        },
        updatePrevDayPrices(state, action) {
            const { prices } = action.payload;
            state.prevDayPrices = prices;
        },
    },
});

export const livepriceActions = livepriceSlice.actions;
