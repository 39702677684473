export const CheckFilled = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 22' className='h-6 w-6' {...props}>
        <path
            d='M9.71671 15.2154L16.1792 8.75286L14.8959 7.46953L9.71671 12.6487L7.10421 10.0362L5.82087 11.3195L9.71671 15.2154ZM11 20.1654C9.73199 20.1654 8.54032 19.9246 7.42504 19.443C6.30976 18.9615 5.33962 18.3085 4.51462 17.4841C3.68962 16.6591 3.03665 15.689 2.55571 14.5737C2.07476 13.4584 1.83399 12.2668 1.83337 10.9987C1.83337 9.73064 2.07415 8.53898 2.55571 7.4237C3.03726 6.30842 3.69024 5.33828 4.51462 4.51328C5.33962 3.68828 6.30976 3.03531 7.42504 2.55436C8.54032 2.07342 9.73199 1.83264 11 1.83203C12.2681 1.83203 13.4598 2.07281 14.575 2.55436C15.6903 3.03592 16.6605 3.68889 17.4855 4.51328C18.3105 5.33828 18.9637 6.30842 19.4453 7.4237C19.9268 8.53898 20.1673 9.73064 20.1667 10.9987C20.1667 12.2668 19.9259 13.4584 19.4444 14.5737C18.9628 15.689 18.3098 16.6591 17.4855 17.4841C16.6605 18.3091 15.6903 18.9624 14.575 19.4439C13.4598 19.9255 12.2681 20.166 11 20.1654Z'
            fill='currentColor'
        />
    </svg>
);

export const CheckBadge = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='h-6 w-6' {...props}>
        <path
            fillRule='evenodd'
            d='M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
            clipRule='evenodd'
        />
    </svg>
);

export const Check = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='h-6 w-6' {...props}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5' />
    </svg>
);

export const CheckBadgeOutlined = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='h-6 w-6' {...props}>
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
        />
    </svg>
);
