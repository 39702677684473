export const History = (props: any) => (
    <svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' {...props}>
        <path
            d='M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C6.98333 18 5.175 17.404 3.575 16.212C1.975 15.0207 0.9 13.4667 0.35 11.55C0.266667 11.25 0.296 10.9667 0.438 10.7C0.579333 10.4333 0.8 10.2667 1.1 10.2C1.38333 10.1333 1.63767 10.1957 1.863 10.387C2.08767 10.579 2.24167 10.8167 2.325 11.1C2.75833 12.5667 3.596 13.75 4.838 14.65C6.07933 15.55 7.46667 16 9 16C10.95 16 12.604 15.3207 13.962 13.962C15.3207 12.604 16 10.95 16 9C16 7.05 15.3207 5.39567 13.962 4.037C12.604 2.679 10.95 2 9 2C7.85 2 6.775 2.26667 5.775 2.8C4.775 3.33333 3.93333 4.06667 3.25 5H5C5.28333 5 5.521 5.09567 5.713 5.287C5.90433 5.479 6 5.71667 6 6C6 6.28333 5.90433 6.52067 5.713 6.712C5.521 6.904 5.28333 7 5 7H1C0.716667 7 0.479333 6.904 0.288 6.712C0.0960001 6.52067 0 6.28333 0 6V2C0 1.71667 0.0960001 1.479 0.288 1.287C0.479333 1.09567 0.716667 1 1 1C1.28333 1 1.521 1.09567 1.713 1.287C1.90433 1.479 2 1.71667 2 2V3.35C2.85 2.28333 3.88767 1.45833 5.113 0.875C6.33767 0.291667 7.63333 0 9 0C10.25 0 11.421 0.237333 12.513 0.712C13.6043 1.18733 14.5543 1.829 15.363 2.637C16.171 3.44567 16.8127 4.39567 17.288 5.487C17.7627 6.579 18 7.75 18 9C18 10.25 17.7627 11.4207 17.288 12.512C16.8127 13.604 16.171 14.554 15.363 15.362C14.5543 16.1707 13.6043 16.8127 12.513 17.288C11.421 17.7627 10.25 18 9 18Z'
            fill='currentColor'
        />
    </svg>
);

export const CalendarIcon = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='h-6 w-6' {...props}>
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5'
        />
    </svg>
);
