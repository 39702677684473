import { Combobox, Transition } from '@headlessui/react';
import { ChevronArrowDown, Help } from 'assets';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Checkbox } from './Checkbox';

type MultiSelectProps = {
    selected: string[];
    setSelected: (val: string[]) => void;
    items: string[];
    valueMap: { [key: string]: string };
    display?: boolean;
    label?: string;
    type?: 'vertical' | 'horizontal';
    disabled?: boolean;
};

export function MultiSelect({ display, label, type, disabled, selected, setSelected, items, valueMap }: MultiSelectProps) {
    const handleCheck = (val: string) => {
        let temp = [...selected];
        if (!selected.includes(valueMap[val])) {
            temp.push(valueMap[val]);
        } else {
            temp = temp.filter((item) => item !== valueMap[val]);
        }
        setSelected(temp);
    };

    const handleCheckAll = () => {
        if (selected.length === items.length) {
            setSelected([]);
        } else {
            setSelected(items.map((item) => valueMap[item]));
        }
    };

    return (
        <div
            className={classNames('flex w-full items-center gap-2', {
                hidden: !display,
                'flex-col !items-start': type === 'vertical',
                'pointer-events-none opacity-80': disabled,
            })}>
            {label && <label className={classNames('flex items-center gap-2 text-xsm font-normal leading-6')}>{label}</label>}
            <Combobox value={selected} multiple>
                <div className='relative w-full'>
                    <div
                        className={classNames(
                            'relative flex w-full cursor-default justify-between gap-2 overflow-hidden rounded border-2 bg-white px-2 text-left placeholder-shown:text-ellipsis focus-within:rounded focus-within:border-2 focus-within:border-secondaryBlue-900 sm:text-sm'
                        )}>
                        <Combobox.Button as='div' className=' w-full whitespace-nowrap  border-none py-1 text-xs'>
                            {selected.length === items.length ? 'All Selected' : `${selected.length} Selected`}
                        </Combobox.Button>
                        <Combobox.Button>
                            <ChevronArrowDown className='h-4 w-4 text-primaryBlack-600' />
                        </Combobox.Button>
                    </div>
                    <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                        <Combobox.Options>
                            {items.length === 0 ? (
                                <div className='absolute flex w-full cursor-default select-none items-center gap-1 bg-white px-2 py-2 text-xs text-primaryBlack-650 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                    <Help className='h-4' /> Nothing found.
                                </div>
                            ) : (
                                <div className='absolute z-50 max-h-96 w-full min-w-max overflow-auto rounded-md bg-white pb-1 text-sm shadow-card ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                    <div className='relative w-full'>
                                        <Combobox.Option
                                            value={'all'}
                                            className={({ active }) =>
                                                classNames('flex h-10 w-full cursor-pointer select-none items-center  px-2 py-2', {
                                                    'bg-primaryBlack-200 duration-100': active,
                                                    'text-primaryBlack-750': !active,
                                                })
                                            }>
                                            <Checkbox
                                                id={`expiry-radio-allselect`}
                                                value={selected.length === items.length}
                                                onChange={handleCheckAll}
                                                label={'Select All'}
                                            />
                                        </Combobox.Option>
                                        {items.map((item) => (
                                            <Combobox.Option
                                                key={item}
                                                value={valueMap[item]}
                                                className={({ active }) =>
                                                    classNames(
                                                        'flex h-10 w-full cursor-pointer select-none items-center whitespace-nowrap px-2 py-2',
                                                        {
                                                            'bg-primaryBlack-200 duration-100': active,
                                                            'text-primaryBlack-750': !active,
                                                        }
                                                    )
                                                }>
                                                <Checkbox
                                                    id={`expiry-radio${item}`}
                                                    value={selected.includes(valueMap[item])}
                                                    onChange={() => handleCheck(item)}
                                                    label={item}
                                                />
                                            </Combobox.Option>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}
