export const ACTION_TYPE_MAP = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    REMOVE_POSIITON: 'REMOVE_POSIITON',
    UPDATE: 'UPDATE',
    SQOFF: 'SQOFF',
    CHECK: 'CHECK',
    UNOD_SQOFF: 'UNOD_SQOFF',
};

export const timeIntervalValues = [
    {
        interval: 1,
        text: '1s',
    },
    {
        interval: 5,
        text: '5s',
    },
    {
        interval: 10,
        text: '10s',
    },
];

export const timeTraversalValues = [
    {
        interval: -376,
        text: '-1d',
    },
    // {
    //     interval: -120,
    //     text: '-2h',
    // },
    {
        interval: -60,
        text: '-1h',
    },
    {
        interval: -5,
        text: '-5m',
    },
    {
        interval: -1,
        text: '-1m',
    },
    {
        interval: 1,
        text: '+1m',
    },
    {
        interval: 5,
        text: '+5m',
    },
    {
        interval: 60,
        text: '+1h',
    },
    // {
    //     interval: 120,
    //     text: '+2h',
    // },
    {
        interval: 376,
        text: '+1d',
    },
];

export const autoPlayValues = [
    {
        interval: 1,
        text: '1 Minute',
        title: '1m',
    },
    {
        interval: 5,
        text: '5 Minutes',
        title: '5m',
    },
    {
        interval: 15,
        text: '15 Minutes',
        title: '15m',
    },
    {
        interval: 30,
        text: '30 Minutes',
        title: '30m',
    },
    {
        interval: 60,
        text: '1 Hour',
        title: '1h',
    },
    {
        interval: 120,
        text: '2 Hours',
        title: '2h',
    },
];

export const LegCheckPointTypes = ['Price (in ₹)'];
export const LegCheckPointTypeMap = {
    'Price (in ₹)': 'LegCheckPoint.Price',
};

export const OverallCheckPointsType = ['MTM (in ₹)', 'Underlying', 'Delta (Δ)'];
export const OverallCheckPointsTypeMap = {
    'MTM (in ₹)': 'OverallCheckPoint.MTM',
    Underlying: 'OverallCheckPoint.UnderlyingPrice',
    'Delta (Δ)': 'OverallCheckPoint.Delta',
};

export const RevOverallCheckPointsTypeMap = {
    'OverallCheckPoint.UnderlyingPrice': 'Underlying',
    'OverallCheckPoint.MTM': 'MTM',
    'OverallCheckPoint.Delta': 'Delta',
};

export const UnderlyingheckPointType = OverallCheckPointsTypeMap['Underlying'];
export const MTMCheckPointType = OverallCheckPointsTypeMap['MTM (in ₹)'];
export const DeltaCheckPointType = OverallCheckPointsTypeMap['Delta (Δ)'];
