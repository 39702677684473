import Tippy from '@tippyjs/react';
import { ErrorIcon } from 'assets';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { setLocalStorageNudge } from 'utils/NudgeUtils';
import { Checkbox } from '../Input';
import React from 'react';

type NudgeTooltipProps = {
    nudgeKey: string | undefined;
    content: string | undefined;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    setNudgeKey: Dispatch<SetStateAction<string | undefined>>;
};

type NudgeContentProps = {
    content: string | undefined;
    itemKey: string | undefined;
    setNudge: Dispatch<SetStateAction<string | undefined>>;
};

const NudgeContent = ({ content, setNudge, itemKey }: NudgeContentProps) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleClose = () => {
        if (isChecked) {
            setLocalStorageNudge(itemKey);
        }
        setNudge(undefined);
    };

    return (
        <div className='flex flex-col'>
            <div className='flex w-72 gap-2 p-2 text-sm'>
                <ErrorIcon className='h-5 text-warning-900' />
                <p className='w-4/5'>{content}</p>
            </div>
            <div className='flex items-center justify-between'>
                <Checkbox label='Do not show again' onChange={(e: ChangeEvent<HTMLInputElement>) => setIsChecked(e.target.checked)} />
                <button className='font-bold' onClick={handleClose}>
                    OK
                </button>
            </div>
        </div>
    );
};
const NudgeTooltip = ({ nudgeKey, setNudgeKey, content, placement }: NudgeTooltipProps) => {
    return (
        <Tippy
            trigger='manual'
            content={<NudgeContent content={content} itemKey={nudgeKey} setNudge={setNudgeKey} />}
            visible={nudgeKey !== null && nudgeKey !== undefined}
            allowHTML
            placement={placement ?? 'bottom'}
            interactive
            maxWidth={800}>
            <p></p>
        </Tippy>
    );
};

export default NudgeTooltip;
