import { Close, MagnifyingGlass } from 'assets';
import classNames from 'classnames';
import { TextInput } from 'components/UI/Input';
import { useState } from 'react';

const SearchFilter = ({ onChange, placeholder, dark, inputClass = '', iconClass = '' }) => {
    const [filterInput, setFilterInput] = useState('');

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilterInput(value);
        onChange(value);
    };

    const clearFilterHandler = () => {
        setFilterInput('');
        onChange('');
    };

    return (
        <div className={classNames('relative w-full')}>
            <div className={classNames('absolute left-1 top-1/4', iconClass)} onClick={clearFilterHandler}>
                <MagnifyingGlass className='w-4 text-primaryBlack-700' />
            </div>
            <TextInput
                value={filterInput}
                onChange={handleFilterChange}
                placeholder={placeholder}
                className={classNames('py-2 pl-7 ', inputClass, {
                    '!border-primaryBlack-800 bg-primaryBlack-800 !font-normal text-white focus:ring-primaryBlack-600 ': dark,
                })}
            />
            {filterInput && (
                <button
                    className={classNames('absolute right-1 top-1/4 bg-white', { '!bg-primaryBlack-800 text-white': dark })}
                    onClick={clearFilterHandler}>
                    <Close className='w-5 text-primaryBlack-600' />
                </button>
            )}
        </div>
    );
};

export default SearchFilter;
