import { SymbolArrayDataType } from 'types/Chart';
import { axiosInstance, axiosPriceInstance, handleAuthenticatedError } from 'utils/API';
import { convertDateToString } from 'utils/Date';
import { getFromSession, setToSession } from './MarginAPI';
import { formattedTime } from 'utils/ChartUtils';

export const GetSymbolsWithExpiries = async () => {
    const key = 'symbols_for_charts';
    const prevStored = getFromSession(key);
    if (prevStored) {
        return prevStored as { Status: true; Data: SymbolArrayDataType[] };
    }
    const symbolsforChartsEndpoint = `/symbols`;
    try {
        const response = await axiosPriceInstance.get(symbolsforChartsEndpoint);
        const value = { Status: true, Data: response.data as SymbolArrayDataType[] } as const;
        setToSession(key, value);
        return value;
    } catch (err) {
        return { Status: false, Msg: 'Unable to fetch. Please try again later' } as const;
    }
};

export const getSymbolData = async (symbols: string[], start_date: string, end_date: string, quantity: number[]) => {
    const symbolDataEndpoint = `/historical-trades?symbols=${encodeURIComponent(symbols.join(','))}&start_date=${start_date}&end_date=${end_date}`;
    const quantityMap = symbols.reduce((acc: any, curr, i) => {
        acc[curr] = quantity[i];
        return acc;
    }, {});
    try {
        const response = await axiosPriceInstance.get(symbolDataEndpoint);

        const data = response.data.reduce((acc: any, curr: any) => {
            const symbol = curr[0];
            const date = convertDateToString(curr[1], 0);
            if (!acc[date]) {
                acc[date] = {};
            }
            if (acc[date][symbol]) {
                acc[date][symbol].push({
                    price: (curr[2] * quantityMap[symbol]) / 100,
                    exchange_time: formattedTime(curr[1]),
                    volume: curr[3],
                });
            } else {
                acc[date][symbol] = [{ price: (curr[2] * quantityMap[symbol]) / 100, exchange_time: formattedTime(curr[1]), volume: curr[3] }];
            }
            return acc;
        }, {});

        const newData = Object.keys(data).reduce((acc: any, date) => {
            acc[date] = symbols.map((symbol) => ({ symbol: symbol, data: data[date][symbol] ?? [] }));
            return acc;
        }, data);

        return newData;
    } catch (error) {
        console.error(`Chart Data error from ${start_date} to ${end_date}`);
        handleAuthenticatedError(error);
        return [];
    }
};

export const getSymbolLTPData = async (symbols: string, delay: number) => {
    const symbolLTPEndpoint = `/ltp?symbols=${encodeURIComponent(symbols)}&delay=${delay}`;
    try {
        const response = await axiosPriceInstance.get(symbolLTPEndpoint);
        return { Status: true, Data: response.data as [string, string, number, number][] } as const;
    } catch (error) {
        handleAuthenticatedError(error);
    }
};

export const getExpiredSymbolLTPData = async (symbols: string) => {
    const symbolLTPEndpoint = `/expired_ltp?symbols=${encodeURIComponent(symbols)}`;
    try {
        const response = await axiosPriceInstance.get(symbolLTPEndpoint);
        return { Status: true, Data: response.data as [string, string, number, number][] } as const;
    } catch (error) {
        handleAuthenticatedError(error);
    }
};

export const GetStocksData = async () => {
    const key = 'stocks_indicator';
    const prevStored = getFromSession(key);
    if (prevStored) {
        return prevStored as { Status: true; Data: string[] };
    }
    const symbolsforChartsEndpoint = `/stock_backtest_tokens`;
    try {
        const response = await axiosInstance.get(symbolsforChartsEndpoint);
        const value = { Status: true, Data: response.data.tokens as string[] } as const;
        setToSession(key, value);
        return value;
    } catch (err) {
        return { Status: false, Msg: 'Unable to fetch stocks. Please try again later' } as const;
    }
};

export const getOptionChainData = async (underlying: string, expiry: string) => {
    const payload = new URLSearchParams({ underlying, expiry, with_ltp: 'true' }).toString();
    const optionChainEndpoint = `/option-chain?${payload}`;
    try {
        const response = await axiosPriceInstance.get(optionChainEndpoint);
        return { Status: true, Data: response.data as any } as const;
    } catch (error) {
        return handleAuthenticatedError(error);
    }
};
