import {
    BrokerConfirm,
    BrokerSettings,
    ChoiceOTP,
    DeleteBroker,
    DhanBrokerConsent,
    EdelweissBrokerConsent,
    GetBroker,
    KotakOTP,
    PostBroker,
    PutBroker,
} from 'API/BrokerAPI';
import { Dispatch } from 'react';
import { brokerActions } from 'store/brokerStore';
import { AppKeysType, BrokerSettingsRequestType } from 'types/Broker';
import { showAlert } from 'utils/AlertUtils';
import { LogoutHandler } from '../Authentication/AuthAPIHandlers';

export const GetBrokerHandler = (dispatch: Dispatch<any>) => {
    GetBroker().then((Response) => {
        if (Response) {
            if (Response.Status) {
                dispatch(brokerActions.updateBrokers(Response.Data));
            }
        } else {
            LogoutHandler(dispatch);
        }
    });
};

export const DeleteBrokerHandler = (id: string, dispatch: Dispatch<any>) => {
    DeleteBroker(id).then((Response) => {
        if (Response) {
            if (Response.Status) {
                showAlert.success(Response.Msg);
                dispatch(brokerActions.deleteBroker(id));
            } else {
                showAlert.error(Response.Msg);
            }
        } else {
            LogoutHandler(dispatch);
        }
    });
};

export const BrokerAddHandler = (brokerId: string, app_keys: AppKeysType, setSuccess: (value: boolean) => void, dispatch: Dispatch<any>) => {
    PutBroker(brokerId, app_keys).then((Response) => {
        if (Response) {
            if (Response.Status) {
                showAlert.success(Response.Msg);
                GetBrokerHandler(dispatch);
                setSuccess(true);
            } else {
                showAlert.error(Response.Msg);
            }
        } else {
            LogoutHandler(dispatch);
        }
    });
};

export const BrokerSetupHandler = (broker: string, alias: string, setSuccess: (value: string) => void, dispatch: Dispatch<any>) => {
    PostBroker(broker, alias).then((Response) => {
        if (Response) {
            if (Response.Status) {
                setSuccess(Response.Data);
            } else {
                showAlert.error(Response.Msg);
            }
        } else {
            LogoutHandler(dispatch);
        }
    });
};

export const BrokerConfirmHandler = (urlPath: string, setState: (value: { Status: boolean; msg: string }) => void, dispatch: Dispatch<any>) => {
    BrokerConfirm(urlPath).then((Response) => {
        if (Response) {
            setState({ Status: Response.Status, msg: Response.Msg });
        } else {
            LogoutHandler(dispatch);
            setState({ Status: false, msg: 'Something went wrong' });
        }
    });
};

export const UpdateBrokerSettings = (broker_id: string, params: BrokerSettingsRequestType, onSucess: () => void, dispatch: Dispatch<any>) => {
    BrokerSettings(broker_id, params).then((Response) => {
        if (Response) {
            if (Response.Status) {
                showAlert.success(Response.Msg);
                dispatch(brokerActions.updateBrokerSettings({ _id: broker_id, settings: params }));
            } else {
                showAlert.error(Response.Msg);
            }
            onSucess();
        } else {
            LogoutHandler(dispatch);
        }
    });
};

export const KotakOTPHandler = (
    phoneNumber: string,
    password: string,
    responseHandler: (value: boolean) => void,
    brokerId: string,
    dispatch: Dispatch<any>
) => {
    KotakOTP(phoneNumber, password, brokerId).then((Response) => {
        if (Response) {
            if (Response.Status) {
                showAlert.success(Response.Msg);
            } else {
                showAlert.error(Response.Msg);
            }
            responseHandler(Response.Status);
        } else {
            LogoutHandler(dispatch);
        }
    });
};

export const EdelweissBrokerConsentHandler = (successFun: (api_key: string, access_token: string) => void, brokerId: string) => {
    EdelweissBrokerConsent(brokerId).then((Response) => {
        if (Response) {
            if (Response.Status) {
                successFun(Response.Data.vendor_api_key, Response.Data.vendor_access_token);
                return Response.Msg;
            } else {
                showAlert.error(Response.Msg);
            }
        } else {
            showAlert.error('Something went wrong');
        }
    });
};

export const DhanBrokerConsentHandler = (successFun: (consent_id: string) => void, brokerId: string) => {
    DhanBrokerConsent(brokerId).then((Response) => {
        if (Response) {
            if (Response.Status) {
                successFun(Response.Data);
            } else {
                showAlert.error(Response.Msg);
            }
        } else {
            showAlert.error('Something went wrong');
        }
    });
};

export const ChoiceOTPHandler = (
    userId: string,
    password: string,
    responseHandler: (value: boolean) => void,
    brokerId: string,
    dispatch: Dispatch<any>
) => {
    ChoiceOTP(userId, password, brokerId).then((Response) => {
        if (Response) {
            if (Response.Status) {
                showAlert.success(Response.Msg);
            } else {
                showAlert.error(Response.Msg);
            }
            responseHandler(Response.Status);
        } else {
            LogoutHandler(dispatch);
        }
    });
};
