import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY, CLIENT_ID_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: 'Go to ' },
                        {
                            type: 'link',
                            text: 'go.mynt.in',
                            url: ' https://go.mynt.in',
                        },
                        { type: 'text', text: ' and login to your account.' },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Go to Profile → ClientId',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Click on the API Key button to generate your API Key.`,
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Post generation, Copy the Vendor code and the API key of the generated App and paste them here`,
                        },
                    ],
                },
            },
        ],
    },
];

export const ZEBU_INPUT_FIELD_LIST = [
    {
        name: CLIENT_ID_IDENTITY,
        label: 'Vendor Code (Case-sensitive, should be all capital letters and Without _U)',
        placeholder: 'Enter Zebu Vendor Code',
        defaultValue: '',
    },
    {
        name: API_KEY_IDENTITY,
        label: 'API Key',
        placeholder: 'API Key',
        defaultValue: '',
    },
];

export default instructions;
