import history from 'history';
import { InstructionType } from 'types/Broker';
import { FLATTRADE_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'https://wall.flattrade.in',
                            url: 'https://wall.flattrade.in',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Navigate to “Pi” on the menu section of the Page.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click “Create New API Key”. Enter App Name as "AlgoTest" and App Short Name as "AlgoTest".',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                        },
                        {
                            type: 'special_link',
                            text: 'https://algotest.in/confirm_broker/' + FLATTRADE_TAG,
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Leave the Postback as blank',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Copy the App Key and Secret Key and paste them below',
                        },
                    ],
                },
            },
        ],
    },
];

export const FLATTRADE_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'API Key',
        placeHolder: 'Enter API Key',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'API Secret',
        placeHolder: 'Enter Secret Key',
        defaultValue: '',
    },
];

export const FLATTRADE_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    history.push(`https://auth.flattrade.in/?app_key=${encodeURIComponent(appKey)}`);
};

export default instructions;
