import history from 'history';
import { InstructionType } from 'types/Broker';
import { ZERODHA_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'IMPORTANT - Please make sure Zerodha TOTP is setup. Find instructions to setup TOTP here: ',
                        },
                        {
                            type: 'link',
                            text: 'Zerodha TOTP Setup',
                            url: 'https://support.zerodha.com/category/your-zerodha-account/login-credentials/login-credentials-of-trading-platforms/articles/time-based-otp-setup',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Go to ',
                        },
                        {
                            type: 'link',
                            text: 'developers.kite.trade',
                            url: 'https://developers.kite.trade/',
                        },
                        {
                            type: 'text',
                            text: ' and create a new app.',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Choose Type as Connect. Enter any App Name as you like and Enter your Zerodha Client ID.',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'https://algotest.in/confirm_broker/' + ZERODHA_TAG,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [{ type: 'text', text: `Copy the API key and API secret and paste them in below and click Add` }],
                },
            },
        ],
    },
];

export const ZERODHA_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'API Key',
        placeholder: 'Enter API Key',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'API Secret',
        placeholder: 'Enter API Secret',
        defaultValue: '',
    },
];

export const ZERODHA_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    history.push(`https://kite.zerodha.com/connect/login?v=3&api_key=${appKey}`);
};

export default instructions;
