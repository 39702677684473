import { Divide, Minus, Multiply, Plus } from 'assets';
import { OperatorMap } from 'constants/Indicators';

export const ExpressionMapper = {
    Plus: <Plus />,
    Minus: <Minus />,
    Times: <Multiply />,
    Divide: <Divide />,
};
function MathExpression({ type, value, handleAdd }) {
    const handeDone = () => {
        handleAdd({ type, value });
    };

    return (
        <button
            onClick={handeDone}
            className='border-b-2 border-primaryBlack-250 px-3 py-2 text-left text-sm font-medium text-gray-900 hover:bg-primaryBlack-150'>
            {type == OperatorMap.MATHBINARY ? ExpressionMapper[value] : value}
        </button>
    );
}

export { MathExpression };
