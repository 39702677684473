import { IndicatorPopup, MathExpression, NumberPopup } from 'components/Indicators/Popups';
import {
    ComparatorTypeMap,
    CrossTypeMap,
    IndicatorComplexTypeMap,
    IndicatorTypeMap,
    MathBinaryTypeMap,
    OperatorMap,
    SelectorTypeMap,
} from 'constants/Indicators';

const HEADER = 'header';
const ITEM = 'item';
export const DROPDOWN = 'dropdown';
export const INLINE = 'inline';

export const IndicatorItems = (handleExp, firstItem = false) => [
    { type: HEADER, label: 'Stock Attributes' },
    {
        type: ITEM,
        label: 'Open',
        item: <NumberPopup handleAdd={handleExp} type={OperatorMap.SELECTOR} value={SelectorTypeMap.Open} firstItem={firstItem} variant={DROPDOWN} />,
    },
    {
        type: ITEM,
        label: 'Close',
        item: (
            <NumberPopup handleAdd={handleExp} type={OperatorMap.SELECTOR} value={SelectorTypeMap.Close} firstItem={firstItem} variant={DROPDOWN} />
        ),
    },
    {
        type: ITEM,
        label: 'High',
        item: <NumberPopup handleAdd={handleExp} type={OperatorMap.SELECTOR} value={SelectorTypeMap.High} firstItem={firstItem} variant={DROPDOWN} />,
    },
    {
        type: ITEM,
        label: 'Low',
        item: <NumberPopup handleAdd={handleExp} type={OperatorMap.SELECTOR} value={SelectorTypeMap.Low} firstItem={firstItem} variant={DROPDOWN} />,
    },
    {
        type: ITEM,
        label: 'Volume',
        item: (
            <NumberPopup handleAdd={handleExp} type={OperatorMap.SELECTOR} value={SelectorTypeMap.Volume} firstItem={firstItem} variant={DROPDOWN} />
        ),
    },
    { type: HEADER, label: 'Indicators' },
    {
        type: ITEM,
        label: 'RSI',
        item: (
            <IndicatorPopup
                handleAdd={handleExp}
                type={OperatorMap.INDICATOR}
                value={IndicatorTypeMap.RSI}
                firstItem={firstItem}
                variant={DROPDOWN}
            />
        ),
    },
    {
        type: ITEM,
        label: 'SMA',
        item: (
            <IndicatorPopup
                handleAdd={handleExp}
                type={OperatorMap.INDICATOR}
                value={IndicatorTypeMap.SMA}
                firstItem={firstItem}
                variant={DROPDOWN}
            />
        ),
    },
    {
        type: ITEM,
        label: 'RMA',
        item: (
            <IndicatorPopup
                handleAdd={handleExp}
                type={OperatorMap.INDICATOR}
                value={IndicatorTypeMap.RMA}
                firstItem={firstItem}
                variant={DROPDOWN}
            />
        ),
    },
    {
        type: ITEM,
        label: 'EMA',
        item: (
            <IndicatorPopup
                handleAdd={handleExp}
                type={OperatorMap.INDICATOR}
                value={IndicatorTypeMap.EMA}
                firstItem={firstItem}
                variant={DROPDOWN}
            />
        ),
    },
    // {
    //     type: ITEM,
    //     label: 'MACD',
    //     item: <IndicatorPopup handleAdd={handleExp} type={OperatorMap.INDICATOR} value={IndicatorTypeMap.MACD} firstItem={firstItem} />,
    // },
    {
        type: ITEM,
        label: 'ATR',
        item: (
            <IndicatorPopup
                handleAdd={handleExp}
                type={OperatorMap.INDICATORCOMPLEX}
                value={IndicatorComplexTypeMap.ATR}
                firstItem={firstItem}
                variant={DROPDOWN}
            />
        ),
    },
    {
        type: ITEM,
        label: 'TR',
        item: (
            <NumberPopup
                handleAdd={handleExp}
                type={OperatorMap.INDICATORCOMPLEX}
                value={IndicatorComplexTypeMap.TR}
                firstItem={firstItem}
                variant={DROPDOWN}
            />
        ),
    },
    { type: HEADER, label: 'Number' },
    {
        type: ITEM,
        label: 'Value',
        item: <NumberPopup handleAdd={handleExp} type={OperatorMap.Number} firstItem={firstItem} variant={DROPDOWN} />,
    },
];

export const MathItems = (handleExp, hasComparator) =>
    [
        hasComparator && { type: HEADER, label: 'Comparators' },
        hasComparator && {
            type: ITEM,
            label: '> greater than',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={ComparatorTypeMap.GreaterThan} variant={DROPDOWN} />,
        },
        hasComparator && {
            type: ITEM,
            label: '>= greater than equal to',
            item: (
                <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={ComparatorTypeMap.GreaterThanEqualTo} variant={DROPDOWN} />
            ),
        },
        hasComparator && {
            type: ITEM,
            label: '< less than',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={ComparatorTypeMap.LessThan} variant={DROPDOWN} />,
        },
        hasComparator && {
            type: ITEM,
            label: '<= less than equal to',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={ComparatorTypeMap.LessThanEqualTo} variant={DROPDOWN} />,
        },
        hasComparator && {
            type: ITEM,
            label: '= equal to',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={ComparatorTypeMap.EqualTo} variant={DROPDOWN} />,
        },
        hasComparator && {
            type: ITEM,
            label: '!= not equal to',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={ComparatorTypeMap.NotEqualTo} variant={DROPDOWN} />,
        },
        hasComparator && { type: HEADER, label: 'Cross Operations' },
        hasComparator && {
            type: ITEM,
            label: 'crosses above',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={CrossTypeMap.CrossAbove} variant={DROPDOWN} />,
        },
        hasComparator && {
            type: ITEM,
            label: 'crosses below',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.COMPARATOR} value={CrossTypeMap.CrossBelow} variant={DROPDOWN} />,
        },
        { type: HEADER, label: 'Arithmetic Operators' },
        {
            type: ITEM,
            label: '+ plus',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.MATHBINARY} value={MathBinaryTypeMap.PLUS} variant={DROPDOWN} />,
        },
        {
            type: ITEM,
            label: '- minus',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.MATHBINARY} value={MathBinaryTypeMap.MINUS} variant={DROPDOWN} />,
        },
        {
            type: ITEM,
            label: 'x multiply',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.MATHBINARY} value={MathBinaryTypeMap.TIMES} variant={DROPDOWN} />,
        },
        {
            type: ITEM,
            label: '/ divide',
            item: <MathExpression handleAdd={handleExp} type={OperatorMap.MATHBINARY} value={MathBinaryTypeMap.DIVIDE} variant={DROPDOWN} />,
        },

        // { type: HEADER, label: 'Brackets' },
        // { type: ITEM, label: '(', item: <MathExpression handleAdd={handleExp} label='(' /> },
        // { type: ITEM, label: ')', item: <MathExpression handleAdd={handleExp} label=')' /> },
    ].filter(Boolean);
