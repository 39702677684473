import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            // {
            //     type: 'list_item',
            //     item: {
            //         type: 'paragraph',
            //         items: [
            //             {
            //                 type: 'text',
            //                 text: 'Please to go to ',
            //             },
            //             {
            //                 type: 'link',
            //                 text: 'https://spark.jainam.in/ ',
            //                 url: 'https://spark.jainam.in/',
            //             },
            //             {
            //                 type: 'text',
            //                 text: 'and login to your account',
            //             },
            //         ],
            //     },
            // },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Create a ticket through Smart Delta web in API > for Symphony API',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Include details in the ticket as Branch code, Client code, Name of the Client, Mobile number and Email ID (If the contact person is different, then their Name & Mobile number)',
                        },
                    ],
                },
            },
        ],
    },
];

export default instructions;
