import { createSlice } from '@reduxjs/toolkit';

const initialExecutionState = {
    executionList: [],
    executableStrategiesList: [],
    activeExecution: null,
};

export const executionSlice = createSlice({
    name: 'execution',
    initialState: initialExecutionState,
    reducers: {
        updateExecutableStrategyList(state, action) {
            state.executableStrategiesList = action.payload;
        },
        addExecutableStrategy(state, action) {
            state.executableStrategiesList = [action.payload, ...state.executableStrategiesList];
        },
        deleteExecutableStrategy(state, action) {
            const id = action.payload;
            state.executableStrategiesList = state.executableStrategiesList.filter((item) => item['_id'] !== id);
        },
        updateExecutionList(state, action) {
            state.executionList = action.payload;
        },
        addExecution(state, action) {
            state.executionList.push(action.payload);
        },
        updateActiveExecution(state, action) {
            state.activeExecution = action.payload;
        },
        updateExecutionStatus(state, action) {
            const id = action.payload.id;
            const new_state = action.payload.new_state;
            const index = state.executionList.findIndex((item) => item['_id'] === id);
            if (index >= 0) {
                state.executionList[index].status = new_state;
            }
        },
        updateActiveExecutionStatus(state, action) {
            state.activeExecution.status = action.payload.new_state;
        },
        addExecutableStrategyList(state, action) {
            state.executableStrategiesList = [...state.executableStrategiesList, ...action.payload];
        },
    },
});

export const executionActions = executionSlice.actions;
