import { createSlice } from '@reduxjs/toolkit';
import { Straddle920Template } from 'constants/TemplateName';
import { default920StraddleStrategy, default920StraddleStrategyAttributes } from 'templates/Straddle920/DefaultStrategy';

const defaultActiveStrategy = {
    strategy: default920StraddleStrategy,
    name: null,
    id: null,
    attributes: default920StraddleStrategyAttributes,
    template: Straddle920Template,
};

const initialStrategyState = {
    strategyList: [],
    activeStrategy: defaultActiveStrategy,
    analyserStrategyList: [],
};

export const strategySlice = createSlice({
    name: 'strategy',
    initialState: initialStrategyState,
    reducers: {
        updateStrategyList(state, action) {
            state.strategyList = action.payload;
        },
        addStrategy(state, action) {
            state.strategyList.push(action.payload);
        },
        deleteStrategy(state, action) {
            const id = action.payload;
            state.strategyList = state.strategyList.filter((item) => item['_id'] !== id);
        },
        updateStrategy(state, action) {
            const name = action.payload.name;
            const id = action.payload.id;
            const index = state.strategyList.findIndex((item) => item['_id'] === id);
            if (index >= 0) {
                state.strategyList[index].name = name;
            }
        },
        resetActiveStrategy(state) {
            state.activeStrategy = defaultActiveStrategy;
        },
        updateActiveStrategy(state, action) {
            state.activeStrategy = action.payload;
        },
        setNewActiveStrategy(state, action) {
            state.activeStrategy = {
                strategy: action.payload.strategy,
                name: null,
                id: null,
                attributes: action.payload.attributes,
                template: action.payload.template,
            };
        },
        addStrategies(state, action) {
            state.strategyList = [...state.strategyList, ...action.payload];
        },
        updateAnalyserStrategyList(state, action) {
            state.analyserStrategyList = action.payload;
        },
        updateAnalyserStrategy(state, action) {
            const id = action.payload.id;
            const index = state.analyserStrategyList.findIndex((item) => item['id'] === id);
            if (index >= 0) {
                state.analyserStrategyList[index] = action.payload;
            } else {
                state.analyserStrategyList.push(action.payload);
            }
        },
        addAnalyserStrategy(state, action) {
            state.analyserStrategyList.push(action.payload);
        },
        deleteAnalyserStrategy(state, action) {
            const id = action.payload;
            state.analyserStrategyList = state.analyserStrategyList.filter((item) => item['id'] !== id);
        },
    },
});

export const strategyActions = strategySlice.actions;
