import Button from 'components/UI/Button';
import { Number } from 'components/UI/Input';
import Popup from 'components/UI/Popup';
import { ComparatorTypeMap, IndicatorComplexTypeMap, OperatorMap, SelectorTypeMap } from 'constants/Indicators';
import { useRef, useState } from 'react';
import { DROPDOWN, INLINE } from '../DropdownItems';

function NumberPopup({ type, value, handleAdd, handleDelete = () => {}, offset = 0, firstItem = false, variant = DROPDOWN, disabled }) {
    const [show, setShow] = useState(false);
    const offsetRef = useRef();

    const label = OperatorMap.Number == type ? 'value' : 'offset';

    const handleDone = () => {
        const offset = offsetRef.current.getValue();
        if (value == IndicatorComplexTypeMap.TR) {
            handleAdd({ type, value, offset, args: {} });
        } else if (type == OperatorMap.Number) {
            handleAdd({ type, value: offset });
        } else {
            handleAdd({ type, value, offset });
        }
        if (firstItem) {
            if (value == SelectorTypeMap.Open) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({ type: OperatorMap.SELECTOR, value: SelectorTypeMap.Close, offset: 0 });
            } else if (value == SelectorTypeMap.Close) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({ type: OperatorMap.SELECTOR, value: SelectorTypeMap.Open, offset: 0 });
            } else if (value == SelectorTypeMap.High) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({ type: OperatorMap.SELECTOR, value: SelectorTypeMap.High, offset: offset + 1 });
            } else if (value == SelectorTypeMap.Low) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.LessThan });
                handleAdd({ type: OperatorMap.SELECTOR, value: SelectorTypeMap.Low, offset: offset + 1 });
            } else if (value == SelectorTypeMap.Volume) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({ type: OperatorMap.Number, value: 1000 });
            }
        }

        setShow(false);
    };
    return (
        <>
            {variant === DROPDOWN ? (
                <button
                    disabled={disabled}
                    onClick={() => setShow(true)}
                    className='border-b-2 border-primaryBlack-250 px-3 py-2 text-left text-sm font-medium text-gray-900 hover:bg-primaryBlack-150'>
                    {type === OperatorMap.Number ? type : value}
                </button>
            ) : (
                <button disabled={disabled} onClick={() => setShow(true)} className='py-1 text-xs font-medium leading-4'>
                    {type === OperatorMap.Number ? (
                        value
                    ) : (
                        <>
                            {value}({offset})
                        </>
                    )}
                </button>
            )}
            <Popup
                title={type === OperatorMap.Number ? type : value}
                show={show}
                setShow={setShow}
                footer={
                    <>
                        {variant === INLINE && (
                            <Button onClick={handleDelete} tertiaryText>
                                Delete
                            </Button>
                        )}
                        <Button primary onClick={handleDone}>
                            Done
                        </Button>
                    </>
                }>
                <Number display ref={offsetRef} label={label} inputClass='w-40' defaultValue={type === OperatorMap.Number ? value : offset} />
            </Popup>
        </>
    );
}

export { NumberPopup };
