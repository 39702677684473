import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'IMPORTANT: Please make sure you are a registered Kotak Neo customer. AlgoTest only supports the Kotak Neo platform ',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'API Activation:  ',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Please go to  ',
                                        },
                                        {
                                            type: 'link',
                                            text: 'Kotak Securities',
                                            url: 'https://www.kotaksecurities.com/',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Click on Products ',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Under Products, click on Neo TradeAPI',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Enter your Client ID  and Click Register for Neo TradeAPI ',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Fill the form with the required details and click on Submit.',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'You will receive your Kotak Neo TradeAPI credentials on your registered email in 24 hours.',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Copy the received credentials and paste them in respective fields below.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Setting Expiry Time:  ',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Please go to ',
                                        },
                                        {
                                            type: 'link',
                                            text: 'Kotak WSO2 API Manager',
                                            url: 'https://napi.kotaksecurities.com/devportal/applications',
                                        },
                                        {
                                            type: 'text',
                                            text: '. Login with your credentials. Click on Application > DefaultApplication',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Click on OAuth2 Tokens ',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Under Key Configurations',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Insert a large value in all Expiry Time fields',
                                        },
                                    ],
                                },
                                sub_items: [
                                    {
                                        type: 'list',
                                        items: [
                                            {
                                                type: 'list_item',
                                                item: {
                                                    type: 'paragraph',
                                                    items: [
                                                        {
                                                            type: 'text',
                                                            text: 'Application Access Token Expiry Time: 36000000000000',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                type: 'list_item',
                                                item: {
                                                    type: 'paragraph',
                                                    items: [
                                                        {
                                                            type: 'text',
                                                            text: 'User Access Token Expiry Time: 36000000000000',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                type: 'list_item',
                                                item: {
                                                    type: 'paragraph',
                                                    items: [
                                                        {
                                                            type: 'text',
                                                            text: 'Refresh Token Expiry Time: 36000000000000',
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                type: 'list_item',
                                                item: {
                                                    type: 'paragraph',
                                                    items: [
                                                        {
                                                            type: 'text',
                                                            text: 'Id Token Expiry Time: 36000000000000',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Click on Update to set those values',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Support Email: ',
                        },
                        {
                            type: 'link',
                            text: 'neo.api@kotak.com',
                            url: 'mailto:neo.api@kotak.com',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'For any issues Contact Kotak Customer Care:',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Call on kotak helpline Toll Free Number – 18002099191 [from 8am to 6pm]',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Live Chat with kotak on WhatsApp - 77389 88888 [from 8am to 8pm]',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const KOTAK_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Consumer Key',
        placeholder: 'Enter Consumer Key',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'Consumer Secret ',
        placeholder: 'Enter Consumer Secret',
        defaultValue: '',
    },
];

export default instructions;
