import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import SearchFilter from 'components/Admin/SearchFilter';
import { INLINE } from 'components/Indicators/DropdownItems';
import { ExpressionMapper } from 'components/Indicators/Popups';
import { OperatorMap } from 'constants/Indicators';
import { useEffect, useState } from 'react';

export const SelectAdvanced = ({ value, items, variant, type, disabled }) => {
    const [filterVal, setFilterVal] = useState('');

    const handleChange = (val) => {
        setFilterVal(val.toUpperCase());
    };
    useEffect(() => {
        setFilterVal('');
    }, [items]);

    items = items.filter((item) => item.label.toUpperCase().includes(filterVal));

    return (
        <Popover className='relative '>
            {({}) => (
                <>
                    <Popover.Button
                        disabled={disabled}
                        className={classNames('flex py-1 text-xs font-medium leading-4', {
                            'rounded-md border border-dashed border-primaryBlue-900 px-3 py-2  text-primaryBlue-900': variant !== INLINE,
                        })}>
                        {type == OperatorMap.MATHBINARY ? ExpressionMapper[value] : value}
                    </Popover.Button>

                    <Popover.Panel className='absolute left-0 z-10 w-max rounded bg-white shadow-xl'>
                        <div className='p-2'>
                            <SearchFilter placeholder='Search' onChange={handleChange} />
                        </div>
                        <div className=' flex max-h-80 flex-col  overflow-auto overscroll-contain'>
                            {items.length === 0 && (
                                <label className='bg-primaryBlack-250 px-3 py-2 text-left text-sm font-semibold text-gray-900 '>No items found</label>
                            )}
                            {items.map((item, i) =>
                                item.type === 'header' ? (
                                    <label key={i} className='bg-primaryBlack-250 px-3 py-2 text-left text-sm font-semibold text-gray-900 '>
                                        {item.label}
                                    </label>
                                ) : (
                                    item.item
                                )
                            )}
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};
