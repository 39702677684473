export const TimeIndicatorName = 'IndicatorType.TimeIndicator';
export const PositionalIndicatorName = 'IndicatorType.PositionalIndicator';
export const VWAPIndicatorName = 'IndicatorType.VWAPIndicator';

export const IndicatorTreeDataNode = 'IndicatorTreeNodeType.DataNode';
export const IndicatorTreeOperandNode = 'IndicatorTreeNodeType.OperandNode';

export const AndNode = 'OperandType.And';
export const OrNode = 'OperandType.Or';

export const CandleIntervals = ['1min', '3min', '5min', '15min', '1hr'];
export const CandleIntervalMap = {
    '1min': '1m',
    '3min': '3m',
    '5min': '5m',
    '15min': '15m',
    '1hr': '1h',
};
export const defaultCandleInterval = CandleIntervalMap['1min'];

export const Operands = ['And', 'Or'];
export const OperandTypeMap = {
    And: 'And',
    Or: 'Or',
};
export const defaultOperandType = 'And';

export const IndicatorTypeMap = {
    RSI: 'RSI',
    SMA: 'SMA',
    RMA: 'RMA',
    EMA: 'EMA',
    MACD: 'MACD',
};

export const IndicatorComplexTypeMap = {
    ATR: 'ATR',
    TR: 'TR',
};

export const Selectors = ['Open', 'Close', 'High', 'Low', 'Volume'];
export const SelectorTypeMap = {
    Open: 'Open',
    Close: 'Close',
    High: 'High',
    Low: 'Low',
    Volume: 'Volume',
};

export const MathBinaryTypeMap = {
    PLUS: 'Plus',
    MINUS: 'Minus',
    TIMES: 'Times',
    DIVIDE: 'Divide',
};

export const ComparatorTypeMap = {
    GreaterThan: 'GreaterThan',
    LessThan: 'LessThan',
    EqualTo: 'EqualTo',
    GreaterThanEqualTo: 'GreaterThanEqualTo',
    LessThanEqualTo: 'LessThanEqualTo',
    NotEqualTo: 'NotEqualTo',
};

export const CrossTypeMap = {
    CrossAbove: 'CrossAbove',
    CrossBelow: 'CrossBelow',
};

export const OperatorMap = {
    SELECTOR: 'Selector',
    Number: 'Number',
    MATHBINARY: 'MathBinary',
    COMPARATOR: 'Comparator',
    CONJUNCTION: 'Conjunction',
    INDICATOR: 'Indicator',
    INDICATORCOMPLEX: 'IndicatorComplex',
    STOCK: 'Stock',
};

export const BodMassOrder = [
    { type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan },
    { type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.LessThan },
    { type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.EqualTo },
    { type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThanEqualTo },
    { type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.LessThanEqualTo },
    { type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.NotEqualTo },
    { type: OperatorMap.COMPARATOR, value: CrossTypeMap.CrossAbove },
    { type: OperatorMap.COMPARATOR, value: CrossTypeMap.CrossBelow },
    { type: OperatorMap.MATHBINARY, value: MathBinaryTypeMap.MINUS },
    { type: OperatorMap.MATHBINARY, value: MathBinaryTypeMap.PLUS },
    { type: OperatorMap.MATHBINARY, value: MathBinaryTypeMap.TIMES },
    { type: OperatorMap.MATHBINARY, value: MathBinaryTypeMap.DIVIDE },
];
