export const SquareOffLabels = ['Partial', 'Complete'];

export const SquareOffMap = {
    Partial: 'False',
    Complete: 'True',
} as const;

export const defaultSquareOffType = SquareOffMap['Partial'];

export const TrailSLtoBreakevenLabels = ['All Legs', 'SL Legs'];
export const TrailSLtoBreakevenMap = {
    'All Legs': 'TrailSLtoBreakeven.AllLegs',
    'SL Legs': 'TrailSLtoBreakeven.StopLossLegs',
} as const;

export const defaultTrailSLtoBreakeven = 'False';
export const defaultTrailSLtoBreakevenSelection = TrailSLtoBreakevenMap['All Legs'];
