import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'https://www.acagarwal.com ',
                            url: 'https://www.acagarwal.com/',
                        },
                        {
                            type: 'text',
                            text: 'and select Trading API from Product and tools tab.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on Request API link and enter Client id, confirm it by entering OTP',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Upon submission Agree to the terms and condition column and click submit',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'You will receive a mail within 24 hours of submission and then can proceed for API key generation',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on API Link, first link under API Dashboard, ',
                        },
                        {
                            type: 'link',
                            text: 'as shared in mail',
                            url: 'https://www.acagarwal.com/',
                        },
                        {
                            type: 'text',
                            text: ' and select Trading API from Product and tools tab.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Create profile by entering details. Field marked with ‘*’ are mandatory',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Verification mail will be sent on your email id. Click the link in the email to verify your email.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Post successful registration & Email address verification. Click ‘Continue to Login’',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Login Id & password will be shared over mail',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Note :
                            Below steps to be initiated only after Activation of API registration request.`,
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Proceed for API Subscription by clicking API Catalogue',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on ‘Create New Application’. It will ask to Validate your XTS login by putting your XTS Client Id and XTS login password shared with you on mail in (5)',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Post validation, subscribe to Interactive and Market Data API',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Under the subscription page, please mention below URL in the ‘Redirect URL’ Box.
                            URL for Marketdata API & Interactive API are mentioned below. Note the URLs are different from the link mentioned in the shared PDF in step 4`,
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        { type: 'text', text: 'MarketData API : ' },
                                        {
                                            type: 'special_link',
                                            text: 'https://developers.symphonyfintech.in/doc/marketdata/',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        { type: 'text', text: 'Interactive API : ' },
                                        {
                                            type: 'special_link',
                                            text: 'https://developers.symphonyfintech.in/doc/interactive/',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Post subscription Login credentials along with secret key will be shared. User can login to Live Environment using the same.                            ',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Copy and paste the API key and secret below, once the App becomes active.                            ',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'For any issue in any of the previous step, please contact Mr. Yogesh Sharma: ',
                        },
                        { type: 'link', text: '+91-6378882400', url: 'tel:+91-6378882400' },
                    ],
                },
            },
        ],
    },
];
export default instructions;
