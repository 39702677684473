import Button from 'components/UI/Button';
import { Number, Select } from 'components/UI/Input';
import Popup from 'components/UI/Popup';
import { ComparatorTypeMap, IndicatorComplexTypeMap, IndicatorTypeMap, OperatorMap, SelectorTypeMap, Selectors } from 'constants/Indicators';
import { useRef, useState } from 'react';
import { DROPDOWN, INLINE } from '../DropdownItems';

function IndicatorPopup({
    type,
    value,
    handleAdd,
    firstItem,
    handleDelete = () => {},
    offset = 0,
    args = { period: '14' },
    series = { type: OperatorMap.SELECTOR, value: SelectorTypeMap.Close },
    variant = DROPDOWN,
    disabled,
}) {
    const [show, setShow] = useState(false);
    const offsetRef = useRef();
    const periodRef = useRef();
    const candleRef = useRef();

    const handleDone = () => {
        const offset = offsetRef.current.getValue();
        const period = periodRef.current.getValue();
        const candle = candleRef?.current?.getValue() ?? SelectorTypeMap.Close;

        const expObj = { type, value, offset, args: { period }, series: { type: OperatorMap.SELECTOR, value: candle } };

        if (value == IndicatorComplexTypeMap.ATR) {
            expObj.args = { ...expObj.args, smooth_func: IndicatorTypeMap.SMA };
            delete expObj.series;
        }
        handleAdd(expObj);
        if (firstItem) {
            if (value == IndicatorTypeMap.RSI) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({ type: OperatorMap.Number, value: 70 });
            } else if (value == IndicatorTypeMap.SMA) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({
                    type: OperatorMap.INDICATOR,
                    value: IndicatorTypeMap.SMA,
                    offset: 0,
                    args: { period: 20 },
                    series: { type: OperatorMap.SELECTOR, value: SelectorTypeMap.Close },
                });
            } else if (value == IndicatorTypeMap.RMA) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({
                    type: OperatorMap.INDICATOR,
                    value: IndicatorTypeMap.RMA,
                    offset: 0,
                    args: { period: 20 },
                    series: { type: OperatorMap.SELECTOR, value: SelectorTypeMap.Close },
                });
            } else if (value == IndicatorTypeMap.EMA) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({
                    type: OperatorMap.INDICATOR,
                    value: IndicatorTypeMap.EMA,
                    offset: 0,
                    args: { period: 20 },
                    series: { type: OperatorMap.SELECTOR, value: SelectorTypeMap.Close },
                });
            } else if (value == IndicatorComplexTypeMap.ATR) {
                handleAdd({ type: OperatorMap.COMPARATOR, value: ComparatorTypeMap.GreaterThan });
                handleAdd({
                    type: OperatorMap.INDICATORCOMPLEX,
                    value: IndicatorComplexTypeMap.ATR,
                    offset: 0,
                    args: { period: 20, smooth_func: IndicatorTypeMap.SMA },
                });
            }
        }

        setShow(false);
    };
    return (
        <>
            {variant === DROPDOWN ? (
                <button
                    disabled={disabled}
                    onClick={() => setShow(true)}
                    className='border-b-2 border-primaryBlack-250 px-3 py-2 text-left text-sm font-medium text-gray-900 hover:bg-primaryBlack-150'>
                    {value}
                </button>
            ) : (
                <button disabled={disabled} onClick={() => setShow(true)} className='py-1 text-xs font-medium leading-4'>
                    {value === IndicatorComplexTypeMap.ATR || value == IndicatorTypeMap.RSI ? (
                        <>
                            {value}({args.period}, {offset})
                        </>
                    ) : (
                        <>
                            {value}({args.period}, {offset}, {series.value})
                        </>
                    )}
                </button>
            )}
            <Popup
                title={value}
                show={show}
                setShow={setShow}
                footer={
                    <>
                        {variant === INLINE && (
                            <Button tertiaryText onClick={handleDelete}>
                                Delete
                            </Button>
                        )}
                        <Button primary onClick={handleDone}>
                            Done
                        </Button>
                    </>
                }>
                <div className='flex flex-col gap-5'>
                    <div className='flex gap-5'>
                        <Number display ref={periodRef} label='Period' defaultValue={args.period} inputClass='w-40' min={1} />
                        <Number display ref={offsetRef} label='Offset' defaultValue={offset} inputClass='w-40' min={0} max={20} />
                    </div>
                    {![IndicatorTypeMap.RSI, IndicatorComplexTypeMap.ATR].includes(value) && (
                        <Select
                            display
                            ref={candleRef}
                            label='Candle'
                            type='vertical'
                            defaultValue={series.value}
                            items={Selectors}
                            valueMap={SelectorTypeMap}
                            className='!w-full'
                        />
                    )}
                </div>
            </Popup>
        </>
    );
}

export { IndicatorPopup };
