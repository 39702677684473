import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'IMPORTANT - Please make sure Autheticator TOTP is setup. Find instructions to setup TOTP here: ',
                        },
                        {
                            type: 'link',
                            text: 'Finvasia TOTP Setup',
                            url: 'https://www.youtube.com/watch?v=zLQAn4E61u0',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'For more information on the login process, check out ',
                        },
                        {
                            type: 'link',
                            text: 'this video (Hindi)',
                            url: 'https://youtu.be/P732zdt36V4',
                        },
                    ],
                },
            },
        ],
    },
];

export const FINVASIA_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Client Code(Case-sensitive, should be all capital letters and Without _U)',
        placeholder: 'Enter Finvasia Client Code',
        defaultValue: '',
        inputParser: (input: string) => input.toUpperCase().replace('_U', ''),
    },
];

export default instructions;
