import { useEffect, useRef } from 'react';

const useBuildId = (interval = 25000) => {
    const buildIdRef = useRef(null);

    useEffect(() => {
        if (process.env.NODE_ENV != 'production') {
            return;
        }

        buildIdRef.current = JSON.parse(document.querySelector('#__NEXT_DATA__').textContent).buildId;

        const id = setInterval(() => {
            try {
                const request = new XMLHttpRequest();
                request.open('HEAD', `/_next/static/${buildIdRef.current}/_buildManifest.js`, false);
                request.setRequestHeader('Pragma', 'no-cache');
                request.setRequestHeader('Cache-Control', 'no-cache');
                request.setRequestHeader('If-Modified-Since', 'Thu, 01 Jun 1970 00:00:00 GMT');
                request.send(null);
                if (request.status === 404) {
                    window.location.reload();
                }
            } catch (error) {
                console.log(error);
            }
        }, interval);

        return () => clearInterval(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {};
};

export default useBuildId;
