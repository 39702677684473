import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: ' If you already have API key & Secret pair, for both the Interactive as well as Market Data, then you can skip steps 2 to 7.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'F&O Activation: Make sure F&O has been activated for your account. You can check the status here: ',
                        },
                        {
                            type: 'link',
                            text: 'IIFL F&O Status',
                            url: 'https://ttweb.indiainfoline.com/Trade/Dashboard.aspx',
                        },
                        {
                            type: 'text',
                            text: ' and activate it from the same link. You can also get in touch with your IIFL RM to activate your F&O',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        { type: 'text', text: 'Login and Go to ' },
                                        { type: 'link', text: 'Dashboard', url: 'https://ttweb.indiainfoline.com/Trade/Dashboard.aspx' },
                                        { type: 'text', text: ' -> My Account -> My Details -> Trading Preference' },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: 'API Activation: Send an email to ' },
                        { type: 'link', text: 'ttblazesupport@iifl.com', url: 'mailto:ttblazesupport@iifl.com' },
                        { type: 'text', text: ' for activating XTS API and Blaze terminal for your account with the following information:' },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Client ID' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Registered Name' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Mobile Number' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'PAN Number' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Date of Birth' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Location(City)' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Segments: CM, F&O' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Email' }] } },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Once you receive a welcome mail with Login ID and password from Blaze IIFL, create a new account on Blaze API using this link: ',
                        },
                        { type: 'link', text: 'Blaze terminal', url: 'https://ttblaze.iifl.com/dashboard#!/login' },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'After creating the account validate your Trading ID with the Login ID and Password received on the mail in the previous step.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Under My App -> Create new app. You need to create an Interactive Data API as well as Market Data API.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Once created you will get a mail with your API key and secret for both the APIs. The current status of the API will be De-activated.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Once the status of both Interactive and Market Data app changes to Active you can copy the respective API key and secret pair, and paste them below.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Make sure you do not use the same API key and secret pair to fire trades from anywhere else, otherwise your executions would run in unexpected errors.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'For any issue in any of the previous step, please contact Mr. Santosh Gupta: ',
                        },
                        { type: 'link', text: '+91-8591403350', url: 'tel:+91-8591403350' },
                    ],
                },
            },
        ],
    },
];
export default instructions;
