import classNames from 'classnames';
import React, { useImperativeHandle, useRef } from 'react';

type TextInputProps = {
    disabled?: boolean;
    inputName: string;
    labelText?: string;
    type?: string;
    placeholder?: string;
    required?: boolean;
    min?: number;
    step?: number;
    defaultValue?: string;
    autoComplete?: string;
    value?: string;
    hasError?: boolean;
    errorText?: string;
    className?: string;
    minimal?: boolean;
    dark?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const TextInput = React.forwardRef(
    (
        {
            disabled,
            inputName,
            labelText,
            type,
            placeholder,
            onBlur,
            onChange,
            required,
            min,
            step,
            defaultValue,
            value,
            hasError,
            errorText,
            className,
            minimal = false,
            dark = false,
        }: TextInputProps,
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);

        const getValue = () => {
            return inputRef.current!.value;
        };

        const setValue = (val: string) => {
            inputRef.current!.value = val;
        };

        useImperativeHandle(ref, () => {
            return { getValue: getValue, setValue: setValue };
        });

        return (
            <div className={classNames('flex w-full flex-col gap-2', { 'opacity-80': disabled })}>
                {labelText && (
                    <label htmlFor={inputName} className='flex items-center gap-2 text-xsm font-normal leading-6'>
                        {labelText}
                    </label>
                )}
                <input
                    type={type || 'text'}
                    placeholder={placeholder}
                    className={classNames(
                        'rounded border border-primaryBlue-200 px-2 py-1 text-xs font-medium text-primaryBlack-700 placeholder:font-medium placeholder:text-primaryBlack-600',
                        className,
                        { 'appearance-none !border-0 !ring-0': minimal },
                        {
                            'border-primaryBlack-525 !bg-transparent !text-white placeholder:!text-primaryBlack-525 focus:!ring-white focus:placeholder:text-white':
                                dark,
                        }
                    )}
                    name={inputName}
                    onBlur={onBlur}
                    onChange={onChange}
                    required={required}
                    min={min}
                    step={step}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    value={value}
                    ref={inputRef}
                    title={value}
                />
                {hasError && <p className=''>{errorText}</p>}
            </div>
        );
    }
);
