import { generateRandomID } from 'constants/RandomID';
import { ACTION_TYPE_MAP } from 'constants/Simulator';
import { IndexLotMapping, IndexStrikeGap } from 'constants/Tickers';
import { FutureChainDataType, OptionChainDataType, SimulatorIlliquidType, SimulatorPositionType } from 'types/Simulator';
import { ValueAtInterpolation } from './PayoffUtils';
import { AnalyserPositionType } from 'types/Analyser';

export const FormKey = (trade: any) => {
    return `${trade.Expiry}_${trade.InstrumentType}_${trade.Strike}`;
};
export const FormTradesFromDiff = (validDiffs: any[], timestamp: string, illiquidData: SimulatorIlliquidType) => {
    const NetPositions: Record<string, SimulatorPositionType> = validDiffs.reduce((acc: Record<string, SimulatorPositionType>, item) => {
        const key = FormKey(item.data);
        const symbolTrades = acc[key]?.trades ?? [];
        const Quantity = item.data.Quantity * item.data.Position + (acc[key]?.Quantity ?? 0);
        symbolTrades.push(item.data);
        const ExpiryTimeStamp = new Date(item.data.Expiry);
        ExpiryTimeStamp.setHours(15, 30, 0, 0);
        const isExpired = ExpiryTimeStamp.getTime() < new Date(timestamp).getTime();
        if (!acc[key]) {
            acc[key] = {
                Key: key,
                Quantity,
                trades: symbolTrades,
                Strike: item.data.Strike,
                Expiry: item.data.Expiry,
                InstrumentType: item.data.InstrumentType,
                Ticker: item.data.Ticker,
                isExpired: isExpired,
                SqoffTime: null,
            };
        } else {
            acc[key].Quantity = Quantity;
            acc[key].trades = symbolTrades;
        }
        if (item.type === ACTION_TYPE_MAP.SQOFF) {
            acc[key].SqoffTime = item.time;
        }
        return acc;
    }, {});

    const validNetPositions = Object.keys(NetPositions).reduce((acc: Record<string, SimulatorPositionType>, key) => {
        if (NetPositions[key].Quantity !== 0 && NetPositions[key].isExpired) {
            //auto sqoff
            const ltp = illiquidData[key]?.close ?? -1;
            const oppTrade = {
                _id: generateRandomID(),
                Position: NetPositions[key].Quantity > 0 ? -1 : 1,
                Quantity: Math.abs(NetPositions[key].Quantity),
                TradedPrice: ltp,
                InstrumentType: NetPositions[key].InstrumentType,
                Strike: NetPositions[key].Strike,
                Expiry: NetPositions[key].Expiry,
                Ticker: NetPositions[key].Ticker,
                TradedTime: timestamp,
            };
            NetPositions[key].trades.push(oppTrade);
            NetPositions[key].Quantity = 0;
        }
        return acc;
    }, NetPositions);

    return validNetPositions;
};

export const findSymbolData = (
    trade: any,
    OptionChainData: OptionChainDataType | null,
    FutureChainData: FutureChainDataType | null,
    illiquidData: SimulatorIlliquidType | null
): { ltp: number; greeks: any; low: null | number; high: null | number } => {
    const expiryKey = trade.Expiry;
    const greeks = { Delta: 0, Gamma: 0, Vega: 0, Theta: 0, IV: 0 };
    let ltp = 0;
    const lotSize = IndexLotMapping[trade.Ticker];
    const lots = trade.Quantity / lotSize;
    let low = null;
    let high = null;

    if (trade.InstrumentType == 0) {
        ltp = FutureChainData?.[expiryKey]?.close ?? 0;
        low = FutureChainData?.[expiryKey]?.low ?? 0;
        high = FutureChainData?.[expiryKey]?.high ?? 0;
        greeks.Delta = lots;
    } else {
        if (!OptionChainData) return { ltp, greeks, low, high };

        if (trade.InstrumentType === 1) {
            ltp = OptionChainData[expiryKey]?.[trade.Strike]?.call_close ?? 0;
            greeks.Delta = (OptionChainData[expiryKey]?.[trade.Strike]?.call_delta ?? 0) * lots;
            greeks.Gamma = (OptionChainData[expiryKey]?.[trade.Strike]?.call_gamma ?? 0) * lots;
            greeks.Vega = (OptionChainData[expiryKey]?.[trade.Strike]?.call_vega ?? 0) * lots;
            low = OptionChainData[expiryKey]?.[trade.Strike]?.call_low ?? 0;
            high = OptionChainData[expiryKey]?.[trade.Strike]?.call_high ?? 0;
        } else {
            ltp = OptionChainData[expiryKey]?.[trade.Strike]?.put_close ?? 0;
            greeks.Delta = (OptionChainData[expiryKey]?.[trade.Strike]?.put_delta ?? 0) * lots;
            greeks.Gamma = (OptionChainData[expiryKey]?.[trade.Strike]?.put_gamma ?? 0) * lots;
            greeks.Vega = (OptionChainData[expiryKey]?.[trade.Strike]?.put_vega ?? 0) * lots;
            low = OptionChainData[expiryKey]?.[trade.Strike]?.put_low ?? 0;
            high = OptionChainData[expiryKey]?.[trade.Strike]?.put_high ?? 0;
        }
        greeks.Theta = (OptionChainData[expiryKey]?.[trade.Strike]?.theta ?? 0) * lots;
        greeks.IV = OptionChainData[expiryKey]?.[trade.Strike]?.implied_volatility ?? 0;
    }

    if (ltp == 0 && illiquidData) {
        const key = FormKey(trade);
        const data = illiquidData[key];
        if (data) {
            ltp = data.close ?? -1;
            greeks.Delta = data.delta;
            greeks.Gamma = data.gamma;
            greeks.Vega = data.vega;
            greeks.Theta = data.theta;
            greeks.IV = data.implied_volatility;
        }
    }

    return { ltp, greeks, low, high };
};

export const calculateMTM = (Data: SimulatorPositionType | AnalyserPositionType, ltp: number) => {
    const lotSize = IndexLotMapping[Data.Ticker];
    const { buyTotal, sellTotal, buyQty, sellQty } = Data.trades.reduce(
        (acc, item) => {
            const lots = item.Quantity / lotSize;
            if (item.Position === 1) {
                acc.buyTotal += item.TradedPrice * lots;
                acc.buyQty += lots;
            } else {
                acc.sellTotal += item.TradedPrice * lots;
                acc.sellQty += lots;
            }
            return acc;
        },
        { buyTotal: 0, sellTotal: 0, buyQty: 0, sellQty: 0 }
    );

    const realizedQTY = Math.min(buyQty, sellQty);
    const unRealizedQTY = buyQty - sellQty;
    const avgBuyPrice = buyQty == 0 ? 0 : buyTotal / buyQty;
    const avgSellPrice = sellQty == 0 ? 0 : sellTotal / sellQty;

    const realizedMTM = realizedQTY * (avgSellPrice - avgBuyPrice) * lotSize;
    const unRealizedMTM = unRealizedQTY * (ltp - (buyQty > sellQty ? avgBuyPrice : avgSellPrice)) * lotSize;

    return { realizedMTM, unRealizedMTM, avgBuyPrice, avgSellPrice };
};

export const calculatePOP = (
    breakeven: number[][],
    underlying: string,
    smallestExpiry: string,
    payoffRange: number[],
    priceRange: number[],
    optionChainData: OptionChainDataType | null
) => {
    const strikeGap = IndexStrikeGap[underlying];
    const lotSize = IndexLotMapping[underlying];

    const breakevenIndexes = breakeven?.map((item) => priceRange.findIndex((p) => p == Math.round(item[0])));
    const PrevNextVals = breakevenIndexes?.map((item) => [payoffRange[item - 1], payoffRange[item + 1]]);

    const Deltas = breakevenIndexes?.map((item) => {
        const AbovevalidStrike = Math.ceil(priceRange[item] / strikeGap) * strikeGap;
        const BelowvalidStrike = Math.floor(priceRange[item] / strikeGap) * strikeGap;
        if (AbovevalidStrike == BelowvalidStrike) {
            const currDelta = findSymbolData(
                { Ticker: underlying, Expiry: smallestExpiry, Strike: AbovevalidStrike, InstrumentType: 1, Quantity: lotSize },
                optionChainData,
                null,
                null
            );
            return currDelta.greeks.Delta;
        }

        const AboveDelta = findSymbolData(
            { Ticker: underlying, Expiry: smallestExpiry, Strike: AbovevalidStrike, InstrumentType: 1, Quantity: lotSize },
            optionChainData,
            null,
            null
        );
        const BelowDelta = findSymbolData(
            { Ticker: underlying, Expiry: smallestExpiry, Strike: BelowvalidStrike, InstrumentType: 1, Quantity: lotSize },
            optionChainData,
            null,
            null
        );

        const AboveDeltaVal = AboveDelta.greeks.Delta;
        const BelowDeltaVal = BelowDelta.greeks.Delta;
        const InterpolatedDelta = ValueAtInterpolation(priceRange[item], BelowvalidStrike, BelowDeltaVal, AbovevalidStrike, AboveDeltaVal);

        return InterpolatedDelta;
    });

    const totalPOP = breakeven.reduce((acc, item, i) => {
        const A = PrevNextVals[i][0];
        const B = PrevNextVals[i][1];
        const delta = Deltas[i];
        // if ((i == 0 && B == 0 && A > 0) || (A > 0 && B == 0) || (A > 0 && B < 0)) {
        //     return acc + 1 - delta;
        // } else if ((A == 0 && B > 0) || (A < 0 && B > 0)) {
        //     return acc + delta;
        // }
        if (i == 0 && A > 0) {
            return acc + 1 - delta;
        } else if (A > 0 && B > 0) {
            return acc;
        } else if (B > 0) {
            return acc + delta;
        } else if (A > 0) {
            return acc - delta;
        }
        return acc;
    }, 0);

    return totalPOP * 100;
};
