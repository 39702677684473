import { equalComparison, greaterThanComparison, greaterThanEqualComparison, lessThanComparison } from 'constants/ComparisonType';
import { INSTRUMENT_KIND_MAPPING } from 'constants/Execution';
import { monthlyExpiry, nextWeeklyExpiry, weeklyExpiry } from 'constants/ExpiryType';
import { ReentryType } from 'constants/ReentryType';
import { CURRENCY_VALUES } from 'constants/ResultDataMapping';
import { IndicatorTemplate, SubscribedTemplate } from 'constants/TemplateName';
import { format } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import { CostConfigType } from 'types/Cost';
import {
    ChildTradeObjectType,
    ChildTradeWiseDataType,
    DTEObjectType,
    DrawdownChartPointType,
    ProfitChartPointType,
    ResultTableDataType,
    ResultTradeObjectType,
    TradeWiseDataType,
} from 'types/Result';
import { IndicatorStgType, Simple920StgType, Straddle920StgType, StrategyType, VWAPStgType } from 'types/Strategy';
import { getCorrelation, getCumulativeSum, maxConsecutivePositiveCount, negateArray, shuffle, sumArray } from './ArrayUtils';
import { AddObjects, BacktestRowCost, DefaultCost, getCostAdjustedValue } from './CostCalulatorUtils';
import { currencyFormat } from './Currency';
import { ISOTimestamp } from './Date';

export const milliseconds_per_day: number = 24 * 60 * 60 * 1000;
const default_yearwise_array: number[] = new Array(15).fill(0).concat([Infinity]);
const index_for_total: number = default_yearwise_array.length - 3;
const index_for_max_dd: number = default_yearwise_array.length - 2;
const index_for_max_dd_period: number = default_yearwise_array.length - 1;
const index_for_romdd: number = default_yearwise_array.length;
const date_format: string = 'dd MMM yyyy';

const BudgetDays: string[] = [
    '02/28/2013',
    '02/17/2014',
    '07/10/2014',
    '02/28/2015',
    '02/29/2016',
    '02/01/2017',
    '02/01/2018',
    '02/01/2019',
    '07/05/2019',
    '02/01/2020',
    '02/01/2021',
    '02/01/2022',
    '02/01/2023',
];

const round_to_5_paise = (x: number) => Math.floor(x * 20) / 20;

const intToDateTime = (date: number, time: number) => {
    const year = Math.trunc(date / 10000);
    const month_day = date % 10000;
    const month = Math.trunc(month_day / 100);
    const day = month_day % 100;

    const minutes = Math.trunc(time / 60);
    const hour = Math.trunc(minutes / 60);
    const minute = minutes % 60;

    return new Date(year, month - 1, day, hour, minute);
};

// The legs have data in form of [entryPrice, exitPrice, quantity, position[1/-1], enDate, enTime, exDate, exTime, Instrument Kind, Strike if any]
const getProfitFromLeg = (leg: ChildTradeWiseDataType, slippage: number) => {
    const en = leg[0];
    const ex = leg[1];
    const qty = leg[2];
    const pos = leg[3];
    const ans = (ex - en) * pos - slippage * (en + ex);
    return qty * ans;
};

const getProfitFromLegs = (legs: ChildTradeWiseDataType[], slippage: number) => {
    let ans = 0;
    for (let i = 0; i < legs.length; i++) {
        ans += getProfitFromLeg(legs[i], slippage);
    }
    return ans;
};

const parseSingleChildTrade = (row: ChildTradeWiseDataType, multiplier: number, slippage: number, ticker: string): ChildTradeObjectType => {
    return {
        EntryPrice: row[0] * (1 + row[3] * slippage),
        ExitPrice: row[1] * (1 - row[3] * slippage),
        Qty: row[2] * multiplier,
        Side: row[3] == -1 ? 'Sell' : 'Buy',
        EntryTime: intToDateTime(row[4], row[5]),
        ExitTime: intToDateTime(row[6], row[7]),
        OptionType: row[8],
        Strike: row[8] === INSTRUMENT_KIND_MAPPING[0] ? '-' : row[9],
        Profit: parseFloat((getProfitFromLeg(row, slippage) * multiplier).toFixed(2)),
        ExpiryDate: intToDateTime(row[10] as number, 55800),
        Ticker: ticker,
    };
};

const dataRowToFormattedResult = (row: TradeWiseDataType, slippage: number, multiplier: number, ticker: string) => {
    const ChildTrades = row[6].map((leg) => parseSingleChildTrade(leg, multiplier, slippage, ticker));

    return {
        EntryTime: intToDateTime(row[0], row[1]),
        UnderlyingAtEntry: row[2],
        ExitTime: intToDateTime(row[3], row[4]),
        UnderlyingAtExit: row[5],
        TradeProfit: parseFloat((multiplier * getProfitFromLegs(row[6], slippage)).toFixed(2)),
        ChildTrades: ChildTrades,
        Cost: BacktestRowCost(ChildTrades, ticker),
    };
};

export const checkNumberIfFloat = (value: number) => {
    return Number(value) === value && value % 1 !== 0;
};

export const mapTradeData = (arr: TradeWiseDataType[], slippage: number, multiplier: number, ticker: string) => {
    return arr.map((row) => dataRowToFormattedResult(row, slippage, multiplier, ticker));
};

export const correlationBetweenTwoTrades = (tradeArrayA: ResultTradeObjectType[], tradeArrayB: ResultTradeObjectType[]) => {
    const numTradesA = tradeArrayA?.length ?? 0;
    const numTradesB = tradeArrayB?.length ?? 0;
    if (numTradesA === 0 || numTradesB === 0) return -999;
    let i = 0;
    let j = 0;
    const seriesA = [];
    const seriesB = [];
    let sumA = 0;
    let sumB = 0;
    while (i < numTradesA && j < numTradesB) {
        const tradeA = tradeArrayA[i];
        const tradeB = tradeArrayB[j];
        if (tradeA.ExitTime <= tradeB.ExitTime) {
            sumB += tradeB.TradeProfit;
            while (i < numTradesA) {
                if (tradeArrayA[i].ExitTime <= tradeB.ExitTime) {
                    sumA += tradeArrayA[i].TradeProfit;
                    i += 1;
                } else {
                    break;
                }
            }
            j += 1;
        } else {
            sumA += tradeA.TradeProfit;
            while (j < numTradesB) {
                if (tradeArrayB[j].ExitTime <= tradeA.ExitTime) {
                    sumB += tradeArrayB[j].TradeProfit;
                    j += 1;
                } else {
                    break;
                }
            }
            i += 1;
        }
        seriesA.push(sumA);
        seriesB.push(sumB);
        sumA = 0;
        sumB = 0;
    }
    if (seriesA.length !== seriesB.length) {
        return 100;
    }
    return getCorrelation(seriesA, seriesB);
};

const getOnlyDate = (d: Date) => {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const getIntradayAggregatedData = (collatedTrades: ResultTradeObjectType[]): ResultTradeObjectType[] => {
    const collatedData: ResultTradeObjectType[] = [...collatedTrades];
    const n = collatedData.length;
    if (n <= 0) {
        return [];
    }
    let i = 0;
    const trades = [];
    while (i < n) {
        let profit = 0;
        const endDate = getOnlyDate(collatedData[i].ExitTime).getTime();
        const startDate = getOnlyDate(collatedData[i].EntryTime);
        let cost = { ...DefaultCost };
        let ChildTrades: ChildTradeObjectType[] = [];
        while (i < n && getOnlyDate(collatedData[i].ExitTime).getTime() === endDate) {
            profit += collatedData[i].TradeProfit;
            ChildTrades = ChildTrades.concat(collatedData[i].ChildTrades);
            if (cost !== undefined && collatedData[i].Cost !== undefined) {
                cost = AddObjects(cost, collatedData[i].Cost!);
            }
            i += 1;
        }
        trades.push({
            EntryTime: new Date(startDate),
            UnderlyingAtEntry: null,
            ExitTime: new Date(endDate),
            UnderlyingAtExit: null,
            TradeProfit: profit,
            ChildTrades: ChildTrades,
            Cost: cost,
        });
    }
    return trades;
};

export const getPositionalMergedData = (collatedData: ResultTradeObjectType[][]): ResultTradeObjectType[] => {
    const num_strat = collatedData.length;
    const currIndex = Array(num_strat).fill(0);
    const maxIndex = collatedData.map((item) => item.length);

    const trades = [];
    while (true) {
        let endDate = null;
        let startDate = null;
        let cost = { ...DefaultCost };
        let ChildTrades: ChildTradeObjectType[] = [];

        for (let i = 0; i < num_strat; i++) {
            const index = currIndex[i];
            if (index < maxIndex[i]) {
                const trade = collatedData[i][index];
                endDate = endDate ? new Date(Math.max(endDate.getTime(), getOnlyDate(trade.ExitTime).getTime())) : getOnlyDate(trade.ExitTime);
                startDate = startDate
                    ? new Date(Math.min(startDate.getTime(), getOnlyDate(trade.EntryTime).getTime()))
                    : getOnlyDate(trade.EntryTime);
            }
        }
        if (startDate === null || endDate === null) {
            break;
        }
        let profit = 0;
        for (let i = 0; i < num_strat; i++) {
            while (true) {
                const index = currIndex[i];
                if (index >= maxIndex[i]) {
                    break;
                }
                const trade = collatedData[i][index];

                if (getOnlyDate(trade.ExitTime) <= endDate) {
                    profit += trade.TradeProfit;
                    ChildTrades = ChildTrades.concat(trade.ChildTrades);
                    cost = AddObjects(cost, trade.Cost!);

                    currIndex[i] += 1;
                } else {
                    break;
                }
            }
        }
        trades.push({
            EntryTime: new Date(startDate),
            UnderlyingAtEntry: null,
            ExitTime: new Date(endDate),
            UnderlyingAtExit: null,
            TradeProfit: profit,
            ChildTrades: ChildTrades,
            Cost: cost,
        });
    }
    return trades;
};

export function CollateData(number: number, takeStrategy: boolean[], filteredData: ResultTradeObjectType[][], strategyTypes: boolean[]) {
    let aggregated_data: ResultTradeObjectType[] = [];
    let intraday_collated_data: ResultTradeObjectType[] = [];
    const positional_collated_data: ResultTradeObjectType[][] = [];
    for (let i = 0; i < number; i++) {
        if (takeStrategy[i]) {
            aggregated_data = aggregated_data.concat(filteredData[i]);
            if (strategyTypes[i]) {
                intraday_collated_data = intraday_collated_data.concat(filteredData[i]);
            } else {
                positional_collated_data.push(filteredData[i]);
            }
        }
    }

    aggregated_data.sort((a, b) => a.ExitTime.getTime() - b.ExitTime.getTime());
    intraday_collated_data.sort((a, b) => a.ExitTime.getTime() - b.ExitTime.getTime());

    positional_collated_data.push(getIntradayAggregatedData(intraday_collated_data));

    const collated_data = getPositionalMergedData(positional_collated_data);
    return { collated_data, aggregated_data };
}

const getProfitArray = (trades: ResultTradeObjectType[], costConfig: CostConfigType) => {
    return trades.map((item) => Math.floor(getCostAdjustedValue(item, costConfig) * 100) / 100); // roundoff to 2 decimal places
};

export const getPointsForPlot = (trades: ResultTradeObjectType[], costConfig: CostConfigType) => {
    const profits = getCumulativeSum(getProfitArray(trades, costConfig));
    return trades.map((trade, index) => {
        const exit_time_formatted = format(trade.ExitTime, date_format);
        const entry_time_formatted = format(trade.EntryTime, date_format);
        return {
            profitTime: exit_time_formatted,
            Profit: round_to_5_paise(profits[index]),
            ExitTime: exit_time_formatted,
            UnderlyingAtExit: trade.UnderlyingAtExit,
            EntryTime: entry_time_formatted,
            UnderlyingAtEntry: trade.UnderlyingAtEntry,
        };
    });
};

export const tradeDaysFilters = (trades: ResultTradeObjectType[], weekdaySet: Set<any>, onlyBudgetDays: boolean, DTE: DTEObjectType) => {
    if (onlyBudgetDays) {
        return trades.filter((item) => BudgetDays.includes(format(item.EntryTime, 'MM/dd/yyyy')));
    } else {
        if (DTE.enabled) {
            return DTEFilter(trades, DTE.comparator, DTE.value);
        }
        return trades.filter((item) => weekdaySet.has(item.EntryTime.getDay()));
    }
};

export const inSampleFilter = (trades: ResultTradeObjectType[], date: Date) => {
    return trades.filter((item) => item.EntryTime <= date);
};

export const outSampleFilter = (trades: ResultTradeObjectType[], date: Date) => {
    return trades.filter((item) => item.EntryTime > date);
};

export const roundingTableData = (number: number[], index: number, n: number) => {
    if (!isFinite(number[index])) {
        return 'No Drawdown';
    }
    if (n === 0) {
        return Math.trunc(number[index]);
    }

    const degree = Math.pow(10, n);
    return Math.floor(number[index] * degree) / degree;
};

const calculateMaxDrawdown = (profits: number[]): number[] => {
    const cumulativeProfit = getCumulativeSum(profits);
    const temp = [];
    if (cumulativeProfit[0] > 0) {
        temp.push([cumulativeProfit[0], 0]);
    } else {
        temp.push([0, -1]);
    }
    for (let i = 1; i < cumulativeProfit.length; i++) {
        if (temp[i - 1][0] > cumulativeProfit[i]) {
            temp.push(temp[i - 1]);
        } else {
            temp.push([cumulativeProfit[i], i]);
        }
    }
    let minVal = Infinity;
    let minIndex = -1;
    let maxIndex = Infinity;
    const temp_drawdown = [];
    for (let i = 0; i < cumulativeProfit.length; i++) {
        const temp_diff = cumulativeProfit[i] - temp[i][0];
        temp_drawdown.push(temp_diff);
        if (temp_diff < minVal) {
            minVal = temp_diff;
            minIndex = temp[i][1];
            maxIndex = i;
        }
    }
    return [minVal, minIndex, maxIndex, maxConsecutivePositiveCount(negateArray(temp_drawdown))];
};

export const calculateDrawdownList = (trades: ResultTradeObjectType[], costConfig: CostConfigType) => {
    const profits = getProfitArray(trades, costConfig);

    const cumulativeProfit = getCumulativeSum(profits);
    const temp = [];
    if (cumulativeProfit[0] > 0) {
        temp.push([cumulativeProfit[0], 0]);
    } else {
        temp.push([0, -1]);
    }
    for (let i = 1; i < cumulativeProfit.length; i++) {
        if (temp[i - 1][0] > cumulativeProfit[i]) {
            temp.push(temp[i - 1]);
        } else {
            temp.push([cumulativeProfit[i], i]);
        }
    }

    const temp_drawdown = [];
    for (let i = 0; i < cumulativeProfit.length; i++) {
        const temp_diff = cumulativeProfit[i] - temp[i][0];
        temp_drawdown.push({
            Drawdown: temp_diff.toFixed(2),
            time: format(trades[i].ExitTime, date_format),
        });
    }
    return temp_drawdown;
};

const getSortedYear = (trades: ResultTradeObjectType[]): number[] => {
    const year: Set<number> = new Set();
    trades.forEach((element) => {
        year.add(element.ExitTime.getFullYear());
    });
    return Array.from(year).sort((a, b) => a - b);
};

const getDrawdownTotalDays = (trades: ResultTradeObjectType[], minIndex: number, maxIndex: number) => {
    return Math.max(Math.trunc((trades[maxIndex].ExitTime.getTime() - trades[minIndex + 1].EntryTime.getTime()) / milliseconds_per_day), 0) + 1;
};

const getDrawdownPeriod = (trades: ResultTradeObjectType[], minIndex: number, maxIndex: number) => {
    return trades[minIndex + 1].EntryTime.toLocaleDateString() + ' to ' + trades[maxIndex].ExitTime.toLocaleDateString();
};

const getOverallDrawdownTime = (trades: ResultTradeObjectType[], minIndex: number, maxIndex: number) => {
    return getDrawdownTotalDays(trades, minIndex, maxIndex) + ' [' + getDrawdownPeriod(trades, minIndex, maxIndex) + ']';
};

export const getTableData = (trades: ResultTradeObjectType[], costConfig: CostConfigType): ResultTableDataType[] => {
    const years = getSortedYear(trades);
    const table: ResultTableDataType[] = [];
    years.forEach((year) => {
        const row: ResultTableDataType = [year].concat(default_yearwise_array) as ResultTableDataType;
        const yearTrades = trades.filter((item) => item.ExitTime.getFullYear() === year);
        const num_trades = yearTrades.length;
        if (num_trades !== 0) {
            const profits = getProfitArray(yearTrades, costConfig);
            const last_day = yearTrades[num_trades - 1];
            const first_day = yearTrades[0];
            const num_days = Math.trunc((last_day.ExitTime.getTime() - first_day.EntryTime.getTime()) / milliseconds_per_day) + 1;
            const [max_dd, max_dd_min_index, max_dd_max_index] = calculateMaxDrawdown(profits);
            const overall_profit_in_year = sumArray(profits);
            yearTrades.forEach((trade) => {
                const exitMonth = trade.ExitTime.getMonth() + 1;
                row[exitMonth] = (row[exitMonth] as number) + getCostAdjustedValue(trade, costConfig);
            });
            row[index_for_total] = overall_profit_in_year;
            row[index_for_max_dd] = max_dd;
            row[index_for_max_dd_period] = max_dd < 0 ? getOverallDrawdownTime(yearTrades, max_dd_min_index, max_dd_max_index) : 'NA []';
            row[index_for_romdd] = max_dd < 0 ? overall_profit_in_year / (num_days / 365) / (-1 * max_dd) : Infinity;
        }
        table.push(row);
    });
    return table;
};

const runSimulations = (value: number, profits: number[]) => {
    const drawdownArr: number[] = [];
    for (let i = 0; i < value; i++) {
        const shuffledProfits = shuffle(profits);
        const [max_drowdown] = calculateMaxDrawdown(shuffledProfits);
        drawdownArr.push(max_drowdown);
    }
    drawdownArr.sort((a, b) => b - a);

    return drawdownArr;
};

export const MonteCarloDrawdown = (trades: ResultTradeObjectType[], simulations: number, costConfig: CostConfigType) => {
    const profits = getProfitArray(trades, costConfig);
    const drawdownArr = runSimulations(simulations, profits);
    return drawdownArr;
};

//Takes sorted list of Trades, Sorted by exit day. Naturally the trades array should be sorted.
export const getSummary = (trades: ResultTradeObjectType[], costConfig: CostConfigType) => {
    const number_of_trades = trades.length;
    if (number_of_trades === 0) {
        return null;
    }
    const profit = getProfitArray(trades, costConfig);
    const last_day = trades[number_of_trades - 1];
    const first_day = trades[0];
    const number_of_days = Math.trunc((last_day.ExitTime.getTime() - first_day.EntryTime.getTime()) / milliseconds_per_day) + 1;
    const losing_profits = profit.filter((item) => item <= 0);
    const winning_profits = profit.filter((item) => item > 0);
    const winning_ratio = winning_profits.length / number_of_trades;
    const overallProfit = sumArray(profit);
    const avg_win_profit = winning_ratio > 0 ? sumArray(winning_profits) / winning_profits.length : 0;
    const avg_lose_profit = winning_ratio < 1 ? sumArray(losing_profits) / losing_profits.length : 0;
    const reward_to_risk_ratio = avg_lose_profit < 0 ? avg_win_profit / (-1 * avg_lose_profit) : 0;
    const [max_drawdown, max_dd_min_index, max_dd_max_index, max_time_dd] = calculateMaxDrawdown(profit);
    const expectancy = reward_to_risk_ratio * winning_ratio - (1 - winning_ratio);
    const max_winning_streak = maxConsecutivePositiveCount(profit);
    const max_losing_streak = maxConsecutivePositiveCount(negateArray(profit));
    const avg_profit = overallProfit / number_of_trades;
    const return_over_mdd = max_drawdown < 0 ? overallProfit / (number_of_days / 365) / (-1 * max_drawdown) : 'No Drawdown';

    return {
        OverallProfit: round_to_5_paise(overallProfit),
        NumberOfTrades: number_of_trades,
        AverageProfitPerTrade: avg_profit,
        WinningRatio: 100 * winning_ratio,
        LosingRatio: 100 * (1 - winning_ratio),
        AverageProfitPerWinningTrade: avg_win_profit,
        AverageProfitPerLosingTrade: avg_lose_profit,
        MaximumProfitInSingleTrade: round_to_5_paise(Math.max(...profit)),
        MinimumProfitInSingleTrade: round_to_5_paise(Math.min(...profit)),
        MaximumDrawdown: round_to_5_paise(max_drawdown),
        MaxDrawdownTotalDays: max_drawdown < 0 ? getOverallDrawdownTime(trades, max_dd_min_index, max_dd_max_index) : 0,
        ReturnOverMaximumDrawdown: return_over_mdd,
        RewardToRiskRatio: reward_to_risk_ratio,
        Expectancy: expectancy,
        MaximumWinningStreak: max_winning_streak,
        MaximumLosingStreak: max_losing_streak,
        MaximumTimeDrawdown: max_time_dd,
    };
};

export const currencyFormatConditionally = (key: string, number: number) => {
    if (CURRENCY_VALUES.includes(key)) {
        return currencyFormat(Number(number));
    }

    return number;
};

export function debounce(func: Function, timeout: number = 500) {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // @ts-ignore
            func.apply(this, args);
        }, timeout);
    };
}

export function throttle(func: Function, timeout: number = 500) {
    let wait: boolean = false;
    let storedArgs: any = null;

    function checkStoredArgs() {
        if (storedArgs == null) {
            wait = false;
        } else {
            func(...storedArgs);
            storedArgs = null;
            setTimeout(checkStoredArgs, timeout);
        }
    }

    return (...args: any[]) => {
        if (wait) {
            storedArgs = args;
            return;
        }

        func(...args);
        wait = true;
        setTimeout(checkStoredArgs, timeout);
    };
}

export const findHighestExpiry = (expiries: string[]) => {
    if (!expiries) return monthlyExpiry;
    if (expiries.some((val) => val === monthlyExpiry)) return monthlyExpiry;
    else if (expiries.some((val) => val === nextWeeklyExpiry)) return nextWeeklyExpiry;
    else if (expiries.some((val) => val === weeklyExpiry)) return weeklyExpiry;
};

export const DTEFilter = (trades: ResultTradeObjectType[], comparator: string, value: number) => {
    if (comparator === '') return trades;
    const intVal = parseInt(String(value));
    return trades
        .map((a) => ({
            ...a,
            ChildTrades: a.ChildTrades.filter(({ ExpiryDate, EntryTime }) => {
                const timeDiff = parseInt(String((ExpiryDate.getTime() - EntryTime.getTime()) / milliseconds_per_day));
                if (comparator === greaterThanEqualComparison) {
                    return timeDiff >= intVal;
                } else if (comparator === greaterThanComparison) {
                    return timeDiff > intVal;
                } else if (comparator === equalComparison) {
                    return timeDiff === intVal;
                } else if (comparator === lessThanComparison) {
                    return timeDiff < intVal;
                } else {
                    return timeDiff <= intVal;
                }
            }),
        }))
        .filter((a) => a.ChildTrades.length > 0);
};

export const timeFilterOptions = (points: ProfitChartPointType[]) => {
    if (points.length === 0) return ['Max'];

    const lastDate = new Date(points[points.length - 1].profitTime).getTime();
    const firstDate = new Date(points[0].profitTime).getTime();
    const diff = Math.trunc((lastDate - firstDate) / milliseconds_per_day) + 1;
    const options = ['Max'];
    if (diff >= 730) {
        options.push('2Y', '1Y', '6M', '1M');
    } else if (diff >= 365) {
        options.push('1Y', '6M', '1M');
    } else if (diff > 183) {
        options.push('6M', '1M');
    } else if (diff >= 28) {
        options.push('1M');
    }
    return options.reverse();
};

export const filterChartPoints = (points: ProfitChartPointType[] | DrawdownChartPointType[], filter: number, isDrawdown: boolean) => {
    if (points.length === 0) return points;

    if (isDrawdown) {
        points = points as DrawdownChartPointType[];
        const lastDate = new Date(points[points.length - 1].time);
        return points.filter((data) => new Date(data['time']).getTime() >= new Date(lastDate.getTime() - filter * milliseconds_per_day).getTime());
    } else {
        points = points as ProfitChartPointType[];
        const lastDate = new Date(points[points.length - 1].profitTime);
        return points.filter(
            (data) => new Date(data['profitTime']).getTime() >= new Date(lastDate.getTime() - filter * milliseconds_per_day).getTime()
        );
    }
};

export const handleBacktestMixpanel = (strat: StrategyType, template: string) => {
    const strategy = strat as IndicatorStgType | Simple920StgType | Straddle920StgType | VWAPStgType;
    let mixpanelObj: any = { template: template };

    if (template === SubscribedTemplate) {
        mixpanel.track('start_backtest_clicked', mixpanelObj);
        return;
    }

    if (template !== IndicatorTemplate) {
        mixpanelObj = { ...mixpanelObj, legs_count: strategy.ListOfLegConfigs.length };
    }
    if (strategy.OverallSL.Type !== 'None') {
        mixpanelObj = { ...mixpanelObj, overall_sl: strategy.OverallSL.Type.split('.')[1] };
    }
    if (strategy.OverallTgt.Type !== 'None') {
        mixpanelObj = { ...mixpanelObj, overall_tgt: strategy.OverallTgt.Type.split('.')[1] };
    }
    if (strategy.OverallReentrySL.Type !== 'None') {
        // @ts-ignore
        const type = Object.keys(ReentryType).find((key: any) => ReentryType[key] === strategy.OverallReentrySL.Type);
        mixpanelObj = { ...mixpanelObj, overall_reentry_sl: type };
    }
    if (strategy.OverallReentryTgt.Type !== 'None') {
        // @ts-ignore
        const type = Object.keys(ReentryType).find((key: any) => ReentryType[key] === strategy.OverallReentryTgt.Type);
        mixpanelObj = { ...mixpanelObj, overall_reentry_tgt: type };
    }
    // @ts-ignore
    if (strategy.OverallTrailSL.Type !== 'None' && strategy.LockAndTrail.Type === 'None') {
        mixpanelObj = { ...mixpanelObj, trailing_options: 'OverallTrailSL' };
    }
    // @ts-ignore
    if (template !== IndicatorTemplate && strategy.LockAndTrail.Type !== 'None') {
        mixpanelObj = { ...mixpanelObj, trailing_options: strategy.OverallTrailSL.Type !== 'None' ? 'LockAndTrail' : 'Lock' };
    }

    mixpanel.track('start_backtest_clicked', mixpanelObj);
};

export const VixFilter = (trades: ResultTradeObjectType[], vixValues: Record<string, number>, vixFrom: number, vixTo: number) => {
    if (!vixValues || Object.keys(vixValues)?.length === 0 || trades.length === 0) return trades;
    const entryTime = ISOTimestamp(trades?.[0]?.EntryTime).slice(11, 19);
    let currVixVal = 0;
    return trades
        .map((trade) => {
            const tradeTime = ISOTimestamp(trade.EntryTime).slice(0, 19);
            const vixVal = vixValues[tradeTime];
            if (tradeTime.slice(11) === entryTime) {
                currVixVal = vixVal;
            }
            return { ...trade, vix: currVixVal };
        })
        .filter((trade) => {
            const vixVal = trade.vix;
            const isInVixRange = vixVal ? Boolean(vixVal >= vixFrom && vixVal <= vixTo) : true;
            return isInVixRange;
        });
};
