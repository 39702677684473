export const Help = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='h-6 w-6' {...props}>
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
        />
    </svg>
);

export const EmptyState = (props: any) => (
    <svg viewBox='0 0 201 160' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' {...props}>
        <rect x='0.5' width='200' height='160' fill='white' />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M166.1 52C169.193 52 171.7 54.5072 171.7 57.6C171.7 60.6928 169.193 63.2 166.1 63.2H134.1C137.193 63.2 139.7 65.7072 139.7 68.8C139.7 71.8928 137.193 74.4 134.1 74.4H151.7C154.793 74.4 157.3 76.9072 157.3 80C157.3 83.0928 154.793 85.6 151.7 85.6H143.561C139.661 85.6 136.5 88.1072 136.5 91.2C136.5 93.2619 138.1 95.1285 141.3 96.8C144.393 96.8 146.9 99.3072 146.9 102.4C146.9 105.493 144.393 108 141.3 108H74.9001C71.8073 108 69.3001 105.493 69.3001 102.4C69.3001 99.3072 71.8073 96.8 74.9001 96.8H43.7001C40.6073 96.8 38.1001 94.2928 38.1001 91.2C38.1001 88.1072 40.6073 85.6 43.7001 85.6H75.7001C78.7929 85.6 81.3001 83.0928 81.3001 80C81.3001 76.9072 78.7929 74.4 75.7001 74.4H55.7001C52.6073 74.4 50.1001 71.8928 50.1001 68.8C50.1001 65.7072 52.6073 63.2 55.7001 63.2H87.7001C84.6073 63.2 82.1001 60.6928 82.1001 57.6C82.1001 54.5072 84.6073 52 87.7001 52H166.1ZM166.1 74.4C169.193 74.4 171.7 76.9072 171.7 80C171.7 83.0928 169.193 85.6 166.1 85.6C163.007 85.6 160.5 83.0928 160.5 80C160.5 76.9072 163.007 74.4 166.1 74.4Z'
            fill='#F3F3F3'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M123.437 51.1999L130.879 105.474L131.547 110.919C131.763 112.673 130.515 114.27 128.761 114.485L81.9131 120.238C80.159 120.453 78.5624 119.205 78.347 117.451L71.1344 58.7093C71.0267 57.8323 71.6504 57.034 72.5275 56.9263C72.533 56.9256 72.5386 56.9249 72.5441 56.9243L86.1 54.7999'
            fill='white'
        />
        <path
            d='M124.676 51.0301C124.582 50.3462 123.952 49.8677 123.268 49.9615C122.584 50.0553 122.105 50.6858 122.199 51.3697L124.676 51.0301ZM130.879 105.474L132.12 105.322C132.119 105.316 132.118 105.31 132.117 105.304L130.879 105.474ZM131.547 110.919L132.788 110.767L131.547 110.919ZM128.761 114.485L128.914 115.726L128.761 114.485ZM81.9131 120.238L82.0655 121.478L81.9131 120.238ZM78.347 117.451L79.5877 117.299L78.347 117.451ZM71.1344 58.7093L72.3751 58.557L71.1344 58.7093ZM72.5441 56.9243L72.6835 58.1665C72.7016 58.1645 72.7196 58.1621 72.7377 58.1592L72.5441 56.9243ZM86.2935 56.0348C86.9756 55.9279 87.4418 55.2884 87.3349 54.6064C87.228 53.9243 86.5885 53.4581 85.9065 53.565L86.2935 56.0348ZM122.199 51.3697L129.641 105.644L132.117 105.304L124.676 51.0301L122.199 51.3697ZM129.638 105.626L130.307 111.071L132.788 110.767L132.12 105.322L129.638 105.626ZM130.307 111.071C130.438 112.14 129.678 113.113 128.609 113.245L128.914 115.726C131.353 115.426 133.088 113.206 132.788 110.767L130.307 111.071ZM128.609 113.245L81.7608 118.997L82.0655 121.478L128.914 115.726L128.609 113.245ZM81.7608 118.997C80.6919 119.128 79.7189 118.368 79.5877 117.299L77.1063 117.604C77.4059 120.043 79.6261 121.778 82.0655 121.478L81.7608 118.997ZM79.5877 117.299L72.3751 58.557L69.8937 58.8617L77.1063 117.604L79.5877 117.299ZM72.3751 58.557C72.3515 58.3651 72.488 58.1905 72.6798 58.1669L72.3752 55.6856C70.8129 55.8774 69.7019 57.2994 69.8937 58.8617L72.3751 58.557ZM72.6798 58.1669C72.681 58.1668 72.6822 58.1667 72.6835 58.1665L72.4048 55.6821C72.3949 55.6832 72.385 55.6844 72.3752 55.6856L72.6798 58.1669ZM72.7377 58.1592L86.2935 56.0348L85.9065 53.565L72.3506 55.6894L72.7377 58.1592Z'
            fill='#3A3A3A'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M121.412 54.6155L128.148 103.802L128.753 108.737C128.949 110.327 127.833 111.772 126.263 111.965L84.309 117.116C82.7381 117.309 81.3064 116.176 81.1112 114.587L74.64 61.8826C74.5054 60.7862 75.285 59.7883 76.3813 59.6537L81.1744 59.0652'
            fill='#F3F3F3'
        />
        <path
            d='M87.0874 44C87.0874 42.4812 88.3186 41.25 89.8374 41.25H125.152C125.881 41.25 126.58 41.5395 127.096 42.0549L137.381 52.3343C137.897 52.8501 138.187 53.5498 138.187 54.2795V103.2C138.187 104.719 136.956 105.95 135.437 105.95H89.8374C88.3186 105.95 87.0874 104.719 87.0874 103.2V44Z'
            fill='white'
            stroke='#3A3A3A'
            stroke-width='2.5'
        />
        <path
            d='M125.837 41.9224V51.2001C125.837 52.5256 126.912 53.6001 128.237 53.6001H137.3'
            stroke='#3A3A3A'
            stroke-width='2.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M94.8999 94.4001H115.7M94.8999 53.6001H115.7H94.8999ZM94.8999 63.2001H129.3H94.8999ZM94.8999 73.6001H129.3H94.8999ZM94.8999 84.0001H129.3H94.8999Z'
            stroke='#3A3A3A'
            stroke-width='2.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);
