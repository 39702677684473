import { flipObject } from 'utils/ArrayUtils';

export const STOP_SQUARE_OFF_REFRESH_REQUEST_TIMEOUT = 2500;
export const STOP_SQUARE_OFF_ALL_STRATEGIES_REFRESH_REQUEST_TIMEOUT = 5000;
export const START_STRATEGY_REFRESH_REQUEST_TIMEOUT = 3500;
export const EXECUTION_LIST_REFRESH_REQUEST_TIMEOUT = 30000;
export const ACTIVE_EXECUTION_REFRESH_REQUEST_TIMEOUT = 15000;
export const PROMO_FT_LIMIT = 50;

export const EXECUTION_STATE_MAPPING = {
    pending_live: 'Deployment is Pending',
    live: 'Running',
    pending_stop: 'Execution is being stopped',
    stop: 'Execution is in Manual Mode',
    pending_square_off: 'Execution is being squared off',
    square_off: 'Execution is Squared Off',
    error: 'Execution is in Error',
};

export const SHORT_EXECUTION_STATE_MAPPING = {
    pending_live: 'Pending',
    live: 'Running',
    pending_stop: 'Stopping',
    stop: 'Stopped',
    pending_square_off: 'Squaring Off',
    square_off: 'Squared Off',
    error: 'Error',
};

export const EXECUTION_LIVE_STATUS = 'live';
export const EXECUTION_PENDING_LIVE_STATUS = 'pending_live';
export const EXECUTION_PENDING_STOP_STATUS = 'pending_stop';
export const EXECUTION_STOP_STATUS = 'stop';
export const EXECUTION_PENDING_SQUARE_OFF_STATUS = 'pending_square_off';
export const EXECUTION_SQUARE_OFF_STATUS = 'square_off';
export const EXECUTION_ERROR_STATUS = 'error';
export const EXECUTION_ARCHIVED_STATUS = 'archived';
export const EXECUTION_SIGNAL_STATUS = 'signal';

export const EXECUTION_STATUS_FILTER_MAP = {
    stop: {
        label: 'Manual',
        affectedStates: [EXECUTION_STOP_STATUS, EXECUTION_PENDING_STOP_STATUS],
    },
    live: {
        label: 'Live',
        affectedStates: [EXECUTION_LIVE_STATUS, EXECUTION_PENDING_LIVE_STATUS],
    },
    square_off: {
        label: 'Sq Off',
        affectedStates: [EXECUTION_SQUARE_OFF_STATUS, EXECUTION_PENDING_SQUARE_OFF_STATUS],
    },
    signal: {
        label: 'Signal',
        affectedStates: [EXECUTION_SIGNAL_STATUS],
    },
    archived: {
        label: 'Archived',
        affectedStates: [EXECUTION_ARCHIVED_STATUS],
    },
};

export const EXECUTION_STATUS_FILTER_STATES = Object.keys(EXECUTION_STATUS_FILTER_MAP);

export const ExchangeTimeLabel = 'Exchange Time';

export const PortfolioNameLabel = 'Portfolio name';

export const TRADE_DETAILS_MAPPING = {
    Symbol: 'Symbol',
    Broker: 'Broker',
    Position: 'Buy/Sell',
    TradedPrice: 'Trade Price',
    ExchangeTime: ExchangeTimeLabel,
    Quantity: 'Quantity',
    TradeKind: 'Entry/Exit',
    InstrumentType: 'Instrument Type',
    Strike: 'Strike',
    TriggerPrice: 'Trigger Price',
    TriggerTime: 'Trigger Time',
    StopLossSet: 'Stop Loss',
    TargetSet: 'Target',
    UnderlyingAtTradeTime: 'Underlying',
    TradedTime: 'Report Time',
};

export const PAST_TRADE_DETAILS_MAPPING = {
    PortfolioName: PortfolioNameLabel,
    StrategyName: 'Strategy name',
    Date: 'Date',
    Symbol: 'Symbol',
    Broker: 'Broker',
    Position: 'Buy/Sell',
    TradedPrice: 'Trade Price',
    ExchangeTime: ExchangeTimeLabel,
    Quantity: 'Qty',
    TradeKind: 'Entry/Exit',
    InstrumentType: 'Instr.',
    Strike: 'Strike',
    TriggerPrice: 'Trigger Price',
    TriggerTime: 'Trigger Time',
    StopLossSet: 'SL',
    TargetSet: 'Tgt',
    UnderlyingAtTradeTime: 'Underlying',
    TradedTime: 'Report Time',
};

export const PAST_TRADES_HEADING = Object.values(PAST_TRADE_DETAILS_MAPPING);

export const TRADE_DETAILS_HEADING = Object.values(TRADE_DETAILS_MAPPING);

export const NOTIFICATIONS_MAPPING = {
    Time: 'Time',
    Message: 'Message',
};

export const NOTIFICATIONS_HEADING = Object.values(NOTIFICATIONS_MAPPING);

export const POSITION_MAPPING = {
    '-1': 'Sell',
    1: 'Buy',
};

export const TRADE_KIND_MAPPING = {
    '-1': 'Exit',
    1: 'Entry',
};

export const LEG_INSTRUMENT_TYPES = ['Futures', 'Options'];
export const LEG_INSTRUMENT_TYPES_MAPPING = {
    Futures: 'Futures',
    Options: 'Options',
};

export const INSTRUMENT_KIND_MAPPING: Record<number, string> = {
    [-1]: 'PE',
    1: 'CE',
    0: 'FUT',
    2: 'CASH',
};

export const REV_INSTRUMENT_KIND_MAPPING: Record<string, string> = flipObject(INSTRUMENT_KIND_MAPPING);

export const SQUARE_OFF_ALL_ACTION = 'square_off_all';
export const STOP_ALL_ACTION = 'stop_all';
export const STOP_ALL_PORTFOLIO_ACTION = 'stop_all_portfolio';
export const STOP_ACTION = 'stop';
export const SQUARE_OFF_ACTION = 'square_off';
export const STOP_PORTFOLIO_ACTION = 'stop_portfolio';
export const SQUARE_OFF_PORTFOLIO_ACTION = 'square_off_portfolio';
export const CANCEL_DEPLOYMENT = 'cancel_deployment';
export const CANCEL_PORTFOLIO_DEPLOYMENT = 'cancel_deployment_portfolio';
export const CANCEL_ALL_DEPLOYMENT = 'cancel_all_deployment';

export const BROKER_NOT_LOGGED_IN = 'broker_not_logged_in';
export const BROKER_NOT_SETUP = 'broker_not_setup';

export const popupText = (action: string) => {
    if (action === SQUARE_OFF_ALL_ACTION) {
        return {
            Head: 'Are you sure you want to square off all the live strategies and portfolios?',
            Text: 'You will not be able to revert this change.',
            ButtonText: 'Square Off All',
        };
    } else if (action === STOP_ALL_ACTION) {
        return {
            Head: 'Are you sure you want to switch all the live strategies and portfolios to manual mode?',
            Text: 'You will not be able to revert these changes',
            ButtonText: 'Switch All',
        };
    } else if (action === STOP_ACTION || action === STOP_PORTFOLIO_ACTION) {
        return {
            Head: 'Are you sure you want to switch to manual?',
            Text: 'You will not be able to revert to active mode.',
            ButtonText: 'Switch',
        };
    } else if (action === SQUARE_OFF_ACTION || action === SQUARE_OFF_PORTFOLIO_ACTION) {
        return {
            Head: 'Are you sure you want to square off?',
            Text: 'You will not be able to revert to active mode.',
            ButtonText: 'Square Off',
        };
    } else if (action === BROKER_NOT_SETUP) {
        return {
            Head: 'You haven’t setup any broker',
            Text: 'You need to setup atleast 1 broker to be able to activate a strategy. Please setup a broker.',
            ButtonText: 'Setup a broker',
        };
    } else if (action === BROKER_NOT_LOGGED_IN) {
        return {
            Head: 'You are not logged in with a broker.',
            Text: 'You need to be logged in to a broker in order to activate a strategy. Please login first.',
            ButtonText: 'Login with a broker',
        };
    } else if (action === CANCEL_DEPLOYMENT || action === CANCEL_PORTFOLIO_DEPLOYMENT) {
        return {
            Head: 'Are you sure you want to cancel this deployment?',
            Text: 'You will not be able to revert this change.',
            ButtonText: 'Cancel Deployment',
        };
    } else if (action === CANCEL_ALL_DEPLOYMENT) {
        return {
            Head: 'Are you sure you want to cancel the deployment of all live strategies and portfolios?',
            Text: 'You will not be able to revert this changes.',
            ButtonText: 'Cancel All Deployments',
        };
    }
};

export const RunningLegsHeaders = [
    'Instrument',
    'Qty',
    'Entry Price',
    'Entry Time',
    'Initial SL',
    'Updated SL',
    'Target',
    'Underlying',
    'Trigger Price',
    'Trigger Time',
    'Report Time',
    'LTP',
    'MTM',
];

export const StoppedLegsHeaders = [
    'Instrument',
    'Qty',
    'Entry Price',
    'Entry Time',
    'Initial SL',
    'Updated SL',
    'Target',
    'Exit Price',
    'Exit Time',
    'MTM',
    '',
];

export const LegLocalStorageKey = 'execution_leg';
export const LegLocalStorageKeyIlliquid = 'illiquid_execution_leg';
