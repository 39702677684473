import { useEffect } from 'react';

export function useKeyDown(keyCode, callback) {
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === keyCode) {
                callback();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [callback, keyCode]);
}
