export const OverallReentryLabels = ['RE ASAP', 'RE ASAP ↩', 'RE MOMENTUM', 'RE MOMENTUM ↩'];

export const LegReentryLabels = OverallReentryLabels.concat(['RE COST', 'RE COST ↩']);

export const ReentryWithoutMomentumLabels = ['RE ASAP', 'RE ASAP ↩', 'RE COST', 'RE COST ↩'];

export const ReentryType = {
    'RE ASAP': 'ReentryType.Immediate',
    'RE ASAP ↩': 'ReentryType.ImmediateReverse',
    'RE COST': 'ReentryType.AtCost',
    'RE COST ↩': 'ReentryType.AtCostReverse',
    'RE MOMENTUM': 'ReentryType.LikeOriginal',
    'RE MOMENTUM ↩': 'ReentryType.LikeOriginalReverse',
} as const;

export const defaultReentryType = ReentryType['RE ASAP'];

export const defaultReentryConfig = { Type: 'None', Value: {} };
