import { useEffect } from 'react';
export function useClickedOutside(setShow, ref) {
    useEffect(() => {
        const listener = (event) => {
            if (ref.current === null) return;
            if (ref.current && event.target && ref.current.contains(event.target)) {
                return;
            }
            setShow(false);
        };
        document.addEventListener('click', listener, { capture: true });
        return () => {
            document.removeEventListener('click', listener, { capture: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
