export const NoResults = () => (
    <svg width='85' height='35' viewBox='0 0 85 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M84 10C84 8.89543 83.105 8 82 8H17C15.8954 8 15 8.89543 15 10C15 11.1046 15.8954 12 17 12H23.5C24.3284 12 25 12.6716 25 13.5C25 14.3284 24.3284 15 23.5 15H2.5C1.1193 15 0 16.1193 0 17.5C0 18.8807 1.1193 20 2.5 20H23C24.1046 20 25 20.8954 25 22C25 23.1046 24.1046 24 23 24H18C16.8954 24 16 24.8954 16 26C16 27.1046 16.8954 28 18 28H25H60H83C84.105 28 85 27.1046 85 26C85 24.8954 84.105 24 83 24H62C60.895 24 60 23.1046 60 22C60 20.8954 60.895 20 62 20H76.5C77.881 20 79 18.8807 79 17.5C79 16.1193 77.881 15 76.5 15H61.5C60.672 15 60 14.3284 60 13.5C60 12.6716 60.672 12 61.5 12H82C83.105 12 84 11.1046 84 10Z'
            fill='white'
            fillOpacity='0.1'
        />
        <path
            d='M54.75 27.75L49.1202 22.1202M49.1202 22.1202C50.5906 20.6499 51.5 18.6187 51.5 16.375C51.5 11.8877 47.8623 8.25 43.375 8.25C38.8877 8.25 35.25 11.8877 35.25 16.375C35.25 20.8623 38.8877 24.5 43.375 24.5C45.6187 24.5 47.6499 23.5906 49.1202 22.1202Z'
            stroke='#808080'
            strokeWidth='1.625'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const NoSetup = (props: any) => (
    <svg width='186' height='143' viewBox='0 0 186 143' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <rect x='23' y='34.5' width='141' height='25' rx='12.5' fill='#F5F5F5' />
        <rect x='6' y='71.5' width='175' height='31' rx='15.5' fill='#F5F5F5' />
        <rect x='20' y='100.5' width='147' height='27' rx='13.5' fill='#F5F5F5' />
        <path
            d='M131 35L135.43 36.5277C141.175 38.5087 144.532 44.4712 143.247 50.4105L131 107C131 113.627 125.627 119 119 119H68C61.3726 119 56 113.627 56 107V102.5V35C56 28.3726 61.3726 23 68 23H119C125.627 23 131 28.3726 131 35Z'
            fill='white'
        />
        <path
            d='M56 35C56 33.8514 54.7913 33.1044 53.7639 33.618L45.9396 37.5302C43.0474 38.9763 41.5342 42.2258 42.2888 45.3702L56 102.5V35Z'
            fill='white'
        />
        <path
            d='M131 35C131 28.3726 125.627 23 119 23H68M131 35V107M131 35L135.43 36.5277M131 107C131 113.627 125.627 119 119 119M131 107V107C131 113.627 125.627 119 119 119V119M131 107L143.247 50.4105M56 102.5L42.2888 45.3702C41.5342 42.2258 43.0474 38.9763 45.9396 37.5302L53.7639 33.618C54.7913 33.1044 56 33.8514 56 35V35M56 102.5V107M56 102.5V35M56 107V35M56 107C56 113.627 61.3726 119 68 119M56 107V107C56 113.627 61.3726 119 68 119V119M56 35C56 28.3726 61.3726 23 68 23M56 35V35C56 28.3726 61.3726 23 68 23V23M119 119H68M135.43 36.5277C141.175 38.5087 144.532 44.4712 143.247 50.4105M135.43 36.5277V36.5277C141.175 38.5087 144.532 44.4712 143.247 50.4105V50.4105'
            stroke='#3A3A3A'
            strokeLinecap='round'
            strokeDasharray='4 4'
        />
        <circle cx='93' cy='69' r='22' fill='#F5F5F5' />
        <path d='M93.4545 59V69M93.4545 79V69M93.4545 69H84H103.273' stroke='#3A3A3A' strokeWidth='3.63636' strokeLinecap='round' />
    </svg>
);

export const NoEntries = (props: any) => (
    <svg width='77' height='56' viewBox='0 0 77 56' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M40.4211 3.12778C50.081 2.05312 61.8951 -3.69578 68.1287 3.76157C74.3766 11.2361 66.8325 21.9264 63.9007 31.2168C61.4334 39.0349 59.9396 47.5887 52.9472 51.8686C45.1483 56.6421 35.4286 57.2539 26.9482 53.8345C17.7195 50.1134 8.86521 43.2489 7.22787 33.4339C5.65895 24.029 12.4561 15.6007 19.4976 9.17173C25.1381 4.02184 32.8301 3.97228 40.4211 3.12778Z'
            fill='#F8F8F8'
        />
        <path
            d='M25.5 29H30.6459C31.7822 29 32.821 29.642 33.3292 30.6584L33.6708 31.3416C34.179 32.358 35.2178 33 36.3541 33H40.6459C41.7822 33 42.821 32.358 43.3292 31.3416L43.6708 30.6584C44.179 29.642 45.2178 29 46.3541 29H51.5M25.5 29.4511V35C25.5 36.6569 26.8431 38 28.5 38H48.5C50.1569 38 51.5 36.6569 51.5 35V29.4511C51.5 29.152 51.4553 28.8547 51.3673 28.5688L48.1516 18.1177C47.7643 16.859 46.6013 16 45.2843 16H31.7157C30.3987 16 29.2357 16.859 28.8484 18.1177L25.6327 28.5688C25.5447 28.8547 25.5 29.152 25.5 29.4511Z'
            stroke='#B7B7B7'
            stroke-width='1.2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const NoSignals = (props: any) => (
    <svg width='134' height='96' viewBox='0 0 134 96' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M128 20C131.093 20 133.6 22.5072 133.6 25.6C133.6 28.6928 131.093 31.2 128 31.2H96C99.0928 31.2 101.6 33.7072 101.6 36.8C101.6 39.8928 99.0928 42.4 96 42.4H113.6C116.693 42.4 119.2 44.9072 119.2 48C119.2 51.0928 116.693 53.6 113.6 53.6H105.461C101.561 53.6 98.4 56.1072 98.4 59.2C98.4 61.2619 100 63.1285 103.2 64.8C106.293 64.8 108.8 67.3072 108.8 70.4C108.8 73.4928 106.293 76 103.2 76H36.8C33.7072 76 31.2 73.4928 31.2 70.4C31.2 67.3072 33.7072 64.8 36.8 64.8H5.6C2.50721 64.8 0 62.2928 0 59.2C0 56.1072 2.50721 53.6 5.6 53.6H37.6C40.6928 53.6 43.2 51.0928 43.2 48C43.2 44.9072 40.6928 42.4 37.6 42.4H17.6C14.5072 42.4 12 39.8928 12 36.8C12 33.7072 14.5072 31.2 17.6 31.2H49.6C46.5072 31.2 44 28.6928 44 25.6C44 22.5072 46.5072 20 49.6 20H128ZM128 42.4C131.093 42.4 133.6 44.9072 133.6 48C133.6 51.0928 131.093 53.6 128 53.6C124.907 53.6 122.4 51.0928 122.4 48C122.4 44.9072 124.907 42.4 128 42.4Z'
            fill='#F3F3F3'
        />
        <path
            d='M50.4502 51H56.8826C58.303 51 59.6014 51.8025 60.2367 53.073L60.6637 53.9271C61.2989 55.1975 62.5974 56 64.0178 56H69.3826C70.803 56 72.1014 55.1975 72.7367 53.9271L73.1637 53.0729C73.7989 51.8025 75.0974 51 76.5178 51H82.9502M50.4502 51.5639V58.5C50.4502 60.5711 52.1291 62.25 54.2002 62.25H79.2002C81.2713 62.25 82.9502 60.5711 82.9502 58.5V51.5639C82.9502 51.1901 82.8943 50.8183 82.7844 50.4611L78.7647 37.3972C78.2806 35.8237 76.8268 34.75 75.1805 34.75H58.2199C56.5736 34.75 55.1198 35.8237 54.6357 37.3972L50.616 50.4611C50.5061 50.8183 50.4502 51.1901 50.4502 51.5639Z'
            stroke='#808080'
            stroke-width='1.25'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);
