import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getLocalStorageNudge } from 'utils/NudgeUtils';
import { Tooltip } from '../Tooltip';
import NudgeTooltip from '../Tooltip/NudgeTooltip';

type NumberProps = {
    value?: number;
    defaultValue?: number;
    display?: string | boolean;
    inline?: boolean;
    disabled?: boolean;
    label?: string;
    tooltipContent?: string;
    min?: number;
    max?: number;
    step?: number;
    appender?: string;
    secondaryLabel?: string;
    inputClass?: string | null;
    defaultNudgeKey?: string | undefined;
    nudgeContent?: string;
    minimal?: boolean;
    onBlur?: (value: number) => void;
    sendBack?: (value: number) => void;
    nudgeChecker?: (value: number) => boolean;
};

export const Number = React.forwardRef(
    (
        {
            value,
            defaultValue,
            display,
            inline,
            disabled,
            label,
            tooltipContent,
            min,
            max,
            step,
            appender,
            secondaryLabel,
            inputClass = null,
            defaultNudgeKey,
            nudgeChecker,
            nudgeContent,
            sendBack,
            onBlur,
            minimal = false,
        }: NumberProps,
        ref
    ) => {
        const [nudgeKey, setNudgeKey] = useState<string | undefined>(undefined);

        const inputRef = useRef<HTMLInputElement>(null);
        const getValue = () => {
            return +inputRef.current!.value;
        };
        const setValue = (val: number) => {
            inputRef.current!.value = val.toString();
        };

        useImperativeHandle(ref, () => {
            return { getValue: getValue, setValue: setValue };
        });

        const inputChangeHandler = () => {
            const value = +inputRef.current!.value;

            if (sendBack) {
                sendBack(value);
            }
            if (defaultNudgeKey) {
                const isDisabled = getLocalStorageNudge(defaultNudgeKey);
                if (!isDisabled && nudgeChecker && nudgeChecker(value)) {
                    setNudgeKey(defaultNudgeKey);
                } else {
                    setNudgeKey(undefined);
                }
            }
        };

        const handleBlur = () => {
            const value = +inputRef.current!.value;
            if (onBlur) {
                onBlur(value);
            }
        };

        useEffect(() => {
            inputRef.current!.value = (defaultValue || 0).toString();
        }, [defaultValue]);

        return (
            <div
                className={classNames('flex flex-col gap-2', {
                    hidden: !display,
                    '!flex-row': inline,
                    'pointer-events-none opacity-80': disabled,
                })}>
                {label && (
                    <label className={classNames('flex items-center gap-2 text-xsm font-normal capitalize leading-6')}>
                        {label}
                        {tooltipContent && <Tooltip info={tooltipContent} />}
                    </label>
                )}

                <div className='relative w-max'>
                    <input
                        title='input-num'
                        disabled={disabled}
                        className={classNames('w-20 rounded border border-primaryBlue-200 px-1 py-2 text-xs', 'md:text-xs/4', inputClass, {
                            'w-28 pr-7': appender,
                            'appearance-none !border-0': minimal,
                        })}
                        type='number'
                        ref={inputRef}
                        value={value}
                        min={min !== undefined ? min : 1}
                        max={max}
                        step={step || 1}
                        onChange={inputChangeHandler}
                        onBlur={handleBlur}
                        onWheel={(e) => {
                            e.currentTarget?.blur();
                            setTimeout(() => {
                                e.currentTarget?.focus();
                            }, 0);
                        }}
                    />
                    {appender && <div className='z-1 pointer-events-none absolute right-0 top-2 mr-2  text-sm '>{appender}</div>}
                </div>
                {nudgeKey && <NudgeTooltip nudgeKey={defaultNudgeKey} content={nudgeContent} setNudgeKey={setNudgeKey} />}

                {secondaryLabel && <label className='text-sm font-normal leading-5'>{secondaryLabel}</label>}
            </div>
        );
    }
);
