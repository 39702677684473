import { BankexTicker, BankniftyTicker, FinniftyTicker, IndexLotMapping, MidcapTicker, NiftyTicker, SensexTicker } from 'constants/Tickers';
import { lastDayOfMonth, parse } from 'date-fns';
import { BarType, OHLCDataType, SymbolTickDataType, TVChartDataType, TVStudyTemplateDataType } from 'types/Chart';
import { showAlert } from './AlertUtils';
import { convertDateToString } from './Date';
import { parseSymbol } from './SymbolUtils';

const DEBUG = false;
const ChartKey = 'TV_charts';
const StudyKey = 'TV_studies';
const DrawingKey = 'TV_drawings';

const resolutionInSeconds: Record<string, number> = {
    '1S': 1,
    '2S': 2,
    '5S': 5,
    1: 60,
    3: 180,
    5: 300,
    15: 900,
    30: 1800,
    60: 3600,
    120: 7200,
    240: 14400,
    '1D': 86400,
    '1W': 604800,
    '1M': 2592000,
};
const resolutionToMilliSeconds = (resolution: string) => resolutionInSeconds[resolution] * 1000;

export function LogInfo(func: string, ...args: any[]) {
    if (DEBUG) {
        console.log(`[${func}]:`, ...args);
    }
}

export const formattedTime = (time: string | number | Date): number => new Date(time).getTime();

export const getNextTime = (time: number | Date) => {
    const date = new Date(time);
    const tradingEndTime = new Date(time);
    tradingEndTime.setHours(15, 30, 0, 0);
    if (date < tradingEndTime) {
        tradingEndTime.setDate(tradingEndTime.getDate() - 1);
    }
    return tradingEndTime.getTime();
};

export const getNextBarTime = (barTime: number, resolution: string) => barTime + resolutionToMilliSeconds(resolution);

export const getNextTradeTime = (tradeTime: number) => getNextBarTime(tradeTime, '1S');

export const separateSymbolsFromQuantity = (symbols: string[]): [string[], number[]] => [
    symbols?.map((symbol) => symbol?.slice(0, symbol.indexOf('('))),
    symbols?.map((symbol) => parseInt(symbol?.slice(symbol.indexOf('(') + 1, symbol.indexOf(')')))),
];

export const getPriceForSymbol = (tradeTime: number, historicalPrices: SymbolTickDataType[]) => {
    let reqIndex = 0;
    historicalPrices?.some((data, index) => {
        reqIndex = index;
        return data?.exchange_time >= tradeTime;
    });
    if (historicalPrices[reqIndex]?.exchange_time === tradeTime) return reqIndex;
    return reqIndex - 1;
};

export const parseDate = (dateString: string) => {
    const dateArr = dateString.split('-');
    dateString = dateString.replaceAll('-', ' ');
    if (dateArr.length === 2) {
        const dateObj = parse(dateString, 'MMM yyyy', new Date());
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth();
        const firstDayOfMonth = new Date(year, month);
        return new Date(lastDayOfMonth(firstDayOfMonth)).getTime();
    } else {
        const dateObj = parse(dateString, 'dd MMM yyyy', new Date());
        return new Date(dateObj).getTime();
    }
};

export const updateBars = (bars: BarType[], time: number, price: number, volume: number, resolution: string) => {
    const lastbar = bars[bars.length - 1];
    const nextBarTime = getNextBarTime(lastbar?.time, resolution);

    let currVolume = volume;
    if (bars.length > 1) {
        currVolume = volume - (bars[bars.length - 2]?.startVolume ?? volume);
    }

    if (!lastbar || time >= nextBarTime) {
        bars.push({
            time: time,
            low: price,
            high: price,
            open: price,
            close: price,
            volume: volume,
            startVolume: volume,
        });
    } else {
        bars[bars.length - 1] = {
            ...lastbar,
            high: Math.max(lastbar.high, price),
            low: Math.min(lastbar.low, price),
            close: price,
            volume: currVolume,
            startVolume: volume,
        };
    }
    return bars;
};

const build1mBars = (
    symbols: string[],
    symbolData: { symbol: string; data: SymbolTickDataType[] }[],
    resolution: string,
    lastHistoricalData: any,
    storeHistorical: boolean
): BarType[] => {
    if (!symbolData) return [];

    if (symbolData.some((symbol) => symbol?.data?.length === 0) && symbols[0] !== '') {
        const emptySymbol = symbolData.find((symbol) => symbol.data?.length === 0);
        console.error(`${emptySymbol?.symbol}: Not enough data to display chart. Please select a different contract`);
        return [];
    }

    const bars: BarType[] = [];

    const lowestCommonTime = Math.max(...symbolData.map((item) => item.data[0].exchange_time));

    let i = 0;
    while (i < symbolData.length) {
        const index = getPriceForSymbol(lowestCommonTime, symbolData[i].data);
        const newData = symbolData[i].data.slice(index);
        newData[0].exchange_time = lowestCommonTime;
        i++;
    }

    if (symbolData.some((symbol) => symbol?.data?.length === 0) && symbols[0] !== '') {
        const emptySymbol = symbolData.find((symbol) => symbol.data?.length === 0);
        console.error(`${emptySymbol?.symbol}: No data after common time`);
        return [];
    }

    const price: number = symbolData.reduce((acc, curr) => acc + curr.data[0].price, 0);
    const volume: number = symbolData.reduce((acc, curr) => acc + curr.data[0].volume, 0);
    updateBars(bars, lowestCommonTime, price, volume, resolution);

    let check = true;
    let tradeTime = lowestCommonTime;
    while (check) {
        let totalPrice = 0;
        let totalVolume = 0;
        symbolData.every((symbol) => {
            const symbolHistoricalData = symbol.data;
            const symbolTime = symbolHistoricalData[1]?.exchange_time;
            if (symbolTime === tradeTime) {
                totalPrice += symbolHistoricalData[1].price;
                totalVolume += symbolHistoricalData[1].volume;
            } else {
                const reqIndex = getPriceForSymbol(tradeTime, symbolHistoricalData);
                totalPrice += symbolHistoricalData[reqIndex].price;
                totalVolume += symbolHistoricalData[reqIndex].volume;
                const nextTradeTime = getNextTradeTime(tradeTime);
                const nextBarTime = symbolHistoricalData[reqIndex]?.exchange_time;
                if (nextBarTime && nextBarTime < nextTradeTime) {
                    symbol.data = symbolHistoricalData.slice(reqIndex);
                }
            }
            totalPrice = parseFloat(totalPrice.toFixed(3));
            totalVolume = parseFloat(totalVolume.toFixed(3));

            if (symbol.data.length >= 2 && storeHistorical) {
                lastHistoricalData.set(symbol.symbol, [
                    { exchange_time: symbol.data[0].exchange_time, price: symbol.data[0].price, volume: symbol.data[0].volume },
                    { exchange_time: symbol.data[1].exchange_time, price: symbol.data[1].price, volume: symbol.data[1].volume },
                ]);
            }

            return true;
        });
        updateBars(bars, tradeTime, totalPrice, totalVolume, resolution);
        tradeTime = getNextTradeTime(tradeTime);
        check = symbolData.every((item) => item.data.length > 2);
    }
    return bars;
};

const convert1mtoResolution = (bars: BarType[], resolution: string): BarType[] => {
    const period = resolutionToMilliSeconds(resolution) / resolutionToMilliSeconds('1');
    const intervalCount = Math.ceil(bars.length / period);
    const newBars = [];
    for (let i = 0; i < intervalCount; i++) {
        let currBar = bars[period * i];
        for (let j = 0; j < period; j++) {
            if (period * i + j >= bars.length) {
                break;
            }
            const elem = bars[period * i + j];
            currBar = {
                ...currBar,
                high: Math.max(currBar.high, elem.high),
                low: Math.min(currBar.low, elem.low),
                close: elem.close,
                volume: elem.volume + currBar.volume,
            };
        }
        newBars.push(currBar);
    }
    return newBars;
};

export const buildBars = (
    symbols: string[],
    symbolData: { symbol: string; data: SymbolTickDataType[] }[],
    resolution: string,
    date: string,
    lastHistoricalData: any,
    firstDataRequest: boolean
): BarType[] => {
    const today = convertDateToString(new Date(), 0);
    const storeHistorical = firstDataRequest && date === today;

    const Bars1m = build1mBars(symbols, symbolData, '1', lastHistoricalData, storeHistorical);

    const bars = convert1mtoResolution(Bars1m, resolution);

    return bars;
};

export const saveLoadAdapter = (symbols: string) => {
    return {
        charts: JSON.parse(localStorage.getItem(ChartKey) ?? '[]'),
        studyTemplates: JSON.parse(localStorage.getItem(StudyKey) ?? '[]'),
        drawingTemplates: JSON.parse(localStorage.getItem(DrawingKey) ?? '[]'),
        getAllCharts: function () {
            return Promise.resolve(this.charts);
        },

        removeChart: function (id: string) {
            for (let i = 0; i < this.charts.length; ++i) {
                if (this.charts[i].id === id) {
                    this.charts.splice(i, 1);
                    localStorage.setItem(ChartKey, JSON.stringify(this.charts));
                    return Promise.resolve();
                }
            }

            return Promise.reject();
        },

        saveChart: function (chartData: TVChartDataType) {
            if (!chartData.id) {
                chartData.id = Math.random().toString();
            } else {
                this.removeChart(chartData.id);
            }

            this.charts.push(chartData);
            localStorage.setItem(ChartKey, JSON.stringify(this.charts));
            return Promise.resolve(chartData.id);
        },

        getChartContent: function (id: string) {
            const charts: TVChartDataType[] = JSON.parse(localStorage.getItem(ChartKey) ?? '[]');
            for (let i = 0; i < charts.length; ++i) {
                if (charts[i].id === id) {
                    if (charts[i].symbol === symbols) {
                        return Promise.resolve(charts[i].content);
                    } else {
                        showAlert.error('Layout is not available for the current selected symbols');
                        return Promise.reject();
                    }
                }
            }

            return Promise.reject();
        },

        removeStudyTemplate: function (studyTemplateData: TVStudyTemplateDataType) {
            for (let i = 0; i < this.studyTemplates.length; ++i) {
                if (this.studyTemplates[i].name === studyTemplateData.name) {
                    this.studyTemplates.splice(i, 1);
                    localStorage.setItem(StudyKey, JSON.stringify(this.studyTemplates));
                    return Promise.resolve();
                }
            }

            return Promise.reject();
        },

        getStudyTemplateContent: function (studyTemplateData: TVStudyTemplateDataType) {
            for (let i = 0; i < this.studyTemplates.length; ++i) {
                if (this.studyTemplates[i].name === studyTemplateData.name) {
                    return Promise.resolve(this.studyTemplates[i]);
                }
            }

            return Promise.reject();
        },

        saveStudyTemplate: function (studyTemplateData: TVStudyTemplateDataType) {
            for (let i = 0; i < this.studyTemplates.length; ++i) {
                if (this.studyTemplates[i].name === studyTemplateData.name) {
                    this.studyTemplates.splice(i, 1);
                    localStorage.setItem(StudyKey, JSON.stringify(this.studyTemplates));
                    break;
                }
            }

            this.studyTemplates.push(studyTemplateData);
            localStorage.setItem(StudyKey, JSON.stringify(this.studyTemplates));
            return Promise.resolve();
        },

        getAllStudyTemplates: function () {
            return Promise.resolve(this.studyTemplates);
        },

        removeDrawingTemplate: function (toolName: string, templateName: string) {
            for (let i = 0; i < this.drawingTemplates.length; ++i) {
                if (this.drawingTemplates[i].name === templateName) {
                    this.drawingTemplates.splice(i, 1);
                    localStorage.setItem(DrawingKey, JSON.stringify(this.drawingTemplates));
                    return Promise.resolve();
                }
            }

            return Promise.reject();
        },

        loadDrawingTemplate: function (toolName: string, templateName: string) {
            for (let i = 0; i < this.drawingTemplates.length; ++i) {
                if (this.drawingTemplates[i].name === templateName) {
                    return Promise.resolve(this.drawingTemplates[i].content);
                }
            }

            return Promise.reject();
        },

        saveDrawingTemplate: function (toolName: string, templateName: string, content: string) {
            for (let i = 0; i < this.drawingTemplates.length; ++i) {
                if (this.drawingTemplates[i].name === templateName) {
                    this.drawingTemplates.splice(i, 1);
                    localStorage.setItem(DrawingKey, JSON.stringify(this.drawingTemplates));
                    break;
                }
            }

            this.drawingTemplates.push({ name: templateName, content: content });
            localStorage.setItem(DrawingKey, JSON.stringify(this.drawingTemplates));
            return Promise.resolve();
        },

        getDrawingTemplates: function () {
            return Promise.resolve(this.drawingTemplates.map((template: TVStudyTemplateDataType) => template.name));
        },
    };
};

export const NetPosToLeg = (symbol: string, netPosition: any) => {
    const { Ticker } = parseSymbol(symbol);
    const lotSize = IndexLotMapping[Ticker];
    return `${symbol}(${netPosition[symbol] > 0 ? '-' : '+'}${Math.abs(netPosition[symbol] / lotSize)})`;
};

export const handleDefaultTicker = (expiries: string[]) => {
    const formattedExpiries = expiries?.map((expiry) => expiry.split('(')[0]);

    if (formattedExpiries?.includes(NiftyTicker)) return NiftyTicker;
    if (formattedExpiries?.includes(BankniftyTicker)) return BankniftyTicker;
    if (formattedExpiries?.includes(FinniftyTicker)) return FinniftyTicker;
    if (formattedExpiries?.includes(MidcapTicker)) return MidcapTicker;
    if (formattedExpiries?.includes(BankexTicker) || formattedExpiries?.includes(SensexTicker)) return SensexTicker;
    else {
        return BankniftyTicker;
    }
};
