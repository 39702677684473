import { defaultEntryTimeInput, defaultExitTimeInput } from 'constants/EntryExitTime';
import { AndNode, IndicatorTreeDataNode, IndicatorTreeOperandNode, TimeIndicatorName } from 'constants/Indicators';
import { defaultSquareOffType, defaultTrailSLtoBreakeven } from 'constants/LegwiseSL';
import { defaultOverallTgtSLConfig } from 'constants/OverallTgtStopLossType';
import { NO_POSITIONAL } from 'constants/Positional';
import { defaultReentryConfig } from 'constants/ReentryType';
import { defaultStrategyType } from 'constants/StrategyType';
import { Straddle920Template } from 'constants/TemplateName';
import { defaultTicker } from 'constants/Tickers';
import { defaultLockProfitConfig, defaultTrailStopLossConfig } from 'constants/TrailStopLossType';
import { defaultUnderlying } from 'constants/Underlying';
import { Straddle920StgAttributes } from 'types/Strategy';

const defaultEntryTime = {
    Type: IndicatorTreeDataNode,
    Value: {
        IndicatorName: TimeIndicatorName,
        Parameters: defaultEntryTimeInput,
    },
};

const defaultExitTime = {
    Type: IndicatorTreeDataNode,
    Value: {
        IndicatorName: TimeIndicatorName,
        Parameters: defaultExitTimeInput,
    },
};

export const default920StraddleStrategy = {
    Ticker: defaultTicker,
    TakeUnderlyingFromCashOrNot: defaultUnderlying,
    SquareOffAllLegs: defaultSquareOffType,
    TrailSLtoBreakeven: defaultTrailSLtoBreakeven,
    ListOfLegConfigs: [],
    OverallTgt: defaultOverallTgtSLConfig,
    OverallSL: defaultOverallTgtSLConfig,
    OverallTrailSL: defaultTrailStopLossConfig,
    OverallReentrySL: defaultReentryConfig,
    OverallReentryTgt: defaultReentryConfig,
    StrategyType: defaultStrategyType,
    MaxPositionInADay: 1,
    EntryIndicators: {
        Type: IndicatorTreeOperandNode,
        OperandType: AndNode,
        Value: [defaultEntryTime],
    },
    ExitIndicators: {
        Type: IndicatorTreeOperandNode,
        OperandType: AndNode,
        Value: [defaultExitTime],
    },
    LockAndTrail: defaultLockProfitConfig,
};

export const default920StraddleStrategyAttributes: Straddle920StgAttributes = {
    template: Straddle920Template,
    positional: NO_POSITIONAL,
};
