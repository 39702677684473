import NoSSR from 'layouts/NoSSR';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import DefaultLayout from './DefaultLayout';

const Navigation = dynamic(() => import('components/Navigation/Navigation'), {
    ssr: false,
});

const LandingHeader = dynamic(() => import('components/Navigation/LandingHeader'), {
    ssr: false,
});

const HeaderLayout = ({ children, ...props }) => {
    const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);

    return (
        <>
            <NoSSR>{isLoggedIn ? <Navigation /> : <LandingHeader />}</NoSSR>
            <DefaultLayout {...props}>{children}</DefaultLayout>
        </>
    );
};

export default HeaderLayout;
