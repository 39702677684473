import { Email, Facebook, LinkedIn, ShareIcon, Telegram, Twitter } from 'assets';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { showAlert } from 'utils/AlertUtils';

type ShareButtonProps = {
    url: string;
    text: string;
};

type ShareLink = {
    name: string;
    url: string;
    icon: ReactElement;
};

function ShareButton({ url, text }: ShareButtonProps) {
    const toggle = () => {
        if (navigator.share === undefined) {
            showAlert.error('Unable to Share, try using a different browser');
        } else {
            navigator
                .share({
                    title: text,
                    text: text,
                    url: url,
                })
                .catch(console.error);
        }
    };

    const shareLinks: ShareLink[] = [
        {
            name: 'Telegram',
            icon: <Telegram className={classNames('h-5 text-primaryBlue-900', 'md:h-6')} />,
            url: `https://telegram.me/share?url=${url}&text=${text}`,
        },
        {
            name: 'Twitter',
            icon: <Twitter className={classNames('h-5 text-primaryBlue-900', 'md:h-6')} />,
            url: `https://twitter.com/intent/tweet?url=${url}&text=${text}&via=Algotest_in`,
        },
        {
            name: 'Facebook',
            icon: <Facebook className={classNames('h-5 text-primaryBlue-900', 'md:h-6')} />,
            url: `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${text}`,
        },
        {
            name: 'LinkedIn',
            icon: <LinkedIn className={classNames('h-5 text-primaryBlue-900', 'md:h-6')} />,
            url: `https://www.linkedin.com/shareArticle?mini=true&summary=Algotest_in&url=${encodeURIComponent(url)}&title=${text}`,
        },
        {
            name: 'Email',
            icon: <Email className={classNames('h-5 text-primaryBlue-900', 'md:h-6')} />,
            url: `mailto:?subject=${url}&body=${text}`,
        },
    ];

    return (
        <div className='mt-5 flex flex-wrap items-center gap-5'>
            {shareLinks.map((item: ShareLink, i: number) => (
                <a key={i} href={item.url} target='_blank' rel='noreferrer' className='flex flex-col items-center gap-1'>
                    {item.icon}
                    <span className={classNames('text-xxs font-semibold', 'md:text-xs')}>{item.name}</span>
                </a>
            ))}
            <a onClick={toggle} className='flex flex-col items-center gap-1'>
                <ShareIcon className={classNames('h-5 text-primaryBlue-900', 'md:h-6')} />
                <span className={classNames('text-xxs font-semibold', 'md:text-xs')}>Share </span>
            </a>
        </div>
    );
}

export { ShareButton };
