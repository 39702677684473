import { configureStore } from '@reduxjs/toolkit';
import { adminSlice } from './adminStore';
import { authSlice } from './authStore';
import { brokerSlice } from './brokerStore';
import { configSlice } from './configStore';
import { executionSlice } from './executionStore';
import { livepriceSlice } from './livepriceStore';
import { optionchainSlice } from './optionchainStore';
import { portfolioSlice } from './portfolioStore';
import { pricingSlice } from './pricingStore';
import { profileSlice } from './profileStore';
import { simulatorSlice } from './simulatorStore';
import { strategySlice } from './strategyStore';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        admin: adminSlice.reducer,
        broker: brokerSlice.reducer,
        config: configSlice.reducer,
        execution: executionSlice.reducer,
        liveprice: livepriceSlice.reducer,
        optionchain: optionchainSlice.reducer,
        portfolio: portfolioSlice.reducer,
        profile: profileSlice.reducer,
        pricing: pricingSlice.reducer,
        strategy: strategySlice.reducer,
        simulator: simulatorSlice.reducer,
    },
    devTools: JSON.parse(process.env.NEXT_PUBLIC_REDUX_DEV_TOOLS),
});

export default store;
