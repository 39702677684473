const NUDGE_LS_KEY: string = 'nudge_dns';

export const generateNudgeLSKey = (key: string | undefined) => {
    return `nudge_${key}_dns`;
};

export const setLocalStorageNudge = (key: string | undefined) => {
    const existingNudges = JSON.parse(localStorage.getItem(NUDGE_LS_KEY) || '[]');
    localStorage.setItem('nudge_dns', JSON.stringify([...existingNudges, generateNudgeLSKey(key)]));
};

export const getLocalStorageNudge = (key: string | undefined) => {
    const existingNudges = JSON.parse(localStorage.getItem(NUDGE_LS_KEY) || '[]');
    return existingNudges.includes(generateNudgeLSKey(key));
};
