import { GetOptionChainLtp } from 'handlers/Charts/ChartsHandler';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { livepriceActions } from 'store/livepriceStore';
import { IS_IN_ACTIVATE_STRATEGY_HOUR } from 'utils/BrokerUtils';
import { candleFeedURL, parsePrices, priceFeedURL, subscribeUnsubscribeIndex } from 'utils/LivePriceUtils';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const LIVE_RECONNECT_INTERVAL = 10000;
const PAPER_PRICES_FREQUENCY = 10 * 1000;

export const useOptionChainHook = ({ Ticker, Expiry, forDemo }) => {
    const dispatch = useDispatch();
    const [readyState, setReadyState] = useState(0);

    const clientref = useRef(window?.clientCandle);
    const LTPpollingRef = useRef();
    const LivePollingRef = useRef();

    const { liveIndex } = useSelector((store) => store.liveprice);

    const websocketUrl = priceFeedURL;

    const setLivePrices = (prices) => dispatch(livepriceActions.updatePrices({ prices, isPaper: false }));
    const setLiveIndex = ({ Ticker, Expiry }) => dispatch(livepriceActions.subscribeIndex({ Ticker, Expiry }));

    const handleMsgSend = (msg, retry = 0) => {
        try {
            clientref.current?.send(JSON.stringify(msg));
        } catch (error) {
            if (retry < 5) {
                setTimeout(() => handleMsgSend(msg, retry + 1), 2000);
            }
        }
    };

    const connectServer = () => {
        const areActiveTradingHours = IS_IN_ACTIVATE_STRATEGY_HOUR();
        if (!areActiveTradingHours || !Ticker || !Expiry) {
            disconnectServer();
            return;
        }

        if (!window.clientCandle || window.clientCandle?.readyState === 3) {
            dispatch(livepriceActions.updateLoading(true));
            const client = new W3CWebSocket(websocketUrl);
            if (clientref.current) {
                client.onopen = clientref.current.onopen;
                client.onclose = clientref.current.onclose;
                client.onerror = clientref.current.onerror;
                client.onmessage = clientref.current.onmessage;
                clientref.current.close();
                clientref.current = null;
            }
            window.clientCandle = client;
            clientref.current = client;
            setLiveIndex({});
        }
        if (window.clientCandle?.readyState === 1 && clientref.current == null) {
            clientref.current = window.clientCandle;
            setReadyState(window.clientCandle?.readyState);
        }
    };

    const disconnectServer = () => {
        if (clientref.current) {
            clientref.current.close();
            clientref.current = null;
            setReadyState(3);
            setLiveIndex({});
        }
        if (window.clientCandle) {
            window.clientCandle?.close();
            window.clientCandle = null;
            setReadyState(3);
            setLiveIndex({});
        }
        dispatch(livepriceActions.updateLoading(false));
    };

    useEffect(() => {
        if (!Ticker || !Expiry) return;
        GetOptionChainLtp(Ticker, Expiry, dispatch);
        if (forDemo) {
            if (LTPpollingRef.current) {
                clearInterval(LTPpollingRef.current);
            }
            if (IS_IN_ACTIVATE_STRATEGY_HOUR()) {
                LTPpollingRef.current = setInterval(() => GetOptionChainLtp(Ticker, Expiry, dispatch), PAPER_PRICES_FREQUENCY);
            }
        } else {
            connectServer();
            if (LivePollingRef.current) {
                clearInterval(LivePollingRef.current);
            }
            LivePollingRef.current = setInterval(() => connectServer(), LIVE_RECONNECT_INTERVAL);
        }

        return () => {
            clearInterval(LivePollingRef.current);
            clearInterval(LTPpollingRef.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Ticker, Expiry, forDemo]);

    useEffect(() => {
        setReadyState(clientref?.current?.readyState);
        if (!clientref.current) return;

        clientref.current.onopen = () => {
            setReadyState(clientref?.current?.readyState);
        };
        clientref.current.onmessage = (message) => {
            parsePrices(JSON.parse(message.data), setLivePrices, true);
        };
        clientref.current.onclose = () => {
            setReadyState(clientref?.current?.readyState);
        };
        clientref.current.onerror = () => {
            setReadyState(clientref?.current?.readyState);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientref.current]);

    useEffect(() => {
        if (readyState !== 1) return;
        subscribeUnsubscribeIndex(liveIndex, { Ticker, Expiry }, setLiveIndex, handleMsgSend);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Ticker, Expiry, readyState, clientref.current]);
};
