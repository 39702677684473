import useBuildId from 'hooks/useBuildId';
import dynamic from 'next/dynamic';
import DefaultLayout from './DefaultLayout';
import HeaderLayout from './HeaderLayout';
import LandingLayout from './LandingLayout';
import SideBarLayout from './SideBarLayout';

const InternetMessage = dynamic(() => import('components/UI/Dialog/InternetMessage'), { ssr: false });

const layouts = {
    default: DefaultLayout,
    sidebar: SideBarLayout,
    header: HeaderLayout,
    landing: LandingLayout,
};

const LayoutWrapper = (props) => {
    let Layout = layouts[props.children.type.layout];

    useBuildId();

    return (
        <div className='relative text-primaryBlack-800'>
            <InternetMessage />
            {Layout != null ? <Layout {...props}>{props.children}</Layout> : <DefaultLayout {...props}>{props.children}</DefaultLayout>}
        </div>
    );
};

export default LayoutWrapper;
