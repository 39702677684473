import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'https://finx.choiceindia.com/auth/login',
                            url: 'https://finx.choiceindia.com/auth/login',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on "My Profile" (Top right corner, Under your photo)',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Copy your Client ID, and paste it below',
                        },
                    ],
                },
            },
        ],
    },
];

export const CHOICE_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Client Id',
        placeholder: 'Enter Client Id',
        defaultValue: '',
    },
];

export default instructions;
