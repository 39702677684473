import history from 'history';
import { InstructionType } from 'types/Broker';
import { JAINAM_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'https://spark.jainam.in/ ',
                            url: 'https://spark.jainam.in/',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on the my profile icon (Top right corner)',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'The first item in the dropdown is your client ID, copy it and paste it below',
                        },
                    ],
                },
            },
        ],
    },
];

export const JAINAM_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Client ID',
        placeHolder: 'Enter Client ID',
        defaultValue: '',
    },
];

export const JAINAM_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    const redirect_uri = `https://algotest.in/confirm_broker/${JAINAM_TAG}`;

    history.push(
        `https://spark.jainam.in/oauth2/auth?scope=orders holdings&state=${redirect_uri}&redirect_uri=${redirect_uri}&response_type=code&client_id=JAINAMDUCK-ALGOTEST`
    );
};

export default instructions;
