import { createSlice } from '@reduxjs/toolkit';
import { isLoggedIn } from 'utils/AuthUtils';

const getLoginDetails = () => {
    let loggedIn = false;

    if (typeof localStorage === 'object') {
        loggedIn = localStorage.getItem(isLoggedIn) === 'true';
    }

    return {
        isLoggedIn: loggedIn,
    };
};

export const authSlice = createSlice({
    name: 'Auth',
    initialState: getLoginDetails(),
    reducers: {
        Login(state, action) {
            localStorage.setItem(isLoggedIn, true);
            state.isLoggedIn = true;
        },
        Logout(state) {
            if (localStorage.getItem(isLoggedIn)) {
                localStorage.removeItem(isLoggedIn);
            }

            state.isLoggedIn = false;
        },
    },
});

export const authActions = authSlice.actions;
