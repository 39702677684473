import Button from 'components/UI/Button';
import { PasswordInput } from 'components/UI/Input';
import Popup from 'components/UI/Popup';
import { FINVASIA_TAG } from 'constants/ConfirmBroker';
import history from 'history';
import { useKeyDown } from 'hooks/useKeyDown';
import { useRef } from 'react';
import { BrokerLoginFieldProps } from 'types/Broker';
import { CustomInputType } from 'types/Global';
import { showAlert } from 'utils/AlertUtils';

export const FinvasiaLoginFields = ({ showLoginPopup, setShowLoginPopup, brokerId }: BrokerLoginFieldProps) => {
    const passref = useRef<CustomInputType>();
    const totpref = useRef<CustomInputType>();

    const submitHandler = () => {
        const password = passref.current!.getValue().trim();
        if (password === '') {
            showAlert.error('Password is required');
            return;
        }
        const totp = totpref.current!.getValue().trim();
        if (totp === '') {
            showAlert.error('TOTP is required');
            return;
        }
        history.push(`/confirm_broker/${FINVASIA_TAG}/${brokerId}?password=${encodeURIComponent(password)}&totp=${encodeURIComponent(totp)}`);
    };

    useKeyDown(13, submitHandler);

    return (
        <Popup
            show={showLoginPopup}
            setShow={setShowLoginPopup}
            title='Enter your Finvasia password'
            footer={
                <>
                    <Button onClick={() => setShowLoginPopup(false)}>Cancel</Button>
                    <Button primary onClick={submitHandler}>
                        Login with Finvasia
                    </Button>
                </>
            }>
            <div className=' mb-5 flex flex-col gap-5'>
                <PasswordInput
                    inputName={`finvasiaPasswordInput`}
                    defaultValue=''
                    placeholder='Enter Finvasia Password'
                    required={true}
                    labelText={`Your Finvasia Account Password:`}
                    ref={passref}
                />
                <PasswordInput
                    inputName={`finvasiaTotpInput`}
                    defaultValue=''
                    placeholder='Enter Authenticator TOTP'
                    required={true}
                    labelText={`Your Finvasia Authenticator TOTP:`}
                    ref={totpref}
                />
            </div>
        </Popup>
    );
};
