import { createSlice } from '@reduxjs/toolkit';

const dummyContact = { contact: 'test@example.com', verified: true };
const initialProfileState = {
    phoneNumber: null,
    backtestQuota: null,
    backtestCount: null,
    accessToken: null,
    backtestPaid: 0,
    backtestUnlimited: null,
    backtestUnlimitedPromo: null,
    credits: 0,
    credits_topup: null,
    plans: {},
    renewals: {},
    contacts: { email: dummyContact, telegram: dummyContact },
    shareAccess: false,
    billingDetails: null,
    signals6MPlan: null,
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialProfileState,
    reducers: {
        updateProfile(state, action) {
            state._id = action.payload._id;
            state.credits = action.payload.credits.balance;
            state.credits_topup = action.payload.credits.last_topup;
            state.phoneNumber = action.payload.phoneNumber;
            state.fullName = action.payload.fullName;
            state.role = action.payload.role;
            state.backtestCount = action.payload.backtestCount;
            state.backtestQuota = action.payload.backtestQuota;
            state.backtestPaid = action.payload.backtestPaid;
            state.backtestUnlimited = action.payload.backtestUnlimited;
            state.backtestUnlimitedPromo = action.payload.backtestUnlimitedPromo;
            state.referral_code = action.payload.referral_code;
            state.contacts = action.payload.contacts;
            state.shareAccess = action.payload.share_access;
            state.billingDetails = action.payload.billing_details;
        },
        UpdateUserName(state, action) {
            state.fullName = action.payload;
        },
        updatePlan(state, action) {
            state.plans = action.payload;
        },
        updateRenewal(state, action) {
            state.renewals = action.payload;
        },
        updateUnlimitedBacktest(state, action) {
            state.backtestUnlimited = action.payload;
        },
        updateLimitedBacktest(state, action) {
            state.backtestPaid = action.payload;
        },
        updateCredits(state, action) {
            state.credits = action.payload;
        },
        updateContacts(state, action) {
            state.contacts = action.payload.contacts;
        },
        deductCredits(state, action) {
            state.credits -= action.payload;
        },
        updateAccessToken(state, action) {
            state.accessToken = action.payload;
        },
        increaseBacktestCount(state, action) {
            if (state.backtestUnlimited) {
                return;
            }
            let num = action.payload;
            const decrease = Math.min(num, state.backtestPaid);
            state.backtestPaid -= decrease;
            num -= decrease;
            state.backtestCount += num;
        },
        updatePromoUsed(state, action) {
            state.backtestUnlimitedPromo = action.payload;
        },

        updateBillingDetails(state, action) {
            state.billingDetails = action.payload;
        },
        updateSignals6MPlan(state, action) {
            state.signals6MPlan = { expiration: action.payload.expiration, timestamp: action.payload.timestamp };
        },
    },
});

export const profileActions = profileSlice.actions;
