import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: ' You are required to send a request for API activation and shifting account ID to Symphony XTS. Please connect with your RM for the same. ',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Client and RM our also required to sign an Algo Agreement form.',
                        },
                    ],
                },
            },
        ],
    },
];
export default instructions;
