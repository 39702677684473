import { createSlice } from '@reduxjs/toolkit';
import { REV_INSTRUMENT_KIND_MAPPING } from 'constants/Execution';
import { autoPlayValues, timeIntervalValues } from 'constants/Simulator';
import { BankniftyTicker, IndiaVixTicker } from 'constants/Tickers';
import { FormKey } from 'utils/SimulatorUtils';

const initialSimulatorState = {
    timestamp: '',
    underlying: { Ticker: BankniftyTicker, LTP: 0, prevClose: 0 },
    indiavix: { dayData: {}, prevClose: 0 },
    futures: null,
    options: null,
    illiquidData: {},
    tradingDays: null,
    strikes: {},
    autoPlay: {
        enabled: false,
        interval: autoPlayValues[0].interval,
        title: autoPlayValues[0].title,
        frequency: timeIntervalValues[0].interval,
        frequencyTitle: timeIntervalValues[0].text,
    },
    checkPoints: { Overall: {}, LegWise: {}, currentHits: [] },
    activity: [],
};

export const simulatorSlice = createSlice({
    name: 'simulator',
    initialState: initialSimulatorState,
    reducers: {
        updateStore(state, action) {
            state.timestamp = action.payload.candle;
            state.underlying = {
                ...state.underlying,
                Ticker: action.payload.underlying,
                LTP: action.payload.cash?.close,
                Open: action.payload.cash?.open,
                High: action.payload.cash?.high,
                Low: action.payload.cash?.low,
            };
            state.futures = action.payload.futures;
            // state.options = action.payload.options;
            const modObject = Object.keys(action.payload.options).reduce((acc, key) => {
                const data = action.payload.options[key];
                const newData = data.strike.reduce((acc1, strike, i) => {
                    acc1[strike] = {
                        implied_volatility: data.implied_volatility[i],
                        theta: data.theta[i],
                        call_close: data.call_close[i],
                        call_open: data.call_open[i],
                        call_high: data.call_high[i],
                        call_low: data.call_low[i],
                        call_delta: data.call_delta[i],
                        call_gamma: data.call_gamma[i],
                        call_vega: data.call_vega[i],
                        put_close: data.put_close[i],
                        put_open: data.put_open[i],
                        put_high: data.put_high[i],
                        put_low: data.put_low[i],
                        put_delta: data.put_delta[i],
                        put_gamma: data.put_gamma[i],
                        put_vega: data.put_vega[i],
                    };
                    state.strikes?.[key]?.map((strike) => {
                        if (!acc1[strike]) {
                            acc1[strike] = {
                                implied_volatility: null,
                                theta: null,
                                call_close: null,
                                call_open: null,
                                call_high: null,
                                call_low: null,
                                call_delta: null,
                                call_gamma: null,
                                call_vega: null,
                                put_close: null,
                                put_open: null,
                                put_high: null,
                                put_low: null,
                                put_delta: null,
                                put_gamma: null,
                                put_vega: null,
                            };
                        }
                    });
                    return acc1;
                }, {});
                acc[key] = newData;
                return acc;
            }, {});
            state.options = modObject;
        },

        updateStrikes(state, action) {
            state.strikes = action.payload.strikes;
        },

        addMissingData(state, action) {
            const data = action.payload.symbols.reduce((acc, symbol) => {
                const key = FormKey({
                    Expiry: symbol.expiry,
                    InstrumentType: REV_INSTRUMENT_KIND_MAPPING[symbol.option_type],
                    Strike: symbol.strike,
                });
                acc[key] = symbol;
                return acc;
            }, {});
            state.illiquidData = { ...state.illiquidData, ...data };
        },

        updateTradingDays(state, action) {
            state.tradingDays = action.payload;
        },
        updateClose(state, action) {
            const { underlying, prices } = action.payload;
            state.underlying.prevClose = prices[underlying]?.close;
            state.indiavix.prevClose = prices[IndiaVixTicker]?.close;
        },
        updateVIXData(state, action) {
            state.indiavix = {
                ...state.indiavix,
                dayData: action.payload,
            };
        },
        updateAutoPlay(state, action) {
            state.autoPlay = { ...state.autoPlay, ...action.payload };
        },
        addLegWiseCheckPoint(state, action) {
            state.checkPoints.LegWise = { ...state.checkPoints.LegWise, ...action.payload };
        },
        addOverallCheckPoint(state, action) {
            state.checkPoints.Overall = action.payload;
        },
        addCurrentCheckPoint(state, action) {
            state.checkPoints.currentHits = [...state.checkPoints.currentHits, action.payload.checkPoint];
            state.activity = [...state.activity, action.payload.activity];
            state.autoPlay.enabled = false;
        },
        resetCurrentCheckPoint(state) {
            state.checkPoints.currentHits = [];
        },
        removeLegWiseCheckPoint(state, action) {
            const { key } = action.payload;
            const { [key]: value, ...rest } = state.checkPoints.LegWise;
            state.checkPoints.LegWise = rest;
        },
        resetCheckPoints(state) {
            state.checkPoints = { Overall: {}, LegWise: {}, currentHits: [] };
            state.activity = [];
        },
    },
});

export const simulatorActions = simulatorSlice.actions;
