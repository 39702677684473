import { Spinner } from 'assets';
import classNames from 'classnames';
import { FetchExpiredSymbolsLTP, FetchSymbolsLTP } from 'handlers/Charts/ChartsHandler';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { livepriceActions } from 'store/livepriceStore';
import { IS_IN_ACTIVATE_STRATEGY_HOUR } from 'utils/BrokerUtils';
import { numericFormat } from 'utils/Currency';
import { parsePrices, priceFeedURL, subscribeUnsubscribeTokens } from 'utils/LivePriceUtils';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { useOptionChainHook } from './useOptionChain';

const LIVE_RECONNECT_INTERVAL = 10000;

export const pollingFrequencyMap = {
    120: 60 * 1000,
    900: 60 * 1000,
};
const delay = 120;

const LiveHookContext = createContext();
export const useLivePriceContext = () => useContext(LiveHookContext);

export const LiveHookProvider = ({ children }) => {
    const value = { useLivePriceHook, useOptionChainHook };
    return <LiveHookContext.Provider value={value}>{children}</LiveHookContext.Provider>;
};

export const OverallPrice = ({ legs, isPaper, isExpired }) => {
    const useHook = useLivePriceContext().useLivePriceHook;
    useHook({ symbols: legs, isPaper, isExpired });

    return null;
};
export const OptionChainPrice = ({ Ticker, Expiry, forDemo }) => {
    const useHook = useLivePriceContext().useOptionChainHook;
    useHook({ Ticker, Expiry, forDemo });

    return null;
};

export const getPrice = (symbol, livePrices, isPaper = false) => {
    const data = livePrices[symbol];
    if (data) {
        if (isPaper) {
            return data[0].price / 100;
        } else {
            return data[1].price / 100;
        }
    }
    return 'NA';
};

export const gePrevDayPrice = (symbol, prevDayPrices, livePrices, isPaper = false) => {
    const prevData = prevDayPrices[symbol];
    const currPrice = getPrice(symbol, livePrices, isPaper);
    if (prevData && !isNaN(currPrice)) {
        return { prevPrice: prevData.price / 100, valueChange: currPrice - prevData.price / 100 };
    }
    return { prevPrice: 'NA', valueChange: 'NA' };
};

export const LivePriceContainer = ({ symbol, quantity, isPaper }) => {
    const livePrices = useSelector((store) => store.liveprice);
    const { loading, prices } = livePrices;
    const price = parseFloat(getPrice(symbol, prices, isPaper) * quantity).toFixed(2);
    return (
        <span className={classNames('whitespace-nowrap tabular-nums')}>
            {!loading ? (
                !isNaN(price) ? (
                    numericFormat(parseFloat(price))
                ) : null
            ) : (
                <Spinner className='h-5 w-5 animate-spin fill-primaryBlue-900 text-center text-primaryBlack-100' />
            )}
        </span>
    );
};

const useLivePriceHook = ({ symbols, isPaper, isExpired }) => {
    const dispatch = useDispatch();
    const { liveSymbols, paperSymbols, expiredSymbols } = useSelector((store) => store.liveprice);

    const [subscribedTokens, setSubscribedTokens] = useState([]);
    const [readyState, setReadyState] = useState(0);

    const clientref = useRef(window?.client);
    const LTPpollingRef = useRef();
    const LivePollingRef = useRef();

    const websocketUrl = priceFeedURL;

    const setLivePrices = (prices) => dispatch(livepriceActions.updatePrices({ prices, isPaper: false }));
    const setLiveSymbols = ({ symbols, isPaper, isExpired }) => dispatch(livepriceActions.subscribeTokens({ symbols, isPaper, isExpired }));

    const handleMsgSend = (msg, retry = 0) => {
        try {
            clientref.current?.send(JSON.stringify(msg));
        } catch (error) {
            if (retry < 5) {
                setTimeout(() => handleMsgSend(msg, retry + 1), 2000);
            }
        }
    };

    const connectServer = () => {
        const areActiveTradingHours = IS_IN_ACTIVATE_STRATEGY_HOUR();
        if (!areActiveTradingHours || liveSymbols.length === 0) {
            disconnectServer();
            return;
        }

        if (!window.client || window.client?.readyState === 3) {
            dispatch(livepriceActions.updateLoading(true));
            const client = new W3CWebSocket(websocketUrl);
            if (clientref.current) {
                client.onopen = clientref.current.onopen;
                client.onclose = clientref.current.onclose;
                client.onerror = clientref.current.onerror;
                client.onmessage = clientref.current.onmessage;
                clientref.current.close();
                clientref.current = null;
            }
            window.client = client;
            clientref.current = client;
            setSubscribedTokens([]);
        }
        if (window.client?.readyState === 1 && clientref.current == null) {
            clientref.current = window.client;
            setReadyState(window.client?.readyState);
        }
    };

    const disconnectServer = () => {
        if (clientref.current) {
            clientref.current.close();
            clientref.current = null;
            setReadyState(3);
            setSubscribedTokens([]);
        }
        if (window.client) {
            window.client?.close();
            window.client = null;
            setReadyState(3);
            setSubscribedTokens([]);
        }
        dispatch(livepriceActions.updateLoading(false));
    };

    useEffect(() => {
        if (isExpired) {
            const isSymbolMissing = symbols.some((item) => !expiredSymbols.includes(item));
            if (!isSymbolMissing) {
                return;
            }
            setLiveSymbols({ symbols, isPaper: false, isExpired: true });
        } else if (isPaper) {
            const isSymbolMissing = symbols.some((item) => !paperSymbols.includes(item));
            if (!isSymbolMissing) {
                return;
            }
            setLiveSymbols({ symbols, isPaper: true, isExpired: false });
        } else {
            const isSymbolMissing = symbols.some((item) => !liveSymbols.includes(item));
            if (!isSymbolMissing) {
                return;
            }
            setLiveSymbols({ symbols, isPaper: false, isExpired: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, isExpired]);

    useEffect(() => {
        if (paperSymbols.length == 0 || !isPaper) {
            clearInterval(LTPpollingRef.current);
            return;
        }

        FetchSymbolsLTP(paperSymbols, delay, dispatch);
        const currTime = new Date().getTime();
        const timeleft = delay * Math.ceil(currTime / delay) - currTime;
        setTimeout(() => {
            if (LTPpollingRef.current) {
                clearInterval(LTPpollingRef.current);
            }
            LTPpollingRef.current = setInterval(() => {
                const areActiveTradingHours = IS_IN_ACTIVATE_STRATEGY_HOUR();
                if (isPaper && areActiveTradingHours) {
                    FetchSymbolsLTP(paperSymbols, delay, dispatch);
                }
            }, pollingFrequencyMap[delay]);
        }, timeleft);
        return () => clearInterval(LTPpollingRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaper, paperSymbols.length]);

    useEffect(() => {
        if (liveSymbols.length == 0 || isPaper) {
            clearInterval(LivePollingRef.current);
            disconnectServer();
            return;
        }
        FetchSymbolsLTP(liveSymbols, 0, dispatch);

        connectServer();

        if (LivePollingRef.current) {
            clearInterval(LivePollingRef.current);
        }
        LivePollingRef.current = setInterval(() => connectServer(), LIVE_RECONNECT_INTERVAL);

        return () => clearInterval(LivePollingRef.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaper, liveSymbols.length]);

    useEffect(() => {
        FetchExpiredSymbolsLTP(expiredSymbols, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpired, expiredSymbols.length]);

    useEffect(() => {
        setReadyState(clientref?.current?.readyState);
        if (!clientref.current) return;

        clientref.current.onopen = () => {
            setReadyState(clientref?.current?.readyState);
        };
        clientref.current.onmessage = (message) => {
            parsePrices(JSON.parse(message.data), setLivePrices, true);
        };
        clientref.current.onclose = () => {
            setReadyState(clientref?.current?.readyState);
        };
        clientref.current.onerror = () => {
            setReadyState(clientref?.current?.readyState);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientref.current]);

    useEffect(() => {
        if (readyState !== 1) return;
        subscribeUnsubscribeTokens(subscribedTokens, liveSymbols, setSubscribedTokens, handleMsgSend);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveSymbols.length, subscribedTokens.length, readyState, clientref.current]);

    return { readyState };
};
