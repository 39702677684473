import Router from 'next/router';

const { assign } = Object;

const push = (to) => {
    if (typeof to === 'string') {
        Router.push(to);
    } else if (typeof to === 'object' && !Array.isArray(to)) {
        const { pathname, state } = to;

        const pushArgs = { pathname };

        if (state) {
            assign(pushArgs, { query: state });
        }
        Router.push(pushArgs);
    }
};

const history = { push, back: () => Router.back() };

export default history;
