import { createSlice } from '@reduxjs/toolkit';
import { isBrokerLoggedIn } from 'utils/BrokerUtils';

const initialBrokerState = {
    brokers: [],
    isAnyBrokerLoggedIn: false,
};

export const brokerSlice = createSlice({
    name: 'broker',
    initialState: initialBrokerState,
    reducers: {
        updateBrokers(state, action) {
            const allBrokers = action.payload.map((broker) => {
                if (broker.logged_until === null) {
                    broker.logged_until = new Date(0).toISOString();
                } else {
                    const date = new Date(broker.logged_until);
                    broker.logged_until = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
                }
                return broker;
            });
            const isAnyBrokerLoggedIn = allBrokers.filter((broker) => broker.configured && isBrokerLoggedIn(broker)).length > 0;

            state.brokers = allBrokers;
            state.isAnyBrokerLoggedIn = isAnyBrokerLoggedIn;
        },
        updateBrokerSettings(state, action) {
            state.brokers = state.brokers.map((broker) => {
                if (broker._id === action.payload._id) {
                    broker.settings = { ...broker.settings, ...action.payload.settings };
                }
                return broker;
            });
        },
        deleteBroker(state, action) {
            state.brokers = state.brokers.filter((broker) => broker._id !== action.payload);
        },
    },
});

export const brokerActions = brokerSlice.actions;
