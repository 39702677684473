import history from 'history';
import { ANGELONE_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY } from './FieldKeys';
import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'smartapi.angelbroking.com/',
                            url: 'https://smartapi.angelbroking.com/',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Create a new app, and fill out the following details',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'App Name - Algotest',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'https://algotest.in/confirm_broker/' + ANGELONE_TAG,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Enter the API key of the generated app below.',
                        },
                    ],
                },
            },
        ],
    },
];

export const ANGELONE_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'API Key',
        placeHolder: 'Enter API Key',
        defaultValue: '',
    },
];

export const ANGELONE_LOGIN_METHOD = (api_key: string, broker_id: string) => {
    const url = `https://smartapi.angelbroking.com/publisher-login?api_key=${api_key}`;
    history.push(url);
};

export default instructions;
