import Button from 'components/UI/Button';
import { PasswordInput } from 'components/UI/Input';
import Popup from 'components/UI/Popup';
import { FIRSTOCK_TAG } from 'constants/ConfirmBroker';
import history from 'history';
import { useRef } from 'react';
import { showAlert } from 'utils/AlertUtils';
import { useKeyDown } from 'hooks/useKeyDown';
import { BrokerLoginFieldProps } from 'types/Broker';
import { CustomInputType } from 'types/Global';

export const FirstockLoginFields = ({ showLoginPopup, setShowLoginPopup, brokerId }: BrokerLoginFieldProps) => {
    const passref = useRef<CustomInputType>();
    const totpref = useRef<CustomInputType>();

    const submitHandler = () => {
        const password = passref.current!.getValue().trim();
        if (password === '') {
            showAlert.error('Password is required');
            return;
        }
        const totp = totpref.current!.getValue().trim();
        if (totp === '') {
            showAlert.error('TOTP is required');
            return;
        }
        history.push(`/confirm_broker/${FIRSTOCK_TAG}/${brokerId}?password=${encodeURIComponent(password)}&totp=${encodeURIComponent(totp)}`);
    };

    useKeyDown(13, submitHandler);

    return (
        <Popup
            show={showLoginPopup}
            setShow={setShowLoginPopup}
            title='Enter your Firstock password'
            footer={
                <>
                    <Button onClick={() => setShowLoginPopup(false)}>Cancel</Button>
                    <Button primary onClick={submitHandler}>
                        Login with Firstock
                    </Button>
                </>
            }>
            <div className=' mb-5 flex flex-col gap-5'>
                <PasswordInput
                    inputName={`FirstockPasswordInput`}
                    defaultValue=''
                    placeholder='Enter Firstock Password'
                    required={true}
                    labelText={`Your Firstock Account Password:`}
                    ref={passref}
                />
                <PasswordInput
                    inputName={`FirstockTotpInput`}
                    defaultValue=''
                    placeholder='Enter Authenticator TOTP'
                    required={true}
                    labelText={`Your Firstock Authenticator TOTP:`}
                    ref={totpref}
                />
            </div>
        </Popup>
    );
};
