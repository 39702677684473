import { ClearingCharges, ExchangeCharges, IPFT, PerLot, PerOrder, SEBICharges, STT, StampDuty } from 'constants/Costs';
import { REV_INSTRUMENT_KIND_MAPPING } from 'constants/Execution';
import { BSE, BSETickers, IndexLotMapping, MidcapTicker, NSE, defaultTicker } from 'constants/Tickers';
import { CostConfigType, CostType, TradeCostType } from 'types/Cost';
import { ExecutionStrategyType, Trade } from 'types/Execution';
import { ChildTradeObjectType, ResultTradeObjectType } from 'types/Result';
import { sumArray } from './ArrayUtils';

const PERCrore = 0.0000001;

export const DefaultCost: CostType = { Total: 0, IPFT: 0, SEBICharges: 0, ClearingCharges: 0, ExchangeCharge: 0, GST: 0, StampDuty: 0, STT: 0 };

const ExchangeFinder = (Ticker: string) => {
    if (BSETickers.includes(Ticker)) {
        return BSE;
    }
    return NSE;
};

export const AddObjects = (obj1: CostType, obj2: CostType): CostType => {
    const newObj: CostType = { ...DefaultCost };
    Object.keys(obj1).forEach((key) => {
        const costKey = key as keyof CostType;
        newObj[costKey] = obj1[costKey] + obj2[costKey];
    });
    return newObj;
};

export const CalculateCost = (data: Trade | TradeCostType): CostType => {
    const { Ticker, TradedPrice, Quantity, InstrumentType, Position } = data;
    if (Position !== 1 && Position !== -1) {
        return DefaultCost;
    }
    const Exchange = ExchangeFinder(Ticker);
    const multiplier = TradedPrice * Quantity * PERCrore;
    let exchangeCharges = 0;
    if (Ticker !== MidcapTicker) {
        exchangeCharges = ExchangeCharges[Position][Exchange][InstrumentType];
    }
    const gstexchangecharges = exchangeCharges * 0.18;
    const gstsebicharges = SEBICharges * 0.18;
    const stampDuty = StampDuty[Position][InstrumentType];
    const stt = STT[Position][InstrumentType];
    const ipft = IPFT[InstrumentType];
    const Total = ipft + SEBICharges + gstsebicharges + ClearingCharges + exchangeCharges + gstexchangecharges + stampDuty + stt;

    return {
        Total: Total * multiplier,
        IPFT: ipft * multiplier,
        SEBICharges: SEBICharges * multiplier,
        ClearingCharges: ClearingCharges * multiplier,
        ExchangeCharge: exchangeCharges * multiplier,
        GST: (gstexchangecharges + gstsebicharges) * multiplier,
        StampDuty: stampDuty * multiplier,
        STT: stt * multiplier,
    };
};

export const LiveTradesCost = (strategies: ExecutionStrategyType[] | null, costConfig: CostConfigType, trades: Trade[] | null) => {
    let allTrades: Trade[] = [];

    if (strategies != null) {
        allTrades = strategies.map((execution) => execution.trades.map((trade) => ({ ...trade, Ticker: execution.ticker }))).flat(1);
    } else if (trades != null) {
        allTrades = trades;
    }

    if (allTrades.length === 0) {
        return { Taxes: DefaultCost, Brokerage: 0 };
    }
    const costsPerTrade = allTrades.map(CalculateCost);
    const lotsPerTrade = allTrades.map((trade) => {
        const Ticker = trade.Ticker;

        const lots = trade.Quantity / IndexLotMapping[Ticker];
        return lots;
    });

    const numLots = sumArray(lotsPerTrade);
    const numOrders = lotsPerTrade.length;
    let brokerage = 0;

    if (costConfig.brokerage.type === PerOrder) {
        brokerage = 1.18 * costConfig.brokerage.value * numOrders;
    } else if (costConfig.brokerage.type === PerLot) {
        brokerage = 1.18 * costConfig.brokerage.value * numLots;
    }

    const AccumulatedCost: CostType = costsPerTrade.reduce(
        (acc: CostType, curr: CostType) => {
            Object.keys(curr).forEach((key) => {
                const costKey = key as keyof CostType;
                acc[costKey] += curr[costKey];
            });
            return acc;
        },
        { ...DefaultCost }
    );

    return { Taxes: AccumulatedCost, Brokerage: brokerage };
};

export const LiveMTMCost = (executionList: ExecutionStrategyType[], costConfig: CostConfigType) => {
    const allTrades = executionList.map((execution) => execution.trades.map((trade) => ({ ...trade, Ticker: execution.ticker }))).flat(1);

    if (!costConfig || allTrades.length == 0) return { Taxes: 0, Brokerage: 0 };

    let taxes = 0;
    let brokerage = 0;

    if (costConfig.taxes.enabled) {
        const costsPerTrade = allTrades.map(CalculateCost);
        const AccumulatedCost = costsPerTrade.reduce(
            (acc: CostType, curr: CostType) => {
                Object.keys(curr).forEach((key) => {
                    const costKey = key as keyof CostType;
                    acc[costKey] += curr[costKey];
                });
                return acc;
            },
            { ...DefaultCost }
        );
        taxes += AccumulatedCost.Total;
    }
    if (costConfig.brokerage.enabled) {
        const lotsPerTrade = allTrades.map((trade) => {
            const Ticker = trade.Ticker;
            const lots = trade.Quantity / IndexLotMapping[Ticker];
            return lots;
        });

        if (costConfig.brokerage.type === PerOrder) {
            const numOrders = lotsPerTrade.length;
            brokerage += costConfig.brokerage.value * numOrders;
        } else if (costConfig.brokerage.type === PerLot) {
            const numLots = sumArray(lotsPerTrade);
            brokerage += costConfig.brokerage.value * numLots;
        }
        brokerage = 1.18 * brokerage;
    }
    return { Taxes: taxes, Brokerage: brokerage };
};

export const BacktestRowCost = (trade: ChildTradeObjectType[], Ticker: string = defaultTicker): CostType => {
    let tradeWiseData: CostType = { ...DefaultCost };
    if (Ticker === undefined) {
        return tradeWiseData;
    }

    const mappedTrades: TradeCostType[] = [];

    trade.forEach((childTrade) => {
        const InstrumentType = REV_INSTRUMENT_KIND_MAPPING[childTrade.OptionType];
        mappedTrades.push({
            Ticker: Ticker,
            TradedPrice: childTrade.EntryPrice,
            Quantity: childTrade.Qty,
            InstrumentType: Number(InstrumentType),
            Position: childTrade.Side === 'Buy' ? 1 : -1,
        });
        mappedTrades.push({
            Ticker: Ticker,
            TradedPrice: childTrade.ExitPrice,
            Quantity: childTrade.Qty,
            InstrumentType: Number(InstrumentType),
            Position: childTrade.Side === 'Buy' ? -1 : 1,
        });
    });
    const costsPerTrade = mappedTrades.map(CalculateCost);

    tradeWiseData = costsPerTrade.reduce((acc: CostType, curr: CostType) => AddObjects(acc, curr), { ...DefaultCost });

    return tradeWiseData;
};

export const FullBacktestCost = (trades: ResultTradeObjectType[], costConfig: CostConfigType) => {
    let taxes: CostType = { ...DefaultCost };
    let brokerage = 0;

    taxes = trades.reduce((acc: CostType, curr: ResultTradeObjectType) => {
        const costData = curr.Cost;
        if (costData !== undefined) {
            return AddObjects(acc, costData);
        }
        return acc;
    }, taxes);

    const { numLots, numOrders } = trades.reduce(
        (acc, curr) => {
            const lots = curr.ChildTrades.reduce((acc1, curr1) => {
                const lots = curr1.Qty / IndexLotMapping[curr1.Ticker];
                return acc1 + lots * 2;
            }, 0);
            acc.numLots += lots;
            acc.numOrders += curr.ChildTrades.length * 2;

            return acc;
        },
        { numLots: 0, numOrders: 0 }
    );
    if (costConfig.brokerage.type === PerOrder) {
        brokerage = 1.18 * costConfig.brokerage.value * numOrders;
    } else if (costConfig.brokerage.type === PerLot) {
        brokerage = 1.18 * costConfig.brokerage.value * numLots;
    }

    return { Taxes: taxes, Brokerage: brokerage };
};

export const getCostAdjustedValue = (value: ResultTradeObjectType, costConfig: CostConfigType) => {
    let newValue = value.TradeProfit;
    if (costConfig.taxes.enabled) {
        newValue -= value.Cost.Total;
    }
    if (costConfig.brokerage.enabled) {
        let brokerageValue = 0;
        if (costConfig.brokerage.type === PerOrder) {
            const numOrders = value.ChildTrades.length * 2;
            brokerageValue = costConfig.brokerage.value * numOrders;
        } else if (costConfig.brokerage.type === PerLot) {
            const lots = value.ChildTrades.reduce((acc, curr) => acc + curr.Qty / IndexLotMapping[curr.Ticker], 0) * 2;
            brokerageValue = costConfig.brokerage.value * lots;
        }
        newValue -= 1.18 * brokerageValue;
    }
    return newValue;
};
