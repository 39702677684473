import history from 'history';
import { API_KEY_IDENTITY } from './FieldKeys';
import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: ' If you do not have a Aliceblue account, you can ' },
                        {
                            type: 'link',
                            text: 'open one here',
                            url: 'https://aliceblueonline.com/open-account-fill-kyc-request-call-back?C=SHYD1331',
                        },
                        { type: 'text', text: ' with following benefits ' },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'BROKERAGE: ₹15/order',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'https://ant.aliceblueonline.com/',
                            url: 'https://ant.aliceblueonline.com/',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Enter the client id visible on the top right of the screen, below your name.',
                        },
                    ],
                },
            },
        ],
    },
];

export const ALICEBLUE_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Client ID',
        placeHolder: 'Enter Client ID',
        defaultValue: '',
    },
];

export const ALICEBLUE_LOGIN_METHOD = (_: string, broker_id: string) => {
    const url = 'https://ant.aliceblueonline.com/?appcode=rVQoXRbgouJChRk';
    history.push(url);
};

export default instructions;
