import { GetStocksData, GetSymbolsWithExpiries, getExpiredSymbolLTPData, getOptionChainData, getSymbolData, getSymbolLTPData } from 'API/ChartsAPI';
import { REV_INSTRUMENT_KIND_MAPPING } from 'constants/Execution';
import { Dispatch, SetStateAction } from 'react';
import { livepriceActions } from 'store/livepriceStore';
import { SymbolArrayDataType, SymbolTickDataType, SymbolType } from 'types/Chart';
import { showAlert } from 'utils/AlertUtils';
import { groupArray } from 'utils/ArrayUtils';
import { parseDate, separateSymbolsFromQuantity } from 'utils/ChartUtils';
import { convertDateToString, getDatesInRange } from 'utils/Date';
import { FetchTodayExpiries } from 'utils/SymbolUtils';

const HISTORICAL_SYMBOL_GROUP_SIZE = 8;
const HISTORICAL_DATE_GROUP_SIZE = 7;
const LTP_GROUP_SIZE = 30;
export const FetchSymbols = (setInstruments: Dispatch<SetStateAction<SymbolType[]>>) => {
    GetSymbolsWithExpiries().then((Response) => {
        if (Response.Status) {
            const Data: SymbolArrayDataType[] = Response.Data;
            const currTime = new Date().getTime();
            let ModData: SymbolType[];

            ModData = Data.map((item) => ({
                Symbol: item[0],
                Ticker: item[2] + (item[2] === item[0] ? ' (Index)' : ''),
                InstrumentType: item[4] ? (item[4] == 'XX' ? 'FUT' : item[4]) : '',
                Strike: item[6] == -1 ? 0 : item[6] / 100,
                Expiry: item[5] ? convertDateToString(new Date(item[5].slice(0, 10)), 1) : '',
            }));

            ModData = ModData.sort((a, b) => {
                if (a.Ticker === b.Ticker) {
                    const aTime = parseDate(a.Expiry);
                    const bTime = parseDate(b.Expiry);
                    if (aTime === bTime) {
                        return a.Strike - b.Strike;
                    }
                    return aTime - currTime > bTime - currTime ? 1 : -1;
                }
                return a.Ticker > b.Ticker ? -1 : 1;
            });

            setInstruments(ModData);
        } else {
            showAlert.error(Response.Msg);
        }
    });
};

export const FetchSymbolsLTP = async (symbols: string[], delay: number, dispatch: Dispatch<any>) => {
    const grouped = groupArray(symbols, LTP_GROUP_SIZE);
    const data = await Promise.all(
        grouped.map(async (group) =>
            getSymbolLTPData(group.join(','), delay).then((Response) => {
                if (Response?.Status) {
                    const priceObj = Response.Data.reduce(
                        (acc, curr) => ({
                            ...acc,
                            [curr[0]]: {
                                exchange_time: curr[1],
                                price: curr[2],
                                volume: curr[3],
                            },
                        }),
                        {}
                    );
                    return priceObj;
                }
                return {};
            })
        )
    );

    const dataObj = data.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    dispatch(livepriceActions.updatePrices({ prices: dataObj, isPaper: delay > 1 }));
};

export const FetchExpiredSymbolsLTP = async (symbols: string[], dispatch: Dispatch<any>) => {
    const grouped = groupArray(symbols, LTP_GROUP_SIZE);
    const data = await Promise.all(
        grouped.map(async (group) =>
            getExpiredSymbolLTPData(group.join(',')).then((Response) => {
                if (Response?.Status) {
                    const priceObj = Response.Data.reduce(
                        (acc, curr) => ({
                            ...acc,
                            [curr[0]]: {
                                // exchange_time: curr[1],
                                price: curr[1],
                                volume: curr[2],
                            },
                        }),
                        {}
                    );
                    return priceObj;
                }
                return {};
            })
        )
    );

    const dataObj = data.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    dispatch(livepriceActions.updatePrices({ prices: dataObj }));
};

export const SymbolsHistoricalData = async (
    symbolList: string[],
    start_date: string,
    end_date: string
): Promise<{ symbols: string[]; symbolData: Record<string, { symbol: string; data: SymbolTickDataType[] }[]> }> => {
    const [symbols, quantitiesWithOptionTypes] = separateSymbolsFromQuantity(symbolList);
    const grouped = groupArray(getDatesInRange(start_date, end_date), HISTORICAL_DATE_GROUP_SIZE);
    const groupedData = await Promise.all(
        grouped.map((dates) => getSymbolData(symbols, dates[0], dates[dates.length - 1], quantitiesWithOptionTypes))
    );

    const symbolData = Object.assign({}, ...groupedData.flat(1));

    return { symbols, symbolData };
};

export const SymbolsTodayData = async (symbols: string[], onSuccess: (props: Record<string, SymbolTickDataType[]>) => void) => {
    const today = convertDateToString(new Date());
    const grouped = groupArray(symbols, HISTORICAL_SYMBOL_GROUP_SIZE);

    const symbolData = await Promise.all(grouped.map((symbol) => getSymbolData(symbol, today, today, Array(HISTORICAL_SYMBOL_GROUP_SIZE).fill(1))));

    const finalData = symbolData
        .map((item) => item[today])
        .flat(1)
        .filter(Boolean)
        .reduce((acc, curr) => {
            if (curr) {
                return { ...acc, [curr?.symbol]: curr?.data };
            }
        }, {});

    onSuccess(finalData);
};

export const SymbolsLastTradedDate = async (symbolList: string[]) => {
    const [symbols] = separateSymbolsFromQuantity(symbolList);
    const Response = await GetSymbolsWithExpiries();
    if (Response.Status) {
        const filteredData = Response.Data.filter((item) => symbols.includes(item[0].toString()));
        const Data = filteredData.reduce((acc, curr) => ({ ...acc, [curr[0]]: new Date(curr[11]).getTime() }), {});
        return Data;
    }
};

export const FetchStocks = (onSuccess: (data: string[]) => void) => {
    GetStocksData().then((Response) => {
        if (Response.Status) {
            const Data = Response.Data.sort();
            onSuccess(Data);
        } else {
            showAlert.error(Response.Msg);
        }
    });
};

export const GetOptionChain = (dispatch: Dispatch<any>) => {
    GetSymbolsWithExpiries().then((Response) => {
        if (Response.Status) {
            const Data = Response.Data.map((item) => ({
                Symbol: item[0],
                token: item[1],
                Underlying: item[2],
                option_type: item[3],
                InstrumentType: item[4] ? REV_INSTRUMENT_KIND_MAPPING[item[4]] : 0,
                Expiry: parseInt(String(item[5])?.slice(0, 10).replaceAll('-', '')),
                Strike: item[6] / 100,
                LotSize: item[7],
                tick_size: item[8],
                max_trad_qty: item[9],
                is_tradable: item[10],
                added_at: item[11],
            }));
            dispatch(livepriceActions.updateSymbolData(Data));
        }
    });
};

export const GetOptionChainLtp = async (underlying: string, expiry: string, dispatch: Dispatch<any>) => {
    getOptionChainData(underlying, expiry).then((Response) => {
        if (Response?.Status) {
            const dataObj = Object.keys(Response.Data).reduce(
                (acc, curr) => ({ ...acc, [curr]: { exchange_time: null, volume: null, price: Response.Data[curr] } }),
                Response.Data
            );
            dispatch(livepriceActions.updatePrices({ prices: dataObj, isPaper: false }));
        } else {
            showAlert.error(Response?.Msg ?? 'Error in fetching option chain data');
        }
    });
};

export const FetchSymbolsLastDayPrice = async (symbols: string[], dispatch: Dispatch<any>) => {
    const grouped = groupArray(symbols, LTP_GROUP_SIZE);
    const data = await Promise.all(
        grouped.map(async (group) =>
            getSymbolLTPData(group.join(','), 3600 * 15).then((Response) => {
                if (Response?.Status) {
                    const priceObj = Response.Data.reduce(
                        (acc, curr) => ({
                            ...acc,
                            [curr[0]]: {
                                exchange_time: curr[1],
                                price: curr[2],
                                volume: curr[3],
                            },
                        }),
                        {}
                    );
                    return priceObj;
                }
                return {};
            })
        )
    );

    const dataObj = data.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    dispatch(livepriceActions.updatePrevDayPrices({ prices: dataObj }));
};

export const FetchAllSymbols = (dispatch: Dispatch<any>) => {
    GetSymbolsWithExpiries().then((Response) => {
        if (Response.Status) {
            const Data = Response.Data.map((item) => ({
                Symbol: item[0],
                token: item[1],
                Underlying: item[2],
                option_type: item[3],
                InstrumentType: item[4] ? REV_INSTRUMENT_KIND_MAPPING[item[4]] : 0,
                Expiry: parseInt(String(item[5])?.slice(0, 10).replaceAll('-', '')),
                Strike: item[6] / 100,
                LotSize: item[7],
                tick_size: item[8],
                max_trad_qty: item[9],
                is_tradable: item[10],
                added_at: item[11],
            }));
            FetchTodayExpiries(Data as any, dispatch);
        }
    });
};
