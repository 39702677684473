import { createSlice } from '@reduxjs/toolkit';

const initialPricingDetails = { creditProducts: [], unlimitedBacktestProducts: [], renewalSettings: {} };

export const pricingSlice = createSlice({
    name: 'Pricing',
    initialState: initialPricingDetails,
    reducers: {
        UpdatePricingDetails(state, action) {
            const pricingProducts = action.payload.products;
            let products = [];
            for (const pid in pricingProducts) {
                const product = pricingProducts[pid];
                product['id'] = pid;
                products.push(product);
            }
            products.sort((a, b) => a.price - b.price);
            state.creditProducts = products;
        },
        UpdateUnlimitedBacktestDetails(state, action) {
            const unlimitedBacktestProducts = action.payload.plans;
            let products = [];
            for (const pid in unlimitedBacktestProducts) {
                const product = unlimitedBacktestProducts[pid];
                product['id'] = pid;
                products.push(product);
            }
            products.sort((a, b) => b.days - a.days);
            state.unlimitedBacktestProducts = products;
        },
        UpdateRenewalSettings(state, action) {
            state.renewalSettings.discount = 1 - action.payload.plans_renew_discount_fraction;
            state.renewalSettings.discountWindow = action.payload.plans_renew_discount_window;
            state.renewalSettings.renewalWindow = action.payload.plans_renew_window;
        },
    },
});

export const pricingActions = pricingSlice.actions;
