import { createSlice } from '@reduxjs/toolkit';

const initialConfigState = {
    isHoliday: false,
    expiries: [],
};

export const configSlice = createSlice({
    name: 'execution',
    initialState: initialConfigState,
    reducers: {
        checkHoliday(state, action) {
            state.isHoliday = action.payload.is_holiday;
        },
        updateTodayExpiries(state, action) {
            state.expiries = action.payload;
        },
    },
});

export const configActions = configSlice.actions;
