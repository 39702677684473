import { format } from 'date-fns';
import { DateObjectType } from 'types/Date';

export const formatWithoutSeconds: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
};

const ISTOffset = 330;

const formatWithSeconds: Intl.DateTimeFormatOptions = { ...formatWithoutSeconds, second: '2-digit' };

export const presentableDate = (date: number | Date | string, showSeconds = false, locale = 'en-us'): string => {
    return new Date(new Date(date).getTime() + 5.5 * 60 * 60 * 1000).toLocaleDateString(
        locale,
        showSeconds ? formatWithSeconds : formatWithoutSeconds
    );
};

export const formDate = (date: Date, time: number) => {
    const hour = Math.trunc(time / 60);
    const minute = time % 60;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute);
};

export const ISOTimestamp = (date: number | Date | string = new Date()): string => {
    try {
        const baseDate = new Date(date);
        baseDate.setMinutes(baseDate.getMinutes() - baseDate.getTimezoneOffset());
        return new Date(baseDate.getTime()).toISOString();
    } catch (error) {
        return '';
    }
};
export const TradeTimeMapper = (TradedTime: string) => `${convertDateToString(new Date())}T${TradedTime.slice(0, 8)}`;

export const DatePickerOutputToDate = (input: DateObjectType) => {
    return new Date(input.Year, input.Month - 1, input.Day);
};

export const convertDateToString = (date: Date | number | string, formatType: number = 0): string => {
    date = new Date(date);
    let day: string = '' + date.getDate();
    let year: string = date.getFullYear().toString();

    if (day.length < 2) day = '0' + day;

    if (formatType == 0) {
        let month: string = '' + (date.getMonth() + 1);
        if (month.length < 2) month = '0' + month;
        return [year, month, day].join('-');
    } else if (formatType == 1) {
        year = year.toString().slice(2, 4);
        const month = format(date, 'MMMM').slice(0, 3);
        return `${day} ${month} ${year}`;
    } else if (formatType == 2) {
        year = year.toString().slice(2, 4);
        const month = format(date, 'MMMM').toUpperCase().slice(0, 3);
        return [day, month, year].join('-');
    }
    return '';
};

export const intToDate = (date: number): Date => {
    const year = Math.trunc(date / 10000);
    const month_day = date % 10000;
    const month = Math.trunc(month_day / 100);
    const day = month_day % 100;

    return new Date(year, month - 1, day, 15, 30);
};

export const dateToInt = (date: Date | number | string): number => {
    date = new Date(date);
    const year = date.getFullYear();
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return parseInt(`${year}${month}${day}`);
};

export const getDatesInRange = (startDate: Date | number | string, endDate: Date | number | string): string[] => {
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    const dates = [];
    while (start <= end) {
        dates.push(convertDateToString(start, 0));
        start.setDate(start.getDate() + 1);
    }

    return dates;
};

export const timeDifference = (time1: Date | number | string, time2: Date | number | string): number => {
    const diff = Math.abs(new Date(time1).getTime() - new Date(time2).getTime());
    return diff;
};

export const getFormattedTime = (time: Date | number = new Date()): string => {
    return format(new Date(time), 'yyyy-MM-dd HH:mm:ss');
};

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const ISTDate = (date: Date | number | string = new Date()) => {
    const currentTime = new Date(date);
    const currentOffset = currentTime.getTimezoneOffset();
    const ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    return ISTTime;
};
