export const Save = (props: any) => (
    <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' {...props}>
        <path
            d='M16.25 11.875V9.6875C16.25 8.1342 14.9908 6.875 13.4375 6.875H12.1875C11.6697 6.875 11.25 6.45527 11.25 5.9375V4.6875C11.25 3.1342 9.9908 1.875 8.4375 1.875H6.875M10 9.375V14.375M12.5 11.875H7.5M8.75 1.875H4.6875C4.16973 1.875 3.75 2.29473 3.75 2.8125V17.1875C3.75 17.7053 4.16973 18.125 4.6875 18.125H15.3125C15.8303 18.125 16.25 17.7053 16.25 17.1875V9.375C16.25 5.23286 12.8921 1.875 8.75 1.875Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const SaveNew = (props: any) => (
    <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' {...props}>
        <path
            d='M11.25 14.0625H14.0625M14.0625 14.0625H16.875M14.0625 14.0625V11.25M14.0625 14.0625V16.875M5 8.75H6.875C7.91053 8.75 8.75 7.91053 8.75 6.875V5C8.75 3.96447 7.91053 3.125 6.875 3.125H5C3.96447 3.125 3.125 3.96447 3.125 5V6.875C3.125 7.91053 3.96447 8.75 5 8.75ZM5 16.875H6.875C7.91053 16.875 8.75 16.0355 8.75 15V13.125C8.75 12.0895 7.91053 11.25 6.875 11.25H5C3.96447 11.25 3.125 12.0895 3.125 13.125V15C3.125 16.0355 3.96447 16.875 5 16.875ZM13.125 8.75H15C16.0355 8.75 16.875 7.91053 16.875 6.875V5C16.875 3.96447 16.0355 3.125 15 3.125H13.125C12.0895 3.125 11.25 3.96447 11.25 5V6.875C11.25 7.91053 12.0895 8.75 13.125 8.75Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const Update = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' className='h-6 w-6' {...props}>
        <path
            d='M14 4.66667V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H11.3333L14 4.66667ZM8 12C8.55556 12 9.02778 11.8056 9.41667 11.4167C9.80556 11.0278 10 10.5556 10 10C10 9.44444 9.80556 8.97222 9.41667 8.58333C9.02778 8.19444 8.55556 8 8 8C7.44444 8 6.97222 8.19444 6.58333 8.58333C6.19444 8.97222 6 9.44444 6 10C6 10.5556 6.19444 11.0278 6.58333 11.4167C6.97222 11.8056 7.44444 12 8 12ZM4 6.66667H10V4H4V6.66667Z'
            fill='currentColor'
        />
    </svg>
);
