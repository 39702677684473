import dynamic from 'next/dynamic';
import DefaultLayout from './DefaultLayout';
const SideBarFilled = dynamic(() => import('components/Navigation/VerticalBar/SideBarFilled'), { ssr: false });

const SideBarLayout = ({ children, ...props }) => (
    <SideBarFilled>
        <DefaultLayout {...props}>{children}</DefaultLayout>
    </SideBarFilled>
);

export default SideBarLayout;
