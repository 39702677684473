const ForwardTest = (props: any) => (
    <svg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M38.9583 6.875H13.75C8.68738 6.875 4.58331 10.9791 4.58331 16.0417V32.7546L11.7939 25.5439L12.4803 24.8576L13.2702 25.4218L20.4081 30.5203L27.4076 21.7708H21.7708C21.138 21.7708 20.625 21.2578 20.625 20.625C20.625 19.9922 21.138 19.4792 21.7708 19.4792H29.7917H30.9375V20.625V28.6458C30.9375 29.2787 30.4245 29.7917 29.7917 29.7917C29.1588 29.7917 28.6458 29.2787 28.6458 28.6458V23.8915L21.5197 32.7991L20.8419 33.6464L19.959 33.0157L12.728 27.8508L4.58331 35.9955V40.1042C4.58331 45.1668 8.68737 49.2708 13.75 49.2708H36.6667V43.5417C36.6667 40.3775 39.2317 37.8125 42.3958 37.8125H48.125L48.125 16.0417C48.125 10.9791 44.0209 6.875 38.9583 6.875ZM48.125 40.1042H42.3958C40.4974 40.1042 38.9583 41.6432 38.9583 43.5417L38.9583 49.2708L48.125 40.1042Z'
            fill='currentColor'
        />
    </svg>
);

const LiveTrade = (props: any) => (
    <svg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 6C8.68629 6 6 8.68629 6 12V43C6 46.3137 8.68629 49 12 49H43C46.3137 49 49 46.3137 49 43V12C49 8.68629 46.3137 6 43 6H12ZM14 16.5833V30.4556C14 31.0079 14.4477 31.4556 15 31.4556H15.9548V40.25C15.9548 40.6642 16.2906 41 16.7048 41C17.119 41 17.4548 40.6642 17.4548 40.25V31.4556H18.375C18.9273 31.4556 19.375 31.0079 19.375 30.4556V16.5833C19.375 16.031 18.9273 15.5833 18.375 15.5833L17.4548 15.5833V12.75C17.4548 12.3358 17.1191 12 16.7048 12C16.2906 12 15.9548 12.3358 15.9548 12.75V15.5833L15 15.5833C14.4477 15.5833 14 16.031 14 16.5833ZM25.375 27.0833V38C25.375 38.5523 25.8227 39 26.375 39H27.3298V42.25C27.3298 42.6642 27.6656 43 28.0798 43C28.494 43 28.8298 42.6642 28.8298 42.25V39H29.75C30.3023 39 30.75 38.5523 30.75 38V27.0833C30.75 26.531 30.3023 26.0833 29.75 26.0833L28.8298 26.0833V19.75C28.8298 19.3358 28.494 19 28.0798 19C27.6656 19 27.3298 19.3358 27.3298 19.75V26.0833L26.375 26.0833C25.8227 26.0833 25.375 26.531 25.375 27.0833ZM36.75 32.333V21.5833C36.75 21.031 37.1977 20.5833 37.75 20.5833L38.7048 20.5833V14.75C38.7048 14.3358 39.0406 14 39.4548 14C39.869 14 40.2048 14.3358 40.2048 14.75V20.5833L41.125 20.5833C41.6773 20.5833 42.125 21.031 42.125 21.5833V32.333C42.125 32.8853 41.6773 33.333 41.125 33.333H40.2048V37.25C40.2048 37.6642 39.869 38 39.4548 38C39.0406 38 38.7048 37.6642 38.7048 37.25V33.333H37.75C37.1977 33.333 36.75 32.8853 36.75 32.333Z'
            fill='currentColor'
        />
    </svg>
);

const ForwardTestOutlined = (props: any) => (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M16.5 21.5L21.5 16M16.5 21.5H6.5C4.29086 21.5 2.5 19.7091 2.5 17.5V15M16.5 21.5V18C16.5 16.8954 17.3954 16 18.5 16H21.5M13.5 9L9.5 14L6 11.5L2.5 15M13.5 9H10M13.5 9V12.5M2.5 15V7C2.5 4.79086 4.29086 3 6.5 3H17.5C19.7091 3 21.5 4.79086 21.5 7V16'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

const LiveTradeOutlined = (props: any) => (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.5 13.5255V6.36558C5.5 6.08052 5.73109 5.84944 6.01614 5.84945L6.50895 5.84946V4.3871C6.50895 4.17331 6.68227 4 6.89605 4C7.10984 4 7.28315 4.17331 7.28315 4.3871V5.84946L7.75808 5.84945C8.04313 5.84944 8.27422 6.08052 8.27422 6.36558V13.5255C8.27422 13.8105 8.04314 14.0416 7.75809 14.0416H7.28315V18.5806C7.28315 18.7944 7.10984 18.9677 6.89605 18.9677C6.68227 18.9677 6.50895 18.7944 6.50895 18.5806V14.0416H6.01613C5.73108 14.0416 5.5 13.8105 5.5 13.5255ZM11.371 17.4194V11.785C11.371 11.4999 11.6021 11.2689 11.8871 11.2689L12.38 11.2689V8.00007C12.38 7.78628 12.5533 7.61297 12.7671 7.61297C12.9808 7.61297 13.1542 7.78628 13.1542 8.00007V11.2689L13.6291 11.2689C13.9141 11.2689 14.1452 11.4999 14.1452 11.785V17.4194C14.1452 17.7045 13.9141 17.9355 13.6291 17.9355H13.1542V19.613C13.1542 19.8268 12.9808 20.0001 12.7671 20.0001C12.5533 20.0001 12.38 19.8268 12.38 19.613V17.9355H11.8871C11.6021 17.9355 11.371 17.7045 11.371 17.4194ZM17.242 8.94597V14.4942C17.242 14.7793 17.4731 15.0103 17.7581 15.0103H18.2509V17.032C18.2509 17.2458 18.4243 17.4191 18.6381 17.4191C18.8518 17.4191 19.0252 17.2458 19.0252 17.032V15.0103H19.5001C19.7851 15.0103 20.0162 14.7793 20.0162 14.4942V8.94597C20.0162 8.66092 19.7851 8.42984 19.5001 8.42984L19.0252 8.42985V5.41911C19.0252 5.20532 18.8518 5.03201 18.6381 5.03201C18.4243 5.03201 18.2509 5.20532 18.2509 5.41911V8.42986L17.7581 8.42984C17.4731 8.42984 17.242 8.66092 17.242 8.94597Z'
            fill='currentColor'
        />
    </svg>
);

export { ForwardTest, LiveTrade, ForwardTestOutlined, LiveTradeOutlined };
