import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { TimeObjectType } from 'types/Date';

type TimeInputProps = {
    defaultValue: TimeObjectType;
    onChange?: (e: TimeObjectType) => void;
    display?: boolean;
    disabled?: boolean;
    label?: string;
    type?: string;
    inputClass?: string;
    allowSeconds?: boolean;
};

export const TimeInput = React.forwardRef(
    ({ defaultValue, onChange, display, disabled, label, type, inputClass, allowSeconds }: TimeInputProps, ref) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const defaultHour = ('0' + defaultValue.Hour).slice(-2);
        const defaultMinute = ('0' + defaultValue.Minute).slice(-2);
        let defaultTime = defaultHour + ':' + defaultMinute;

        if (allowSeconds) {
            const defaultSecond = ('0' + defaultValue.Second).slice(-2);
            defaultTime = defaultTime + ':' + defaultSecond;
        }

        const getValue = () => {
            const time = inputRef.current!.value.split(':');
            let returnValue: TimeObjectType = {
                Hour: parseInt(time[0]),
                Minute: parseInt(time[1]),
            };
            if (allowSeconds) {
                returnValue = { ...returnValue, Second: parseInt(time[2] ?? 0) };
            }
            return returnValue;
        };

        useEffect(() => {
            inputRef.current!.value = defaultTime;
        }, [defaultTime]);

        useImperativeHandle(ref, () => {
            return {
                getValue: getValue,
            };
        });

        const onChangeHandler = () => {
            if (onChange) {
                onChange(getValue());
            }
        };

        return (
            <div
                className={classNames('flex items-center justify-between gap-5', {
                    hidden: !display,
                    'pointer-events-none opacity-80': disabled,
                    'flex-col !items-start': type === 'vertical',
                })}>
                {label && <label className='text-xsm font-normal leading-6'>{label}</label>}
                <input
                    title='Time'
                    onChange={onChangeHandler}
                    disabled={disabled}
                    className={classNames('w-26 rounded border border-primaryBlue-200 px-2 py-1 text-xs', inputClass)}
                    type='time'
                    ref={inputRef}
                    min='09:16'
                    max='15:30'
                    step={allowSeconds ? '1' : '60'}
                />
            </div>
        );
    }
);
