export const EDELWEISS_TAG = 'nuvamawealth';
export const ZERODHA_TAG = 'zerodha';
export const DHAN_TAG = 'dhan';
export const XTS_TAG = 'xts';
export const FINVASIA_TAG = 'finvasia';
export const KOTAK_TAG = 'kotak';
export const FYERS_TAG = 'fyers';
export const JAINAM_TAG = 'jainam';
export const FLATTRADE_TAG = 'flattrade';
export const ALICEBLUE_TAG = 'aliceblue';
export const MASTERTRUST_TAG = 'mastertrust';
export const ICICI_TAG = 'icici';
export const MOTILAL_TAG = 'motilal';
export const ANGELONE_TAG = 'angelone';
export const UPSTOX_TAG = 'upstox';
export const FIRSTOCK_TAG = 'firstock';
export const ZEBU_TAG = 'zebu';
export const PAYTM_TAG = 'paytm';
export const CHOICE_TAG = 'choice';

export const BrokerRedirectionURL = (bid: string, params: URLSearchParams, broker_id: string) => {
    if (bid === ZERODHA_TAG) {
        const token = params.get('request_token');
        if (!token) {
            return { Status: false, Msg: 'request_token not found' };
        }
        return { Status: true, Msg: `/broker_login/zerodha_confirm/${broker_id}?request_token=${encodeURIComponent(token)}` };
    } else if (bid === EDELWEISS_TAG) {
        const token = params.get('encrTkn');
        const userid = params.get('userid');
        const login_success = params.get('login_success');
        const brokerForNuvama = params.get('state') || broker_id;
        if (!token) {
            return { Status: false, Msg: 'Token Not Found' };
        }
        if (!userid) {
            return { Status: false, Msg: 'User ID Not Found' };
        }
        if (!login_success) {
            return { Status: false, Msg: 'Login Failed By Broker' };
        }
        return {
            Status: true,
            Msg: `/broker_login/edelweiss_confirm/${brokerForNuvama}?encrTkn=${encodeURIComponent(
                token
            )}&userid=${userid}&login_success=${login_success}`,
        };
    } else if (bid === XTS_TAG) {
        return { Status: true, Msg: `/broker_login/xts_confirm/${broker_id}` };
    } else if (bid === FINVASIA_TAG) {
        const pass = params.get('password');
        const totp = params.get('totp');
        if (!pass) {
            return { Status: false, Msg: 'password not found' };
        }
        if (!totp) {
            return { Status: false, Msg: 'totp not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/finvasia_confirm/${broker_id}?password=${encodeURIComponent(pass)}&totp=${encodeURIComponent(totp)}`,
        };
    } else if (bid === DHAN_TAG) {
        const token = params.get('tokenId');
        if (!token) {
            return { Status: false, Msg: 'tokenId not found' };
        }
        return { Status: true, Msg: `/broker_login/dhan_confirm/${broker_id}?tokenId=${encodeURIComponent(token)}` };
    } else if (bid === FYERS_TAG) {
        const authCode = params.get('auth_code');
        if (!authCode) {
            return { Status: false, Msg: 'auth_code not found' };
        }
        return { Status: true, Msg: `/broker_login/fyers_confirm/${broker_id}?auth_code=${encodeURIComponent(authCode)}` };
    } else if (bid === JAINAM_TAG) {
        const code = params.get('code');
        const redirect_uri = params.get('state');
        if (!code) {
            return { Status: false, Msg: 'code not found' };
        }
        if (!redirect_uri) {
            return { Status: false, Msg: 'state not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/jainam_confirm/${broker_id}?auth_code=${encodeURIComponent(code)}&redirect_uri=${encodeURIComponent(redirect_uri)}`,
        };
    } else if (bid === FLATTRADE_TAG) {
        const code = params.get('code');
        if (!code) {
            return { Status: false, Msg: 'code not found' };
        }
        return { Status: true, Msg: `/broker_login/flattrade_confirm/${broker_id}?auth_code=${encodeURIComponent(code)}` };
    } else if (bid === ALICEBLUE_TAG) {
        const authCode = params.get('authCode');
        const userId = params.get('userId');
        const appcode = params.get('appcode');
        if (!authCode) {
            return { Status: false, Msg: 'authCode not found' };
        }
        if (!userId) {
            return { Status: false, Msg: 'userId not found' };
        }
        if (!appcode) {
            return { Status: false, Msg: 'appcode not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/aliceblue_confirm/${broker_id}?authCode=${encodeURIComponent(authCode)}&userId=${encodeURIComponent(
                userId
            )}&appcode=${encodeURIComponent(appcode)}`,
        };
    } else if (bid === MASTERTRUST_TAG) {
        const code = params.get('code');
        const redirect_uri = params.get('state');
        if (!code) {
            return { Status: false, Msg: 'code not found' };
        }
        if (!redirect_uri) {
            return { Status: false, Msg: 'state not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/mastertrust_confirm/${broker_id}?auth_code=${encodeURIComponent(code)}&redirect_uri=${encodeURIComponent(
                redirect_uri
            )}`,
        };
    } else if (bid === ICICI_TAG) {
        const apisession = params.get('apisession');
        if (!apisession) {
            return { Status: false, Msg: 'apisession not found' };
        }
        return { Status: true, Msg: `/broker_login/icici_confirm/${broker_id}?apisession=${encodeURIComponent(apisession)}` };
    } else if (bid === MOTILAL_TAG) {
        const authtoken = params.get('authtoken');
        if (!authtoken) {
            return { Status: false, Msg: 'authtoken not found' };
        }
        return { Status: true, Msg: `/broker_login/motilal_confirm/${broker_id}?authtoken=${encodeURIComponent(authtoken)}` };
    } else if (bid === ANGELONE_TAG) {
        const refresh_token = params.get('refresh_token');
        const auth_token = params.get('auth_token');
        if (!refresh_token) {
            return { Status: false, Msg: 'refresh_token not found' };
        }
        if (!auth_token) {
            return { Status: false, Msg: 'auth_token not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/angelone_confirm/${broker_id}?auth_token=${encodeURIComponent(auth_token)}&refresh_token=${encodeURIComponent(
                refresh_token
            )}`,
        };
    } else if (bid === UPSTOX_TAG) {
        const code = params.get('code');
        const state = params.get('state');

        if (!code) {
            return { Status: false, Msg: 'code not found' };
        }
        if (!state) {
            return { Status: false, Msg: 'state not found' };
        }

        return { Status: true, Msg: `/broker_login/upstox_confirm/${broker_id}?code=${code}&state=${state}` };
    } else if (bid === FIRSTOCK_TAG) {
        const pass = params.get('password');
        const totp = params.get('totp');
        if (!pass) {
            return { Status: false, Msg: 'password not found' };
        }
        if (!totp) {
            return { Status: false, Msg: 'totp not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/firstock_confirm/${broker_id}?password=${encodeURIComponent(pass)}&totp=${encodeURIComponent(totp)}`,
        };
    } else if (bid === ZEBU_TAG) {
        const pass = params.get('password');
        const totp = params.get('totp');
        if (!pass) {
            return { Status: false, Msg: 'password not found' };
        }
        if (!totp) {
            return { Status: false, Msg: 'totp not found' };
        }
        return {
            Status: true,
            Msg: `/broker_login/zebu_confirm/${broker_id}?password=${encodeURIComponent(pass)}&totp=${encodeURIComponent(totp)}`,
        };
    } else if (bid === PAYTM_TAG) {
        const requestToken = params.get('requestToken');
        const state = params.get('state');

        if (!requestToken) {
            return { Status: false, Msg: 'requestToken not found' };
        }
        if (!state) {
            return { Status: false, Msg: 'state not found' };
        }

        return { Status: true, Msg: `/broker_login/paytm_confirm/${broker_id}?requestToken=${requestToken}&state=${state}` };
    }
    return { Status: false, Msg: 'Invalid broker' };
};
