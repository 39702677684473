export const Comparisons = ['>=', '>', '=', '<', '<='];

export const ComparisonTypeMap = {
    '>=': 'ComparisonType.GTE',
    '>': 'ComparisonType.GT',
    '=': 'ComparisonType.EQ',
    '<': 'ComparisonType.LT',
    '<=': 'ComparisonType.LTE',
};

export const defaultComparison = ComparisonTypeMap['>='];
export const lessThanComparison = ComparisonTypeMap['<'];
export const lessThanEqualComparison = ComparisonTypeMap['<='];
export const equalComparison = ComparisonTypeMap['='];
export const greaterThanComparison = ComparisonTypeMap['>'];
export const greaterThanEqualComparison = ComparisonTypeMap['>='];

export const ComparisonType = {
    GreaterThan: 'GreaterThan',
    LessThan: 'LessThan',
    EqualTo: 'EqualTo',
};
