import { SetStateAction } from 'react';
import { LivePriceMessageDataType, OHLCMessageDataType } from 'types/Store';

export const priceFeedURL = `wss://${process.env.NEXT_PUBLIC_PRICE_FEED_ENDPOINT}/live`;
export const candleFeedURL = `wss://${process.env.NEXT_PUBLIC_PRICE_FEED_ENDPOINT}/candles-live`;

export const ANALYSE_PRICES_DELAY = true;
export const CHART_PRICES_DELAY = false;

export const subscribeMessage = (tokens: string[]) => {
    return {
        header: 1001,
        tokens,
    };
};

export const unsubscribeMessage = (tokens: string[]) => {
    return {
        header: 1002,
        tokens,
    };
};

export const subscribeIndexMessage = (underlying: string, expiry: string) => {
    return {
        header: 1003,
        underlying,
        expiry,
    };
};

export const unsubscribeIndexMessage = (underlying: string, expiry: string) => {
    return {
        header: 1004,
        underlying,
        expiry,
    };
};

export const parsePrices = (
    message: { header: number; data: Record<string, LivePriceMessageDataType | OHLCMessageDataType> },
    setLivePrices: SetStateAction<any>,
    isAnyBrokerLoggedIn: boolean
) => {
    if (message?.header === 5000) {
        let livePrices = message.data;
        if (!isAnyBrokerLoggedIn) {
            livePrices = Object.keys(livePrices).reduce((acc, key) => {
                const data = livePrices[key] as OHLCMessageDataType;
                return {
                    ...acc,
                    [key]: { price: data.close_price, volume: data.volume, exchange_time: data.exchange_time },
                };
            }, {});
        }

        setLivePrices(livePrices);
    }
};

const differenceInTokens = (oldTokens: string[], newTokens: string[]) => {
    oldTokens = Array.from(new Set(oldTokens));
    newTokens = Array.from(new Set(newTokens));

    const addition = newTokens.filter((token) => !oldTokens.includes(token));
    const deletion = oldTokens.filter((token) => !newTokens.includes(token));
    return { addition, deletion, newTokens };
};

export const subscribeUnsubscribeTokens = (
    subscribedTokens: string[],
    newSymbols: string[],
    setSubscribedTokens: SetStateAction<any>,
    sendJsonMessage: (data: { header: number; tokens: string[] }) => void
) => {
    const { addition, deletion, newTokens } = differenceInTokens(subscribedTokens, newSymbols);
    if (addition.length > 0) {
        sendJsonMessage(subscribeMessage(addition));
    }
    if (deletion.length > 0) {
        sendJsonMessage(unsubscribeMessage(deletion));
    }
    if (addition.length > 0 || deletion.length > 0) {
        setSubscribedTokens(newTokens);
    }
};

export const subscribeUnsubscribeIndex = (
    subscribedChain: { Ticker: string; Expiry: string },
    newChainInfo: { Ticker: string; Expiry: string },
    setSubscribedChain: SetStateAction<any>,
    sendJsonMessage: (data: { header: number; underlying: string; expiry: string }) => void
) => {
    if (Object.keys(subscribedChain).length === 0) {
        sendJsonMessage(subscribeIndexMessage(newChainInfo.Ticker, newChainInfo.Expiry));
        setSubscribedChain(newChainInfo);
        return;
    } else if (subscribedChain.Ticker !== newChainInfo.Ticker || subscribedChain.Expiry !== newChainInfo.Expiry) {
        sendJsonMessage(unsubscribeIndexMessage(subscribedChain.Ticker, subscribedChain.Expiry));
        sendJsonMessage(subscribeIndexMessage(newChainInfo.Ticker, newChainInfo.Expiry));
        setSubscribedChain(newChainInfo);
    }
};
