import { API_KEY_IDENTITY, API_SECRET_IDENTITY, MARKET_DATA_API_KEY_IDENTITY, MARKET_DATA_API_SECRET_IDENTITY } from './FieldKeys';

export const XTS_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Interactive API Key',
        placeholder: 'Enter Interactive API Key',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'Interactive API Secret',
        placeholder: 'Enter Interactive API Secret',
        defaultValue: '',
    },
    {
        name: MARKET_DATA_API_KEY_IDENTITY,
        label: 'Market Data API Key',
        placeholder: 'Enter Market Data API Key',
        defaultValue: '',
    },
    {
        name: MARKET_DATA_API_SECRET_IDENTITY,
        label: 'Market Data API Secret',
        placeholder: 'Enter Market Data API Secret',
        defaultValue: '',
    },
];
