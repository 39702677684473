import { EdelweissBrokerConsentHandler } from 'handlers/Broker/BrokerAPIHandlers';
import history from 'history';
import { InstructionType } from 'types/Broker';
import { showAlert } from 'utils/AlertUtils';
import { API_KEY_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: ' If you do not have a Nuvama Wealth account, you can ' },
                        {
                            type: 'link',
                            text: 'open one here',
                            url: 'https://www.nuvamawealth.com/cas/microsite/Nuvama-Lite-Plan-Men/?utm_source=AFFILIATES&utm_medium=&utm_term=&utm_creative=&utm_campaign=ALGOTEST&utm_adgroup=&utm_placement=&utm_device=M&utm_content=Both',
                        },
                        { type: 'text', text: ' with following benefits ' },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'BROKERAGE: ₹10/order',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'No API Fees',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: { type: 'paragraph', items: [{ type: 'text', text: 'Can Buy Deep ITM and Far OTM Options in NRML' }] },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [{ type: 'text', text: 'Exchange charges as per actual discounted rate of NSE (Rs 3500/Cr approx)' }],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [{ type: 'text', text: 'Can pledge securities online for Margin' }],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [{ type: 'text', text: `Copy the Nuvama Client ID and Paste it below` }],
                },
            },
        ],
    },
];

export const EDELWEISS_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'Client ID',
        placeholder: 'Enter Client ID',
        defaultValue: '',
    },
];

export const EDELWEISS_LOGIN_METHOD = async (_: string, broker_id: string) => {
    showAlert.info('Please wait. Redirecting to Nuvama Wealth...');
    EdelweissBrokerConsentHandler((api_key: string, access_token: string) => {
        history.push(`https://nuvamawealth.com/login?ordsrc=${api_key}&ordsrctkn=${access_token}&state=${broker_id}`);
    }, broker_id);
};

export default instructions;
