import history from 'history';
import { InstructionType } from 'types/Broker';
import { PAYTM_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'developer.paytmmoney.com ',
                            url: 'https://developer.paytmmoney.com/',
                        },
                        {
                            type: 'text',
                            text: 'and click on SIGN IN',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Login with Paytm Money Registered Mobile Number/ Email.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click on Create New App. A single user can generate API keys and Secrets for up to five apps.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click proceed to create the app.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Fill the basic details about your new app like App name, Product type, Redirect URL, Description. For the Redirect URL, you can use ',
                        },
                        {
                            type: 'special_link',
                            text: 'https://algotest.in/confirm_broker/' + PAYTM_TAG,
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Click proceed to get the API Key and secret. You can access the API Key and API secret for the app in the dashboard section.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'If you have any further questions, refer to',
                        },
                        {
                            type: 'link',
                            text: ' the official documentation',
                            url: 'https://www.paytmmoney.com/blog/how-to-create-api-key-and-secret/',
                        },
                    ],
                },
            },
        ],
    },
];

export const PAYTM_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'API key',
        placeHolder: 'Enter API Key',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'API Secret',
        placeHolder: 'Enter Secret Key',
        defaultValue: '',
    },
];

export const PAYTM_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    const payload = new URLSearchParams({ apiKey: appKey, state: appKey }).toString();
    history.push(`https://login.paytmmoney.com/merchant-login?${payload}`);
};

export default instructions;
