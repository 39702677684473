import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ExposeGetValue } from 'utils/ExposeGetValue';
import { Tooltip } from '../Tooltip';

type ToggleProps = {
    onChange?: (e: boolean) => void;
    value?: boolean;
    disabled?: boolean;
    id?: string;
    label?: string;
    tooltipContent?: string | JSX.Element;
    smaller?: boolean;
    reverse?: boolean;
};

export const Toggle = React.forwardRef(({ onChange, value, disabled, id, label, tooltipContent, smaller, reverse }: ToggleProps, ref) => {
    const inputRef = useRef<any>(null);
    const getValue = () => {
        return inputRef.current!.checked;
    };

    const dummyChange = () => {};
    const handleChange = onChange || dummyChange;

    useEffect(() => {
        inputRef.current!.checked = value as boolean;
    }, [value]);

    ExposeGetValue(ref, getValue);
    return (
        <label
            className={classNames('relative inline-flex cursor-pointer items-center text-xsm font-normal leading-6 ', {
                'pointer-events-none cursor-not-allowed opacity-80': disabled,
            })}
            htmlFor={id}>
            {label && (
                <label
                    htmlFor={id}
                    className={classNames('mr-3 flex cursor-pointer items-center text-xsm font-normal leading-6', { 'order-last ml-3': reverse })}>
                    {label}
                    {tooltipContent && <Tooltip info={tooltipContent} />}
                </label>
            )}
            <Switch
                id={id}
                checked={value}
                disabled={disabled}
                onClick={(e) => e.stopPropagation()}
                onChange={handleChange}
                ref={inputRef}
                className={classNames(`relative inline-flex items-center rounded-full`, {
                    'bg-secondaryBlue-900': value,
                    'bg-primaryBlack-600': !value,
                    'h-3 w-6': smaller,
                    'h-5 w-9': !smaller,
                })}>
                <span
                    className={classNames(`inline-block  transform rounded-full bg-white transition`, {
                        'translate-x-1': !value,
                        'translate-x-3.5': value && smaller,
                        'translate-x-5': value && !smaller,
                        'h-3 w-3': !smaller,
                        'h-1.5 w-1.5': smaller,
                    })}
                />
            </Switch>
        </label>
    );
});
