import classNames from 'classnames';
import Button from 'components/UI/Button';
import { PasswordInput } from 'components/UI/Input';
import { TextInput } from 'components/UI/Input';
import Popup from 'components/UI/Popup';
import { BrokerConfirmHandler, KotakOTPHandler } from 'handlers/Broker/BrokerAPIHandlers';
import { useKeyDown } from 'hooks/useKeyDown';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrokerLoginFieldProps } from 'types/Broker';
import { CustomInputType } from 'types/Global';
import { showAlert } from 'utils/AlertUtils';

export const KotakLoginFields = ({ showLoginPopup, setShowLoginPopup, brokerId }: BrokerLoginFieldProps) => {
    const dispatch = useDispatch();

    const phoneNumberRef = useRef<CustomInputType>();
    const passwordRef = useRef<CustomInputType>();
    const otpRef = useRef<CustomInputType>();

    const [showotp, setShowOtp] = useState(false);
    const [loading, setLoading] = useState(false);

    const getOTP = () => {
        const phonenum = phoneNumberRef.current!.getValue().trim();
        if (phonenum === '' || phonenum.length !== 10) {
            showAlert.error('Valid Phone number is required');
            return;
        }
        const password = passwordRef.current!.getValue().trim();
        if (password === '') {
            showAlert.error('Password is required');
            return;
        }
        setLoading(true);
        KotakOTPHandler(
            phonenum,
            password,
            (status: boolean) => {
                setLoading(false);
                setShowOtp(status);
            },
            brokerId,
            dispatch
        );
    };

    const submitHandler = () => {
        const otp = otpRef.current!.getValue().trim();
        if (!otp) {
            showAlert.error('OTP is required');
            return;
        }
        setLoading(true);
        const urlPath = `/broker_login/kotak_confirm/${brokerId}?otp=${encodeURIComponent(otp)}`;
        BrokerConfirmHandler(
            urlPath,
            (Response: { Status?: boolean; msg: string }) => {
                if (Response.Status) {
                    setShowLoginPopup(false);
                    showAlert.success(Response.msg);
                    setTimeout(() => window.location.reload(), 3000);
                } else {
                    showAlert.error(Response.msg);
                }
                setLoading(false);
            },
            dispatch
        );
    };

    useKeyDown(13, showotp ? submitHandler : getOTP);

    return (
        <Popup show={showLoginPopup} setShow={setShowLoginPopup} title={showotp ? 'Enter the OTP' : 'Enter your Kotak credentials'}>
            <div className={classNames({ hidden: !showotp })}>
                <TextInput
                    ref={otpRef}
                    inputName={`kotakOTPInput`}
                    required={true}
                    labelText={`Enter OTP received:`}
                    placeholder='OTP received on your phone/email'
                    type='text'
                />
                <div className='mt-5 flex items-center justify-end gap-2'>
                    <Button disabled={loading} secondary onClick={() => setShowLoginPopup(false)}>
                        Cancel
                    </Button>
                    <Button bordered disabled={loading} secondary onClick={getOTP}>
                        {loading ? 'Sending OTP...' : 'Resend OTP'}
                    </Button>
                    <Button primary disabled={loading} onClick={submitHandler}>
                        Login with Kotak
                    </Button>
                </div>
            </div>
            <div className={classNames({ hidden: showotp })}>
                <div className=' mb-5 flex flex-col gap-5'>
                    <TextInput
                        ref={phoneNumberRef}
                        inputName='kotakPhoneInput'
                        required={true}
                        labelText='Enter Phone Number Registered with Kotak:'
                        placeholder='10 digit Indian Phone number'
                        type='tel'
                    />

                    <PasswordInput
                        ref={passwordRef}
                        inputName={`kotakPasswordInput`}
                        required={true}
                        labelText={`Enter your Kotak Password:`}
                        placeholder='Kotak account password'
                    />
                </div>
                <div className='flex items-center justify-end gap-2'>
                    <Button disabled={loading} secondary onClick={() => setShowLoginPopup(false)}>
                        Cancel
                    </Button>
                    <Button primary disabled={loading} onClick={getOTP}>
                        {loading ? 'Sending OTP...' : 'Get OTP'}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};
