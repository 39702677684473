import { CheckFilled, Close, ErrorIcon, ErrorIconRounded, Help } from 'assets';
import classNames from 'classnames';
import React, { Key } from 'react';
import { toast } from 'sonner';

type ToastLayoutProps = {
    type: 'error' | 'warning' | 'success' | 'info';
    toastInstance: Key;
    children: React.ReactNode;
};

const ToastLayout = ({ type, toastInstance, children }: ToastLayoutProps) => (
    <div
        className={classNames('relative flex w-96 cursor-pointer items-center gap-2  rounded-md px-2 py-4 text-sm  text-white', {
            'bg-error-900': type === 'error',
            'bg-confirmation-900': type === 'success',
            'bg-yellow-500': type === 'warning',
            'bg-secondaryBlue-900': type === 'info',
        })}
        onClick={() => toast.dismiss(Number(toastInstance))}>
        {children}
        <div
            className='absolute -right-1 -top-2 cursor-pointer rounded-full bg-white p-1 text-primaryBlack-600'
            onClick={() => toast.dismiss(Number(toastInstance))}>
            <Close className='h-3 w-3' />{' '}
        </div>
    </div>
);

export const showAlert = {
    error: (message: string) =>
        message &&
        toast.custom((t: Key) => (
            <ToastLayout type='error' toastInstance={t}>
                <ErrorIconRounded className='h-5 w-5' />
                <p className='font-medium leading-6'>{message}</p>
            </ToastLayout>
        )),
    warning: (message: string) =>
        message &&
        toast.custom((t: Key) => (
            <ToastLayout type='warning' toastInstance={t}>
                <ErrorIcon className='h-5 w-5' />
                <p>{message}</p>
            </ToastLayout>
        )),
    success: (message: string) =>
        message &&
        toast.custom((t: Key) => (
            <ToastLayout type='success' toastInstance={t}>
                <CheckFilled className='h-5 w-5' />
                <p>{message}</p>
            </ToastLayout>
        )),
    info: (message: string) =>
        message &&
        toast.custom((t: Key) => (
            <ToastLayout type='info' toastInstance={t}>
                <Help className='h-5 w-5' />
                <p>{message}</p>
            </ToastLayout>
        )),
};

export const playSound = async () => {
    const audio = new Audio('audio/alert.wav');
    await audio.play();
};
