import classNames from 'classnames';
import React, { useImperativeHandle, useRef, useState } from 'react';

type PasswordInputProps = {
    labelText?: string;
    inputName: string;
    placeholder?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    disabled?: boolean;
    defaultValue?: string;
    autoComplete?: string;
    value?: string;
    hasError?: boolean;
    errorText?: string;
    className?: string;
    showPass?: boolean;
    dark?: boolean;
};

export const PasswordInput = React.forwardRef(
    (
        {
            labelText,
            inputName,
            placeholder,
            onBlur,
            onChange,
            required,
            disabled,
            defaultValue,
            value,
            hasError,
            errorText,
            className,
            showPass = false,
            dark = false,
        }: PasswordInputProps,
        ref
    ) => {
        const [showPassword, setShowPassword] = useState(showPass);
        const inputRef = useRef<HTMLInputElement>(null);

        const getValue = () => {
            return inputRef.current!.value;
        };

        const setValue = (val: string) => {
            inputRef.current!.value = val;
        };

        useImperativeHandle(ref, () => {
            return { getValue: getValue, setValue: setValue };
        });

        return (
            <div className='w-full'>
                {labelText && (
                    <label htmlFor={inputName} className='text-xsm font-normal'>
                        {labelText}
                    </label>
                )}
                <div className='relative'>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className={classNames(
                            'w-full rounded border border-primaryBlue-200 bg-white px-2 py-1 text-xs text-primaryBlack-700',
                            'placeholder:font-medium placeholder:text-primaryBlack-600 ',
                            'focus:ring-0',
                            className,
                            {
                                '!border-primaryBlack-525 !bg-transparent !text-white placeholder:!text-primaryBlack-525 focus:!border-white focus:!ring-white focus:placeholder:text-white':
                                    dark,
                            }
                        )}
                        placeholder={placeholder}
                        name={inputName}
                        onBlur={onBlur}
                        onChange={onChange}
                        required={required}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        value={value}
                        ref={inputRef}
                        data-testid={`${inputName}-password-input`}
                    />
                    <svg
                        onClick={() => setShowPassword(!showPassword)}
                        className='absolute right-0 top-2 mr-2 cursor-pointer'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M5.97834 3.66699C2.63844 4.5684 0.201254 7.3612 0.0801106 7.50189C-0.0267035 7.62694 -0.0267035 7.81451 0.0801106 7.93956C0.224701 8.1076 3.661 12.0558 8 12.0558C12.339 12.0558 15.7753 8.1076 15.9199 7.93956C16.0267 7.81451 16.0267 7.62694 15.9199 7.50189C15.7987 7.3612 13.3616 4.5697 10.0217 3.66699C11.0103 4.32351 11.6682 5.44376 11.6682 6.72032C11.6669 8.74588 10.0256 10.3885 8 10.3885C5.97444 10.3885 4.33314 8.74588 4.33184 6.72032C4.33184 5.44376 4.98966 4.32351 5.97834 3.66699ZM8 5.05297C7.07905 5.05297 6.33265 5.79937 6.33265 6.72032C6.33265 7.64127 7.07905 8.38766 8 8.38766C8.92095 8.38766 9.66735 7.64127 9.66735 6.72032C9.66735 5.79937 8.92095 5.05297 8 5.05297Z'
                            fill={showPassword ? '#3f3f3f' : '#C7C7C7'}
                        />
                    </svg>
                </div>

                {hasError && <p>{errorText}</p>}
            </div>
        );
    }
);
