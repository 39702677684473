import { ArrowLeft, ChevronArrowRight } from 'assets';
import classNames from 'classnames';
import history from 'history';
import mixpanel from 'mixpanel-browser';
import Link from 'next/link';
import { SpecialTextTooltip } from '../Tooltip/SpecialTextTooltip';

type BackButtonProps = {
    links: { name: string; link?: string; onClick?: () => void }[];
};

function BackButton({ links }: BackButtonProps) {
    links = links.filter(Boolean);
    const length = links.length;

    const handleBack = () => {
        if (links[length - 2].link) {
            mixpanel.track('back_button_clicked', { next_screen_name: links[length - 2]?.link, previous_screen_name: links[length - 1]?.name });
            history.push(links[length - 2].link);
        } else if (links[length - 2].onClick) {
            links[length - 2].onClick!();
        }
    };

    return (
        <div className='flex flex-col'>
            <span className={classNames('flex gap-3 text-xl font-extrabold leading-none ', 'md:text-2xl md:leading-9')}>
                <button onClick={handleBack}>
                    <ArrowLeft />
                </button>
                {links[length - 1].name}
            </span>
            <div className='flex items-center text-sm '>
                {links?.map((item, index) => (
                    <div key={index} className='flex items-center'>
                        {item.link ? (
                            <Link
                                href={item.link}
                                className={classNames(' text-xs font-normal leading-6 text-zinc-400', ' md:text-sm  md:leading-9')}>
                                <SpecialTextTooltip name={item.name} length={20} />
                            </Link>
                        ) : item.onClick ? (
                            <span
                                onClick={item.onClick}
                                className={classNames('cursor-pointer text-xs font-normal leading-6 text-zinc-400', ' md:text-sm md:leading-9')}>
                                <SpecialTextTooltip name={item.name} length={20} />
                            </span>
                        ) : (
                            <span
                                key={index}
                                className={classNames(' text-xs font-medium capitalize leading-6 underline', 'md:text-sm md:leading-9')}>
                                <SpecialTextTooltip name={item.name} length={20} />
                            </span>
                        )}
                        {index < length - 1 && <ChevronArrowRight className='h-5 text-primaryBlack-600' />}
                    </div>
                ))}
            </div>
        </div>
    );
}

export { BackButton };
