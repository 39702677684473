export const Graph = (props: any) => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' {...props}>
        <path
            d='M14.9625 0V1.6H17.0375L13.3625 5.275L12.325 4.2375L11.7625 3.6625L11.2 4.2375L6.9625 8.475L5.925 7.4375L5.3625 6.8625L4.8 7.4375L0 12.2375L1.125 13.3625L5.3625 9.125L6.4 10.1625L6.9625 10.7375L7.525 10.1625L11.7625 5.925L12.8 6.9625L13.3625 7.5375L13.925 6.9625L18.1625 2.725V4.8H19.7625V0H14.9625ZM17.3625 6V20H18.9625V6H17.3625ZM11.7625 8V20H13.3625V8H11.7625ZM14.5625 8V20H16.1625V8H14.5625ZM8.9625 9.6V20H10.5625V9.6H8.9625ZM6.1625 12V20H7.7625V12H6.1625ZM3.3625 12.4V20H4.9625V12.4H3.3625ZM0.5625 15.2V20H2.1625V15.2H0.5625Z'
            fill='currentColor'
        />
    </svg>
);

export const Chart = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16' className='h-6 w-6' {...props}>
        <path
            fillRule='evenodd'
            d='M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z'
        />
    </svg>
);

export const BarChart = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='h-6 w-6' {...props}>
        <path d='M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z' />
    </svg>
);

export const BarChartSquare = (props: any) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='h-6 w-6' {...props}>
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z'
        />
    </svg>
);
