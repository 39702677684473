import { axiosInstance, handlePostRequest } from 'utils/API';
import { getUTMValues } from 'utils/AuthUtils';

export const UserLogin = async (phoneNumber: string, password: string) => {
    const loginEndpoint = '/login';
    const loginObject = {
        phoneNumber: phoneNumber,
        password: password,
    };
    try {
        const response = await axiosInstance.post(loginEndpoint, loginObject);
        return { AuthStatus: true, UserName: response.data.username, Role: response.data.role };
    } catch (err: any) {
        return { AuthStatus: false, Msg: err.response?.data?.msg };
    }
};

export const UserLogout = async () => {
    const logoutEndpoint = '/logout';
    try {
        await axiosInstance.post(logoutEndpoint);
        return true;
    } catch (err) {
        return false;
    }
};

export const UserRegister = (fullName: string, phoneNumber: string, password: string, referee: string) => {
    const registerEndpoint = '/register';
    const registerObject = {
        phoneNumber,
        password,
        fullName,
        referee: referee?.length > 0 ? referee : null,
    };
    return handlePostRequest(registerEndpoint, registerObject);
};

export const UserRegisterConfirm = (phoneNumber: string, otp: number, showOnboarding: boolean) => {
    const registerConfirmEndpoint = '/register_confirm';
    const registerConfirmObject = {
        phoneNumber: phoneNumber,
        otp: otp,
        user_inbound: { ...getUTMValues(), showOnboarding },
    };
    return handlePostRequest(registerConfirmEndpoint, registerConfirmObject);
};

export const UserReset = (phoneNumber: string) => {
    const resetEndpoint = '/reset_password';
    const resetObject = { phoneNumber: phoneNumber };
    return handlePostRequest(resetEndpoint, resetObject);
};

export const UserResetConfirm = (phoneNumber: string, otp: number, password: string) => {
    const resetConfirmEndpoint = '/reset_password_confirm';
    const resetConfirmObject = {
        phoneNumber: phoneNumber,
        otp: otp,
        password: password,
    };
    return handlePostRequest(resetConfirmEndpoint, resetConfirmObject);
};
