export const six_hours_in_milliseconds = 6 * 60 * 60 * 1000;
export const USERNAME = 'username';
export const ROLE = 'role';
export const ADMINROLE = 'admin';
export const USERROLE = 'user';
export const PARTNERROLE = 'partner';
export const PHONENUMBER = 'phoneNumber';
export const isLoggedIn = 'isLoggedIn';

export const getUTMValues = () => {
    const utmObject: Record<string, any> = { referrer: document.referrer };

    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
    const utmQuery = new URLSearchParams(window.location.search.substring(1));

    utmParams.forEach((param) => {
        if (utmQuery.get(param)) {
            utmObject[param] = utmQuery.get(param);
        }
    });

    return utmObject;
};
