import { ICICI_TAG } from 'constants/ConfirmBroker';
import history from 'history';
import { InstructionType } from 'types/Broker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Go to ',
                        },
                        {
                            type: 'link',
                            text: 'api.icicidirect.com/apiuser/home.',
                            url: 'https://api.icicidirect.com/apiuser/home',
                        },
                        {
                            type: 'text',
                            text: ' login, and register a new app. ',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'https://algotest.in/confirm_broker/' + ICICI_TAG + '_post',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Post App Creation, Copy the App ID and the Secret ID of the generated App and paste them here`,
                        },
                    ],
                },
            },
        ],
    },
];

export const ICICI_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'App ID',
        placeHolder: 'Enter App ID',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'Secret ID',
        placeHolder: 'Enter Secret ID',
        defaultValue: '',
    },
];

export const ICICI_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    history.push(`https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(appKey)}`);
};

export default instructions;
