import { MarginDataType, MarginDataTypeWithStrike, MarginPayloadType, MarginResultType, MarginStrikeResponseType } from 'types/Margin';
import { axiosInstance } from 'utils/API';

const marginBaseURL = '/marginCalcAPI';
const timelimit = 30 * 60 * 1000; //30 minutes

export const getFromSession = (key: string) => {
    const data = JSON.parse(sessionStorage.getItem(key)!);
    if (!data) {
        return null;
    }
    const current_time = new Date().getTime();
    if (current_time - data.timestamp < timelimit) {
        return data.value;
    }
    sessionStorage.removeItem(key);
    return null;
};

export const setToSession = (key: string, value: any) => {
    const current_time = new Date().getTime();
    const data = JSON.stringify({ timestamp: current_time, value: value });
    try {
        sessionStorage.setItem(key, data);
    } catch (err) {
        console.log(err);
        sessionStorage.clear();
        window.sessionStorage.clear();
    }
};

export const GetSymbolsWithStrikes = async () => {
    const key = 'margin_symbols';
    const symbolEndpoint = marginBaseURL + '/symbols_with_strikes';
    const prevStored = getFromSession(key);
    if (prevStored) {
        return prevStored as { Status: true; Data: MarginDataTypeWithStrike[] };
    }
    try {
        const response = await axiosInstance.get(symbolEndpoint);
        const value = { Status: true, Data: response.data as MarginDataTypeWithStrike[] } as const;
        setToSession(key, value);
        return value;
    } catch (err) {
        return { Status: false, Msg: 'Unable to fetch. Please try again later' } as const;
    }
};

export const GetSymbols = async () => {
    const key = 'symbols';
    const prevStored = getFromSession(key);
    if (prevStored) {
        return prevStored as { Status: true; Data: MarginDataType };
    }
    const symbolEndpoint = marginBaseURL + '/symbols_with_expiries';
    try {
        const response = await axiosInstance.get(symbolEndpoint);
        const value = { Status: true, Data: response.data as MarginDataType } as const;
        setToSession(key, value);
        return value;
    } catch (err) {
        return { Status: false, Msg: 'Unable to fetch. Please try again later' } as const;
    }
};

export const GetStrikes = async (ticker: string, expiry: string) => {
    const key = ticker + '-' + expiry;
    const prevStored = getFromSession(key);
    if (prevStored) {
        return prevStored as { Status: true; Data: MarginStrikeResponseType };
    }
    const symbolEndpoint = `${marginBaseURL}/strikes?ticker=${encodeURIComponent(ticker)}&expiry=${expiry}`;
    try {
        const response = await axiosInstance.get(symbolEndpoint);
        const value = { Status: true, Data: response.data as MarginStrikeResponseType } as const;
        setToSession(key, value);
        return value;
    } catch (err: any) {
        return { Status: false, Msg: err?.response?.data || 'Not found' } as const;
    }
};

export const GetResults = async (data: MarginPayloadType) => {
    const symbolEndpoint = `${marginBaseURL}/calculate_margin`;
    try {
        const response = await axiosInstance.post(symbolEndpoint, data);
        return { Status: true, Data: response.data as MarginResultType } as const;
    } catch (err: any) {
        return { Status: false, Msg: err?.response?.data?.error?.msg || 'Something went wrong' } as const;
    }
};
