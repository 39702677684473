import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: ' If you do not have a 5Paisa account, you can ' },
                        {
                            type: 'link',
                            text: 'open one here for free',
                            url: 'https://5paisa.page.link/pFrBGaxBvHJASyGW8',
                        },
                        { type: 'text', text: '. Use this promo code to get ₹2000 worth vouchers from top brands' },
                        {
                            type: 'special_link',
                            text: 'RAGH4073',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: ' If you already have API key & Secret pair, for both the Interactive as well as Market Data, then you can skip these steps, and directly copy/paste those in the respective fields below.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: 'API Activation: Send an email to ' },
                        { type: 'link', text: 'megha.ajila@5paisa.com', url: 'mailto:megha.ajila@5paisa.com' },
                        { type: 'text', text: ', & ' },
                        { type: 'link', text: 'kalyani.vashishth@5paisa.com', url: 'mailto:kalyani.vashishth@5paisa.com' },
                        { type: 'text', text: ', CC: ' },
                        { type: 'link', text: 'rakesh.sasmal@5paisa.com,', url: 'mailto:rakesh.sasmal@5paisa.com' },
                        { type: 'link', text: ' deepak.tripathi@iifl.com', url: 'mailto:deepak.tripathi@iifl.com' },
                        { type: 'text', text: ' for activating XTS API and terminal for your account with the following information:' },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Keep the mail subject as',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'XTS API Creation for AlgoTest',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Keep the Mail body as',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'Please get my XTS API created subjected to below details:',
                                        },
                                    ],
                                },
                            },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Client Name' }] } },
                            { type: 'list_item', item: { type: 'paragraph', items: [{ type: 'text', text: 'Client Code' }] } },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Once you receive the email with API Key details and your login ID and password. Copy the API key and secret pair, and paste them in the respective fields below.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Make sure you do not use the same API key and secret pair to fire trades from anywhere else, otherwise your executions would run in unexpected errors.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Once your XTS account has been made and XTS API been created, you will no longer be able to input any orders from the 5Paisa trading terminal/App. You can still view your live MTM & position detail from the 5Paisa trading terminal/App.',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: "Don't worry, you can still take manual trades from your 5Paisa XTS account, please follow this link to ",
                        },
                        { type: 'link', text: 'login to your XTS account(For manual XTS web trading)', url: 'https://xtsmum.5paisa.com' },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'For any issue in any of the previous step, please contact Mr. Deepak Tripathi: ',
                        },
                        { type: 'link', text: '+91-9699054837', url: 'tel:+91-9699054837' },
                    ],
                },
            },
        ],
    },
];
export default instructions;
