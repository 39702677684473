import history from 'history';
import { InstructionType } from 'types/Broker';
import { FYERS_TAG } from '../ConfirmBroker';
import { API_KEY_IDENTITY, API_SECRET_IDENTITY } from './FieldKeys';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: ' If you do not have a Fyers account, you can ' },
                        {
                            type: 'link',
                            text: 'open one here',
                            url: 'https://open-account.fyers.in/?utm-source=AP-Leads&utm-medium=AP1977',
                        },
                        { type: 'text', text: ' with following benefits ' },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'BROKERAGE: ₹20/order',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Go to ',
                        },
                        {
                            type: 'link',
                            text: 'myapi.fyers.in/dashboard',
                            url: 'https://myapi.fyers.in/dashboard',
                        },
                        {
                            type: 'text',
                            text: ' and create a new app. Fill out the following details',
                        },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'App Icon',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'App Name',
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'When creating the app, Copy and paste this url as your Redirect URL: ',
                                        },
                                        {
                                            type: 'special_link',
                                            text: 'https://algotest.in/confirm_broker/' + FYERS_TAG,
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'Give all available permissions for the app',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: `Post App Creation, Copy the App ID and the Secret ID of the generated App and paste them here`,
                        },
                    ],
                },
            },
        ],
    },
];

export const FYERS_INPUT_FIELD_LIST = [
    {
        name: API_KEY_IDENTITY,
        label: 'App ID',
        placeHolder: 'Enter App ID',
        defaultValue: '',
    },
    {
        name: API_SECRET_IDENTITY,
        label: 'Secret ID',
        placeHolder: 'Enter Secret ID',
        defaultValue: '',
    },
];

export const FYERS_LOGIN_METHOD = (appKey: string, broker_id: string) => {
    const redirect_uri = `https://algotest.in/confirm_broker/${FYERS_TAG}/${broker_id}`;
    const response_type = 'code';
    const state = broker_id;

    history.push(
        `https://api.fyers.in/api/v2/generate-authcode?client_id=${appKey}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
    );
};

export default instructions;
