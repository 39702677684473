export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, index) => start + index * step);

export const zipArrays = (a: Array<any>, b: Array<any>) => a.map((x, i) => [x, b[i]]);

export const negateArray = (arr: Array<number>) => arr.map((x) => -x);

export const squareArray = (arr: Array<number>) => arr.map((x) => x * x);

export const multiplyElementWise = (a: Array<number>, b: Array<number>) => a.map((x, i) => x * b[i]);

export const sumArray = (arr: Array<number>) => arr.reduce((a, b) => a + b, 0);

export const uniqueElements = (arr: Array<any>) => Array.from(new Set(arr));

export const flipObject = (data: object) => Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));

export const shuffle = (array: any) => {
    const arr = [...array];

    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    return arr;
};

export const unProxify = (val: any): object => {
    if (val instanceof Array) return val.map(unProxify);
    if (val instanceof Object) return Object.fromEntries(Object.entries(Object.assign({}, val)).map(([k, v]) => [k, unProxify(v)]));
    return val;
};
export const groupArray = (arr: Array<any>, size: number) => {
    const grouped = [];
    for (let i = 0; i < arr.length; i += size) {
        grouped.push(arr.slice(i, i + size));
    }
    return grouped;
};

export const getCumulativeSum = (arr: Array<number>) => {
    const cumulativeSum = (
        (sum) => (value: number) =>
            (sum += value)
    )(0);
    return arr.map(cumulativeSum);
};

//Both Array have to be of equal length
export const getCorrelation = (seriesA: Array<number>, seriesB: Array<number>) => {
    const num = seriesA.length;
    if (num === 0) {
        return 'NA';
    }
    const multiplied = multiplyElementWise(seriesA, seriesB);
    const sumA = sumArray(seriesA);
    const sumB = sumArray(seriesB);
    const squareA = squareArray(seriesA);
    const squareB = squareArray(seriesB);

    const numerator = num * sumArray(multiplied) - sumA * sumB;
    const denominator = Math.sqrt((num * sumArray(squareA) - sumA * sumA) * (num * sumArray(squareB) - sumB * sumB));
    return denominator === 0 ? 'NA' : numerator / denominator;
};

export const subtractElementWise = (a: Array<number>, b: Array<number>) => a.map((x, i) => x - b[i]);

export const maxConsecutivePositiveCount = (arr: Array<number>) => {
    let counter = 0;
    let answer = 0;
    let num = 0;
    for (let i = 0; i < arr.length; i++) {
        num = arr[i];
        if (num > 0) {
            counter += 1;
        } else {
            answer = Math.max(answer, counter);
            counter = 0;
        }
    }
    return Math.max(counter, answer);
};

export const get2DArray = (shapeX: number, shapeY: number) => {
    const ans = [];
    for (let i = 0; i < shapeX; i++) {
        ans.push(new Array(shapeY).fill(0));
    }
    return ans;
};

/**
 * Trims a 2D symetric array and returns non reflected combinations of values
 */
export const trim2DSymetricArrayCombinations = (data: Array<Array<number>>): Array<Array<number>> => {
    // NOTE: This algorithm can be significantly improved to care for speed
    const filteredRows: Array<Array<number>> = [];
    data.forEach((row, rowIndex) => {
        const cleanColumn: Array<number> = [];
        row.forEach((column, columnIndex) => {
            if (columnIndex >= rowIndex) {
                cleanColumn.push(Math.round((column + Number.EPSILON) * 100) / 100);
            }
        });
        filteredRows.push(cleanColumn.reverse());
    });
    return filteredRows;
};

export const generateHeatmapChartData = (
    data: Array<Array<number>>,
    rowNames: Array<string>
): Array<{ name: string; data: Array<{ x: string; y: number }> }> => {
    const chartData: Array<{ name: string; data: Array<{ x: string; y: number }> }> = [];
    const trimmedArray: Array<Array<number>> = trim2DSymetricArrayCombinations(data);
    const lastIndex: number = trimmedArray.length - 1;

    trimmedArray.forEach((row, rowIndex) => {
        const rowClone = [...row];
        const rowWithLabels = rowClone.map((column, columnIndex) => {
            return {
                x: rowNames[lastIndex - columnIndex],
                y: column,
            };
        });
        chartData.push({
            name: rowNames[rowIndex],
            data: rowWithLabels,
        });
    });

    return chartData;
};

export const compareJSON = (obj1: any, obj2: any) => {
    if (typeof obj1 !== typeof obj2) return false;
    if (typeof obj1 !== 'object') {
        return obj1 === obj2;
    }
    const obj1keys = Object.keys(obj1);
    const obj2keys = Object.keys(obj2);
    if (obj1keys.length !== obj2keys.length) {
        return false;
    }

    for (let i = 0; i < obj1keys.length; i++) {
        const currKey = obj1keys[i];
        if (!obj2.hasOwnProperty(currKey) || !compareJSON(obj1[currKey], obj2[currKey])) {
            return false;
        }
    }

    return true;
};
