import { generateRandomID } from 'constants/RandomID';
import { getPrice } from 'hooks/useLivePriceHook';
import { GreeksObjectType, TradeObjectType } from 'types/Analyser';
import { LivePricesStoreType } from 'types/Store';
import { sumArray } from './ArrayUtils';
import { ISOTimestamp, getDatesInRange, timeDifference } from './Date';
import { milliseconds_per_day } from './ResultUtils';

const StrikeGap = 1;

export const ANALYSER_WORKER_FILE = '/scripts/analyserWorker.min.js';
export const SIMULATOR_WORKER_FILE = '/scripts/simulatorWorker.min.js';

export const presetVals = [7, 30, 60];

export const SqoffTrade = (trade: TradeObjectType, prices: LivePricesStoreType) => {
    const { Symbol, Quantity } = trade;
    if (Quantity === 0) {
        return;
    }
    let ltp = Number(getPrice(Symbol, prices, false));
    ltp = isNaN(ltp) ? 0 : parseFloat(ltp.toFixed(2));
    const newtrade = {
        ...trade,
        _id: generateRandomID(),
        Quantity: Math.abs(Quantity),
        TradedPrice: ltp,
        Position: Quantity > 0 ? -1 : 1,
        TradedTime: ISOTimestamp(),
    };
    return newtrade;
};

export const Refprice_interest_rate = (
    timestamp: string,
    currExpiry: string,
    UnderlyingPrice: number,
    FutureData: { Expiry: string; TradedPrice: number }[]
) => {
    if (getDatesInRange(new Date(timestamp), currExpiry).length == 1) {
        // if expiry is today use underlying price
        return UnderlyingPrice;
    }

    const matchingFuture = FutureData.filter((item) => item.Expiry == currExpiry);
    if (matchingFuture.length > 0) {
        if (isNaN(matchingFuture[0].TradedPrice)) {
            return UnderlyingPrice;
        }
        return matchingFuture[0].TradedPrice;
    } else {
        let Time_Monthly = 0;
        let F_U_rate = 0;
        if (getDatesInRange(new Date(timestamp), FutureData[0].Expiry).length == 1) {
            // if today is monthly expiry
            Time_Monthly = Math.max(timeDifference(FutureData[1].Expiry, new Date(timestamp)) / (1000 * 60 * 60 * 24) / 365, 0);
            F_U_rate = Math.log(FutureData[1].TradedPrice / UnderlyingPrice) / Time_Monthly;
        } else {
            Time_Monthly = Math.max(timeDifference(FutureData[0].Expiry, new Date(timestamp)) / (1000 * 60 * 60 * 24) / 365, 0);
            F_U_rate = Math.log(FutureData[0].TradedPrice / UnderlyingPrice) / Time_Monthly;
        }
        const Time_Weekly = Math.max(timeDifference(currExpiry, new Date(timestamp)) / (1000 * 60 * 60 * 24) / 365, 0);
        const price = UnderlyingPrice * Math.exp(F_U_rate * Time_Weekly);

        return price;
    }
};

export function SummaryStats(priceRange: number[], payoffRange: number[], UnderlyingPrice: number, AUM: number | null) {
    const length = payoffRange?.length;
    const minPayoff = length === 0 ? 'NA' : Math.min(...payoffRange);
    const maxPayoff = length === 0 ? 'NA' : Math.max(...payoffRange);
    const isInfinteLoss = payoffRange[0] < payoffRange[1] || payoffRange[length - 2] > payoffRange[length - 1];
    const isInfinteProfit = payoffRange[0] > payoffRange[1] || payoffRange[length - 2] < payoffRange[length - 1];
    const maxLoss = isInfinteLoss ? 'NA' : minPayoff;
    const maxProfit = isInfinteProfit ? 'NA' : maxPayoff;

    const breakeven = payoffRange.reduce((acc: number[][], val, i) => {
        let intersection = null;
        if (i < length - 1) {
            if (i > 0 && val == 0 && (payoffRange[i - 1] != 0 || payoffRange[i + 1] != 0)) {
                intersection = priceRange[i];
            } else if (payoffRange[i] * payoffRange[i + 1] < 0) {
                intersection = Interpolate(priceRange[i], payoffRange[i], priceRange[i + 1], payoffRange[i + 1]);
            }
        }
        if (intersection) {
            intersection = parseFloat(intersection.toFixed(2));
            const perc = parseFloat(((intersection / UnderlyingPrice) * 100 - 100).toFixed(2));
            return [...acc, [intersection, perc]];
        }
        return acc;
    }, []);

    const maxLossPercent = isInfinteLoss || !AUM || maxLoss == 'NA' ? 'NA' : parseFloat(((maxLoss / AUM) * 100).toFixed(2));
    const maxProfitPercent = isInfinteProfit || !AUM || maxProfit == 'NA' ? 'NA' : parseFloat(((maxProfit / AUM) * 100).toFixed(2));

    const stdDev = standardDeviation(priceRange);
    const stdValues = [-2, -1, 1, 2];
    const stdArr = stdValues.map((item) => parseInt(String((UnderlyingPrice + item * stdDev) / StrikeGap)) * StrikeGap);

    let riskReward: string | number = 'NA';
    if (maxLoss != 'NA' && maxProfit != 'NA' && maxLoss * maxProfit < 0) {
        riskReward = Math.abs(parseFloat((maxLoss / maxProfit).toFixed(1)));
    }

    return {
        maxLoss,
        maxProfit,
        maxLossPercent,
        maxProfitPercent,
        breakeven,
        stdArr,
        riskReward,
    };
}

export const Interpolate = (x1: number, y1: number, x2: number, y2: number) => {
    return x1 - ((x2 - x1) / (y2 - y1)) * y1;
};

export const ValueAtInterpolation = (X: number, X1: number, Y1: number, X2: number, Y2: number) => {
    return ((X - X1) * (Y2 - Y1)) / (X2 - X1) + Y1;
};

export function standardDeviation(numbersArr: number[]) {
    const total = sumArray(numbersArr);

    const meanVal = total / numbersArr.length;

    const SDprep = numbersArr.reduce((acc, curr) => acc + Math.pow(curr - meanVal, 2), 0);

    const SDresult = Math.sqrt(SDprep / (numbersArr.length - 1));

    return parseFloat(SDresult.toFixed(2));
}

export const AccumulateGreeks = (GreeksData: Record<string, GreeksObjectType>) => {
    return GreeksData
        ? Object.keys(GreeksData).reduce(
              (acc, curr) => ({
                  Delta: acc.Delta + GreeksData[curr].Delta,
                  Gamma: acc.Gamma + GreeksData[curr].Gamma,
                  Theta: acc.Theta + GreeksData[curr].Theta,
                  Vega: acc.Vega + GreeksData[curr].Vega,
                  Rho: acc.Rho + GreeksData[curr].Rho,
              }),
              { Delta: 0, Gamma: 0, Theta: 0, Vega: 0, Rho: 0 }
          )
        : null;
};

export const CalcNumTradingDays = (data: string[], uniqueExpiries: string[], startDate: Date) => {
    const dateData = data.map((item: any) => {
        const date = new Date(item);
        date.setHours(0, 0, 0, 0);
        return date;
    });

    const DTEs = uniqueExpiries.reduce((acc, curr) => {
        const expiryDate = new Date(curr);
        expiryDate.setHours(0, 0, 0, 0);
        const timeleft = Math.abs(timeDifference(startDate, expiryDate));
        const tradingDays = dateData.filter((date: Date) => timeDifference(startDate, date) < timeleft);
        if (tradingDays.length > 0) {
            acc[curr] = { tradingDays: tradingDays.length, calendarDays: timeleft / milliseconds_per_day };
        }
        return acc;
    }, {} as Record<string, any>);

    const masterMap = presetVals.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: dateData.filter((item: Date) => timeDifference(startDate, item) < curr * milliseconds_per_day).length,
        }),
        {} as Record<string, number>
    );

    return { masterMap, DTEs };
};
