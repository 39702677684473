import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { ulid } from 'ulid';
import { ExposeGetValue } from 'utils/ExposeGetValue';
import { Tooltip } from '../Tooltip';

type NewRadioButtonProps = {
    items: string[];
    defaultValue?: string;
    onChange?: (e: string) => void;
    valueMap: { [key: string]: string };
    display?: boolean;
    type?: string;
    disabled?: boolean;
    label?: string;
    tooltipContent?: string;
    className?: string;
    isSecondary?: boolean;
    rounded?: boolean;
    separated?: boolean;
    updateOnClick?: boolean;
};

export const NewRadioButtons = React.forwardRef(
    (
        {
            items,
            defaultValue,
            onChange,
            valueMap,
            display,
            type,
            disabled,
            label,
            tooltipContent,
            className,
            rounded,
            separated,
            updateOnClick,
        }: NewRadioButtonProps,
        ref
    ) => {
        const length = items.length;
        const { current: name } = useRef(ulid());
        const [selected, setSelected] = useState(defaultValue);

        const handleChange = (e: any) => {
            setSelected(valueMap[e.target.value]);
            if (onChange) {
                onChange(valueMap[e.target.value]);
            }
        };

        const getValue = () => {
            return selected;
        };
        ExposeGetValue(ref, getValue);

        return (
            <div
                className={classNames('flex flex-wrap items-center justify-between gap-2', {
                    hidden: !display,
                    'flex-col !items-start': type === 'vertical',
                    'pointer-events-none opacity-80': disabled,
                })}>
                {label && (
                    <label className={classNames('flex items-center gap-2 text-xs font-normal leading-6 text-primaryBlack-600')}>
                        {label}
                        {tooltipContent && <Tooltip info={tooltipContent} />}
                    </label>
                )}

                <div className={classNames('flex w-full', { '!w-fit gap-1 md:gap-2': separated })}>
                    {items.map((item, index) => (
                        <label
                            key={index}
                            htmlFor={`toggle--input__${index}_${name}`}
                            className={classNames(
                                'cursor-pointer border border-primaryBlack-400 bg-white px-4 py-2 text-center text-xs text-primaryBlack-800',
                                ' md:text-xs/4',
                                'md:w-fit',
                                className,
                                {
                                    'border-secondaryBlue-900 !bg-secondaryBlue-900/5 !font-medium !text-secondaryBlue-900':
                                        selected === valueMap[item],
                                    'rounded-l': index == 0,
                                    'rounded-r': index == length - 1,
                                    '!rounded-l-full': rounded && index == 0,
                                    '!rounded-r-full': rounded && index == length - 1,
                                    '!rounded-full !px-3 !text-sm': separated,
                                    'w-full': !separated && !rounded,
                                }
                            )}>
                            <input
                                hidden
                                onChange={handleChange}
                                onClick={(e) => {
                                    if (updateOnClick) {
                                        handleChange(e);
                                    }
                                }}
                                id={`toggle--input__${index}_${name}`}
                                type='radio'
                                checked={selected === valueMap[item]}
                                disabled={disabled}
                                value={item}
                                className='hidden'
                            />
                            {item}
                        </label>
                    ))}
                </div>
            </div>
        );
    }
);
