import { UserLogin, UserLogout, UserRegister, UserRegisterConfirm, UserReset, UserResetConfirm } from 'API/AuthenticationAPI';
import history from 'history';
import mixpanel from 'mixpanel-browser';
import { authActions } from 'store/authStore';
import { showAlert } from 'utils/AlertUtils';
import { throttle } from 'utils/ResultUtils';
import { Dispatch, SetStateAction } from 'react';

export const LoginHandler = (phoneNumber: string, password: string, queryParams: any, dispatch: Dispatch<any>, isSignup: boolean = false) => {
    UserLogin(phoneNumber, password).then((loginStatus) => {
        if (loginStatus.AuthStatus) {
            showAlert.success('Successfully logged in');
            mixpanel.people.set({
                $name: loginStatus.UserName,
                $phone: phoneNumber,
            });
            dispatch(authActions.Login({ name: loginStatus.UserName, role: loginStatus.Role, phoneNumber }));
            if (isSignup) {
                queryParams.isSignup = String(isSignup);
            }
            const path = window.location.pathname;
            if (path.includes('feature')) {
                history.push({
                    pathname: path.replace('feature', ''),
                    state: queryParams,
                });
            } else {
                history.push({
                    pathname: '/dashboard',
                    state: queryParams,
                });
            }
        } else {
            showAlert.error(loginStatus.Msg);
        }
    });
};

export const LogoutHandler = throttle((dispatch: Dispatch<any>) => {
    UserLogout().then((status) => {
        if (status) {
            showAlert.success('Successfully Logged Out');
        } else {
            showAlert.error('Something Went Wrong');
        }
    });
    dispatch(authActions.Logout());
}, 1000);

export const ResetPasswordHandler = (
    phoneNumber: string,
    setPhoneNumberState: Dispatch<SetStateAction<string>>,
    setResetConfirmState: Dispatch<SetStateAction<boolean>>
) => {
    UserReset(phoneNumber).then((Response) => {
        if (Response.Status) {
            setPhoneNumberState(phoneNumber);
            setResetConfirmState(true);
            showAlert.success(Response.Msg);
        } else {
            showAlert.error(Response.Msg);
        }
    });
};

export const ConfirmResetPasswordHandler = (phoneNumber: string, otp: number, password: string, dispatch: Dispatch<any>) => {
    UserResetConfirm(phoneNumber, otp, password).then((Response) => {
        if (Response.Status) {
            showAlert.success(Response.Msg);
            LoginHandler(phoneNumber, password, {}, dispatch, false);
        } else {
            showAlert.error(Response.Msg);
        }
    });
};

export const RegisterHandler = (
    name: string,
    phoneNumber: string,
    password: string,
    referee: string,
    setRegisterConfirmState: Dispatch<SetStateAction<boolean>>,
    setPhoneNumberState: Dispatch<SetStateAction<string>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReferralError: Dispatch<SetStateAction<boolean>>
) => {
    UserRegister(name, phoneNumber, password, referee).then((Response) => {
        if (Response.Status) {
            setRegisterConfirmState(true);
            setPhoneNumberState(phoneNumber);
            showAlert.success(Response.Msg);
        } else {
            if (Response.Msg === 'invalid referral code') {
                setReferralError(true);
            } else {
                showAlert.error(Response.Msg ? Response.Msg : 'Something went wrong. Please try later');
            }
        }
        setLoading(false);
    });
};

export const ConfirmRegisterHandler = (
    phoneNumber: string,
    otp: number,
    password: string,
    setLoading: Dispatch<SetStateAction<boolean>>,
    queryParams: any,
    dispatch: Dispatch<any>
) => {
    const showOnboarding = true;
    UserRegisterConfirm(phoneNumber, otp, showOnboarding).then((Response) => {
        if (Response.Status) {
            showAlert.success(Response.Msg);
            mixpanel.track('signupConfirm', { phoneNumber: phoneNumber, otp: otp, showOnboarding: showOnboarding });
            LoginHandler(phoneNumber, password, queryParams, dispatch, showOnboarding);
        } else {
            showAlert.error(Response.Msg);
        }
        setLoading(false);
    });
};
