export const TrailStopLossLabels = ['Points', 'Percentage'];

export const TrailStopLossType = {
    Points: 'TrailStopLossType.Points',
    Percentage: 'TrailStopLossType.Percentage',
} as const;

export const SimpleTrailStopLossType = {
    pts: 'TrailStopLossType.Points',
    '%': 'TrailStopLossType.Percentage',
};

export const OverallTSLLabels = ['MTM', 'Premium %'];

export const OverallTSLTypes = {
    MTM: 'TrailStopLossType.Points',
    'Premium %': 'TrailStopLossType.Percentage',
};

export const defaultLockProfitConfig = { Type: 'None', Value: {} } as const;

export const defaultTrailStopLossType = TrailStopLossType['Points'];
export const defaultTrailStopLossConfig = { Type: 'None', Value: {} } as const;
