import { Close } from 'assets';
import classNames from 'classnames';
import { useClickedOutside } from 'hooks/useClickedOutside';
import { useKeyDown } from 'hooks/useKeyDown';
import React, { Dispatch, SetStateAction, useRef } from 'react';

type PopupProps = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>> | ((show: boolean) => void);
    disableClose?: boolean;
    title?: string;
    footer?: React.ReactNode;
    titleClass?: string;
    bodyClass?: string;
    closeClass?: string;
    children: React.ReactNode;
};

const Popup = ({ show, setShow, disableClose, children, title, footer, titleClass, bodyClass, closeClass }: PopupProps) => {
    const ref = useRef(null);

    useClickedOutside(disableClose ? () => {} : setShow, ref);
    useKeyDown(27, () => {
        disableClose ? () => {} : setShow(false);
    });

    if (!show) return null;

    return (
        <div className='fixed inset-0 left-0 right-0 top-0 z-50 flex h-full items-center justify-center overflow-y-auto overflow-x-hidden bg-black/70 p-3'>
            <div className={classNames('relative z-50 h-auto max-w-fit rounded-xl', 'md:max-w-7xl')} ref={ref}>
                {!disableClose && (
                    <button
                        type='button'
                        title='close'
                        onClick={() => setShow(false)}
                        className={classNames('absolute right-1 top-1 text-primaryBlack-500', closeClass)}>
                        <Close className='h-7 stroke-2 ' />
                    </button>
                )}
                {title && (
                    <div
                        className={classNames(
                            'flex justify-center break-words rounded-t-xl bg-primaryBlue-900 p-3 text-center text-lg font-semibold leading-9 text-white ',
                            'md:px-8 md:pb-5 md:pt-8 md:text-2xl',
                            titleClass
                        )}>
                        <p className='max-w-popuptitle'>{title}</p>
                    </div>
                )}
                <div
                    className={classNames('max-h-popupHeight overflow-auto overscroll-none bg-white p-3', 'md:p-8', bodyClass, {
                        'rounded-t-xl': !title,
                        'rounded-b-xl': !footer,
                    })}>
                    {children}
                </div>
                {footer && (
                    <div
                        className={classNames('flex items-center justify-end gap-5 rounded-b-xl bg-white px-3 pb-3 ', 'md:px-8 md:pb-8 md:text-3xl')}>
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup;
