import classNames from 'classnames';
import React, { MouseEvent } from 'react';

type ButtonProps = {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    quaternary?: boolean;
    success?: boolean;
    underlined?: boolean;
    bordered?: boolean;
    borderedRed?: boolean;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    landingPrimary?: boolean;
    landingUnderlined?: boolean;
    landingBordered?: boolean;
    tertiaryText?: boolean;
    disabledGray?: boolean;
    id?: string;
};

const Button = ({
    primary,
    secondary,
    tertiary,
    quaternary,
    success,
    underlined,
    bordered,
    borderedRed,
    disabled,
    loading,
    className,
    onClick,
    children,
    landingPrimary,
    landingUnderlined,
    landingBordered,
    tertiaryText,
    disabledGray,
    id,
}: ButtonProps) => {
    return (
        <button
            id={id}
            className={classNames(
                { 'border-2 border-primaryBlue-900 bg-primaryBlue-900 text-white hover:border-primaryBlue-800 hover:bg-primaryBlue-800': primary },
                { 'bg-none text-primaryBlue-900': secondary },
                { 'border-2 border-error-900 bg-error-900 text-white hover:border-error-800 hover:bg-error-800': tertiary },
                { 'text-error-900  hover:text-error-800 ': tertiaryText },
                { 'rounded-3xl bg-orange-500 text-white hover:bg-orange-700': quaternary },
                { 'border-2 border-green-600 bg-green-600 text-white hover:border-green-700 hover:bg-green-700': success },
                { 'text-primaryBlue-900 hover:underline hover:underline-offset-1': underlined },
                { 'border-2 border-primaryBlue-900 text-primaryBlue-900 hover:bg-primaryBlue-900 hover:text-white': bordered },
                { 'border-2 border-error-900 text-error-900 hover:border-error-800 hover:bg-error-800 hover:text-white': borderedRed },
                {
                    'border-2 border-secondaryBlue-900 bg-secondaryBlue-900 text-white hover:border-secondaryBlue-800 hover:bg-secondaryBlue-800':
                        landingPrimary,
                },
                { 'text-secondaryBlue-900 hover:underline hover:underline-offset-1': landingUnderlined },
                { 'border-2 border-secondaryBlue-900 text-secondaryBlue-900 hover:bg-secondaryBlue-900 hover:text-white': landingBordered },
                { 'cursor-not-allowed opacity-90': disabled },
                { 'cursor-not-allowed border-2 border-primaryBlack-400 bg-primaryBlack-400 text-white': disabledGray },
                'leading-2 flex items-center justify-center gap-2 rounded px-3 py-3 text-center text-xs font-semibold duration-75',
                'md:px-5 md:py-3  md:leading-4',
                className
            )}
            onClick={onClick}
            disabled={disabled || loading}>
            {children}
        </button>
    );
};

export default Button;
export * from './BackButton';
export * from './ShareButton';
