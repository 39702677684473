import { Key } from 'react';

const numberFormat = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 2,
});

const numericFormat = (Value: number) => {
    return numberFormat.format(Value);
};

const numericFormatWithSign = (Value: number) => {
    return `${Value < 0 ? '-' : ''}${numericFormat(parseFloat(Math.abs(Value).toFixed(2)))}`;
};

const currencyFormat = (Value: Key): string => {
    if (typeof Value !== 'number') {
        return String(Value);
    }
    return `₹ ${numericFormatWithSign(Value)}`;
};

export { numericFormat, currencyFormat, numericFormatWithSign };

export const NumberToWords = (num: Key) => {
    if (num === undefined) return;

    num = String(num);

    const ones: Record<string, string> = { 1: 'one', 2: 'two', 3: 'three', 4: 'four', 5: 'five', 6: 'six', 7: 'seven', 8: 'eight', 9: 'nine' },
        _tens: string[] = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
        tens: Record<string, string> = { 2: 'twenty', 3: 'thirty', 4: 'forty', 5: 'fifty', 6: 'sixty', 7: 'seventy', 8: 'eighty', 9: 'ninety' },
        above_tens: string[] = [
            'thousand',
            'million',
            'billion',
            'trillion',
            'quadrillion',
            'quintillion',
            'sextillion',
            'septillion',
            'octillion',
            'nonillion',
            'decillion',
            'undecillion',
            'duodecillion',
            'tredecillion',
            'quattuordecillion',
            'quindecillion',
            'sexdecillion',
            'septendecillion',
            'octodecillion',
            'novemdecillion',
            'vigintillion',
        ];

    const result = [];

    if (/^0+$/g.test(num)) return 'zero';

    num = num.replace(/,/g, '');
    const numArr = num.replace(/^0+/g, '').split(/(?=(?:...)*$)/);

    for (let count = 0; count < numArr.length; count++) {
        let _result = '',
            _num = numArr[count];

        _num = _num.replace(/^0+/g, '');
        if (_num.length == 3) {
            _result += ones[_num[0]] + ' hundred';
            _num = _num.substring(1, 3).replace(/^0+/g, '');
            if (_num.length > 0) _result += ' ';
        }
        if (_num.length == 1) {
            _result += ones[_num];
        } else if (_num.length == 2) {
            if (_num[0] == '1') {
                _result += _tens[Number(_num[1])];
            } else if (/.0/g.test(_num)) {
                _result += tens[_num[0]];
            } else {
                _result += tens[_num[0]] + ' ' + ones[_num[1]];
            }
        }

        if (_result != '') {
            if (numArr.length - count - 2 >= 0) _result += ' ' + above_tens[numArr.length - count - 2];
            result.push(_result);
        }
    }

    if (result[result.length - 1].includes('and') || result.length == 1) return result.join(', ');
    return result.slice(0, result.length - 1).join(', ') + ' ' + result[result.length - 1];
};
