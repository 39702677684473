import { LiveHookProvider } from 'hooks/useLivePriceHook';
import LayoutWrapper from 'layouts/LayoutWrapper';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import { Toaster } from 'sonner';
import store from 'store/reduxStore';
import 'styles/chart.scss';
import 'styles/globals.css';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, { debug: process.env.NODE_ENV === 'development' });

function MyApp({ Component, pageProps }) {
    return (
        <Provider store={store}>
            <LiveHookProvider>
                <LayoutWrapper {...pageProps}>
                    <Component {...pageProps} />
                </LayoutWrapper>
            </LiveHookProvider>
            <Toaster richColors position='top-right' visibleToasts={5} offset={'40px'} />
        </Provider>
    );
}

export default MyApp;
