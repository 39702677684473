import { ExchangeChargesType } from 'types/Cost';
import { REV_INSTRUMENT_KIND_MAPPING } from './Execution';
import { BSE, NSE } from './Tickers';

const BUY = 1;
const SELL = -1;
const FUT = REV_INSTRUMENT_KIND_MAPPING['FUT'];
const CE = REV_INSTRUMENT_KIND_MAPPING['CE'];
const PE = REV_INSTRUMENT_KIND_MAPPING['PE'];

export const SEBICharges = 10;

export const ClearingCharges = 0;

export const IPFT = {
    [FUT]: 10,
    [CE]: 50,
    [PE]: 50,
};

export const StampDuty = {
    [BUY]: {
        [FUT]: 200,
        [CE]: 300,
        [PE]: 300,
    },
    [SELL]: {
        [FUT]: 0,
        [CE]: 0,
        [PE]: 0,
    },
};

export const STT = {
    [BUY]: {
        [FUT]: 0,
        [CE]: 0,
        [PE]: 0,
    },
    [SELL]: {
        [FUT]: 1250,
        [CE]: 6250,
        [PE]: 6250,
    },
};

export const ExchangeCharges: ExchangeChargesType = {
    [BUY]: {
        [NSE]: {
            [FUT]: 190,
            [CE]: 5000,
            [PE]: 5000,
        },
        [BSE]: {
            [FUT]: 0,
            [CE]: 500,
            [PE]: 500,
        },
    },
    [SELL]: {
        [NSE]: {
            [FUT]: 190,
            [CE]: 5000,
            [PE]: 5000,
        },
        [BSE]: {
            [FUT]: 0,
            [CE]: 500,
            [PE]: 500,
        },
    },
};

export const BrokerageTypes: string[] = ['per order', 'per lot'];
export const BrokerageTypeMapping = {
    'per order': 'BrokerageType.PerOrder',
    'per lot': 'BrokerageType.PerLot',
};
export const PerOrder: string = BrokerageTypeMapping['per order'];
export const PerLot: string = BrokerageTypeMapping['per lot'];

export const DefaultCostObject = { taxes: { enabled: false }, brokerage: { enabled: false, value: 0, type: PerOrder } };

export const CostLocalStorageKey = 'cost-config';
