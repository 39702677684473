import { InstructionType } from 'types/Broker';

const instructions: InstructionType[] = [
    {
        type: 'list',
        items: [
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        { type: 'text', text: ' If you do not have a Wisdom Capital account, you can ' },
                        {
                            type: 'link',
                            text: 'open one here',
                            url: 'https://wisdomcapital.in/referral_chintan/',
                        },
                        // { type: 'text', text: ' with following benefits ' },
                    ],
                },
                sub_items: [
                    {
                        type: 'list',
                        items: [
                            {
                                type: 'list_item',
                                item: {
                                    type: 'paragraph',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'BENEFITS: ₹999/month for unlimited trading.',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Please to go to ',
                        },
                        {
                            type: 'link',
                            text: 'trade.wisdomcapital.in/dashboard ',
                            url: 'https://trade.wisdomcapital.in/dashboard#!/login',
                        },
                        {
                            type: 'text',
                            text: 'and login to your account',
                        },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'For any issue in any of the previous step, please contact Trading API Helpline: ',
                        },
                        { type: 'link', text: '01206633215', url: 'tel:01206633215' },
                    ],
                },
            },
            {
                type: 'list_item',
                item: {
                    type: 'paragraph',
                    items: [
                        {
                            type: 'text',
                            text: 'Obtain API key & Secret pair, for both the Interactive as well as Market Data, and copy/paste those in the respective fields below.',
                        },
                    ],
                },
            },
        ],
    },
];

export default instructions;
