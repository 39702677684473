import Tippy from '@tippyjs/react';
import React from 'react';

type SpecialTextTooltipProps = {
    name: string;
    length: number;
    placement?: 'top' | 'bottom' | 'left' | 'right';
};

export const SpecialTextTooltip = ({ name, length, placement = 'bottom' }: SpecialTextTooltipProps) => {
    const truncate = name?.length > length;
    const displayName = truncate ? `${name.slice(0, length - 3)}...` : name;

    return (
        <div className='group relative'>
            {truncate ? (
                <Tippy content={name} allowHTML placement={placement} interactive maxWidth={150} className=' break-words'>
                    <button>{displayName}</button>
                </Tippy>
            ) : (
                displayName
            )}
        </div>
    );
};
