export const Tickers = ['Nifty', 'Banknifty', 'Finnifty', 'Midcpnifty', 'Sensex', 'Bankex'];

export const TickersMap: Record<string, string> = {
    Nifty: 'NIFTY',
    Banknifty: 'BANKNIFTY',
    Finnifty: 'FINNIFTY',
    Midcpnifty: 'MIDCPNIFTY',
    Sensex: 'SENSEX',
    Bankex: 'BANKEX',
} as const;

export const NiftyTicker = TickersMap['Nifty'];
export const BankniftyTicker = TickersMap['Banknifty'];
export const FinniftyTicker = TickersMap['Finnifty'];
export const MidcapTicker = TickersMap['Midcpnifty'];
export const SensexTicker = TickersMap['Sensex'];
export const BankexTicker = TickersMap['Bankex'];
export const IndiaVixTicker = 'INDIAVIX';

export const defaultTicker = BankniftyTicker;
export const NSETickers = [NiftyTicker, BankniftyTicker, FinniftyTicker, MidcapTicker];
export const BSETickers = [SensexTicker, BankexTicker];

export const IndexLotMapping: Record<string, number> = {
    BANKNIFTY: 15,
    NIFTY: 50,
    FINNIFTY: 40,
    MIDCPNIFTY: 75,
    SENSEX: 10,
    BANKEX: 15,
} as const;

export const IndexStrikeGap: Record<string, number> = {
    BANKNIFTY: 100,
    NIFTY: 50,
    FINNIFTY: 50,
    MIDCPNIFTY: 25,
    SENSEX: 100,
    BANKEX: 100,
};

export const MaxLotSizeMapping: Record<string, number> = {
    BANKNIFTY: 60,
    NIFTY: 36,
    FINNIFTY: 45,
    MIDCPNIFTY: 56,
    SENSEX: 50,
    BANKEX: 40,
};

export const NSE = 'NSE';
export const BSE = 'BSE';
