import { Minus, Plus } from 'assets';
import classNames from 'classnames';
import { useRef } from 'react';
import { debounce } from 'utils/ResultUtils';

type NumberProps = {
    label?: string;
    value: number;
    min?: number;
    max?: number;
    step?: number;
    onChange: (value: number) => void;
    inputClass?: string;
    containerClass?: string;
    disabled?: boolean;
};

export function NumberAlt({ label, value, onChange, min, max, disabled = false, step = 1, inputClass = '', containerClass = '' }: NumberProps) {
    const numRef = useRef<HTMLInputElement>(null);

    const handleChange = (mul: number) => {
        let newValue = parseFloat(numRef.current!.value) + mul * step;
        if (min !== undefined) {
            newValue = Math.max(newValue, min);
        }
        if (max !== undefined) {
            newValue = Math.min(newValue, max);
        }

        if (isNaN(newValue)) return;

        numRef.current!.value = newValue.toString();
        onChange(newValue);
    };

    return (
        <div className='flex flex-col gap-2'>
            {label && <label className='text-xs'>{label}</label>}
            <div className={classNames('flex items-center rounded border', containerClass)}>
                <button title='Decrease' className='p-1' onClick={() => handleChange(-1)} disabled={disabled}>
                    <Minus className='h-3 w-3' />
                </button>
                <input
                    title='number'
                    ref={numRef}
                    // type='number'
                    defaultValue={value}
                    min={min}
                    max={max}
                    disabled={disabled}
                    onChange={debounce(() => handleChange(0), 1000)}
                    className={classNames(
                        'w-16 border-0 px-1 py-1 text-center text-sm [appearance:textfield] focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
                        inputClass
                    )}
                />
                <button
                    title='Increase'
                    disabled={value >= max! || disabled}
                    className='p-1 disabled:cursor-not-allowed '
                    onClick={() => handleChange(1)}>
                    <Plus className='h-3 w-3' />
                </button>
            </div>
        </div>
    );
}
