import { createSlice } from '@reduxjs/toolkit';

const initialAdminState = {
    CurrentUser: null,
    UsersList: [],
    PageCount: 0,
    TotalActiveUsers: 0,
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState: initialAdminState,
    reducers: {
        updateCurrentUser(state, action) {
            state.CurrentUser = { ...action.payload };
        },
        resetCurrentUser(state) {
            state.CurrentUser = null;
        },
        updateCurrentUserDetails(state, action) {
            state.CurrentUser = {
                ...state.CurrentUser,
                role: action.payload.role || state.CurrentUser.role,
                backtestQuota: action.payload.backtestQuota,
            };
        },
        addLimitedBacktestToCurrentUser(state, action) {
            state.CurrentUser = { ...state.CurrentUser, backtestPaid: state.CurrentUser.backtestPaid + action.payload };
        },
        addCreditsToCurrentUser(state, action) {
            state.CurrentUser = { ...state.CurrentUser, credits: action.payload };
        },
        updateUsersList(state, action) {
            state.UsersList = action.payload.users;
            if (action.payload.page_count) {
                state.PageCount = action.payload.page_count;
            }
            if (action.payload.total_active_users) {
                state.TotalActiveUsers = action.payload.total_active_users;
            }
        },
    },
});

export const adminActions = adminSlice.actions;
