import Tippy from '@tippyjs/react';
import { Help } from 'assets';
import 'tippy.js/dist/tippy.css';
import React from 'react';

type TooltipProps = {
    info?: string | JSX.Element;
    place?: 'top' | 'bottom' | 'left' | 'right';
    content?: JSX.Element;
    maxWidth?: number;
};

export const Tooltip = (props: TooltipProps) => {
    const info = props.info || (
        <ul>
            <li>This is a tooltip</li>
        </ul>
    );

    return (
        <Tippy content={info} allowHTML placement={props.place || 'top'} interactive maxWidth={props.maxWidth ?? 800}>
            <p>{props.content ? props.content : <Help className='h-4' />}</p>
        </Tippy>
    );
};
