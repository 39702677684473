import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { ExposeGetValue } from 'utils/ExposeGetValue';
import { Tooltip } from '../Tooltip';

type CheckboxProps = {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
    disabled?: boolean;
    id?: string;
    label?: string | JSX.Element;
    tooltipContent?: string;
    className?: string;
    inputName?: string;
};

export const Checkbox = React.forwardRef(({ onChange, value, disabled, id, label, tooltipContent, className }: CheckboxProps, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const getValue = () => {
        return inputRef.current!.checked;
    };

    const dummyChange = () => {};
    const handleChange = onChange || dummyChange;

    useEffect(() => {
        inputRef.current!.checked = value as boolean;
    }, [value]);

    ExposeGetValue(ref, getValue);
    return (
        <label
            className={classNames('flex cursor-pointer items-center gap-2 text-xsm font-normal leading-6 ', className, {
                'pointer-events-none opacity-80': disabled,
            })}
            htmlFor={id}>
            <input
                checked={value}
                disabled={disabled}
                ref={inputRef}
                className='h-4 w-4 cursor-pointer rounded border border-secondaryBlue-900 text-secondaryBlue-900 focus:ring-secondaryBlue-900'
                type='checkbox'
                id={id}
                onChange={handleChange}
            />
            {label}
            {tooltipContent && <Tooltip info={tooltipContent} />}
        </label>
    );
});
