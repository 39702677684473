export const RESULTS_LABELS_MAPPING = {
    OverallProfit: 'Overall Profit',
    NumberOfTrades: 'No. of Trades',
    AverageProfitPerTrade: 'Average Profit per Trade',
    WinningRatio: 'Win %',
    LosingRatio: 'Loss %',
    AverageProfitPerWinningTrade: 'Average Profit on Winning Trades',
    AverageProfitPerLosingTrade: 'Average Loss on Losing Trades',
    MaximumProfitInSingleTrade: 'Max Profit in Single Trade',
    MinimumProfitInSingleTrade: 'Max Loss in Single Trade',
    MaximumDrawdown: 'Max Drawdown',
    MaxDrawdownTotalDays: 'Duration of Max Drawdown',
    ReturnOverMaximumDrawdown: 'Return/MaxDD',
    RewardToRiskRatio: 'Reward to Risk Ratio',
    Expectancy: 'Expectancy Ratio',
    MaximumWinningStreak: 'Max Win Streak (trades)',
    MaximumLosingStreak: 'Max Losing Streak (trades)',
    MaximumTimeDrawdown: 'Max trades in any drawdown',
};

export const AGGREGATED_PORTFOLIO_LABELS_MAPPING = {
    OverallProfit: 'Overall Profit',
    NumberOfTrades: 'No. of Trades(Periods)',
    AverageProfitPerTrade: 'Average Profit per Period',
    WinningRatio: 'Win %(Periods)',
    LosingRatio: 'Loss %(Periods)',
    AverageProfitPerWinningTrade: 'Average Profit on Winning Periods',
    AverageProfitPerLosingTrade: 'Average Loss on Losing Periods',
    MaximumProfitInSingleTrade: 'Max Profit in Single Period',
    MinimumProfitInSingleTrade: 'Max Loss in Single Period',
    MaximumDrawdown: 'Max Drawdown',
    MaxDrawdownTotalDays: 'Duration of Max Drawdown',
    ReturnOverMaximumDrawdown: 'Return/MaxDD',
    RewardToRiskRatio: 'Reward to Risk Ratio',
    Expectancy: 'Expectancy Ratio',
    MaximumWinningStreak: 'Max Win Streak (Periods)',
    MaximumLosingStreak: 'Max Losing Streak (Periods)',
    MaximumTimeDrawdown: 'Max days in any drawdown(Periods)',
};

export const num_trade_key = 'NumberOfTrades';

export const RESULT_KEYS = Object.keys(RESULTS_LABELS_MAPPING);

export const NEUTRAL_VALUES = [
    'NumberOfTrades',
    'WinningRatio',
    'LosingRatio',
    'MaxDrawdownTotalDays',
    'ReturnOverMaximumDrawdown',
    'RewardToRiskRatio',
    'Expectancy',
    'MaximumWinningStreak',
    'MaximumLosingStreak',
    'MaximumTimeDrawdown',
];

export const CURRENCY_VALUES = [
    'OverallProfit',
    'AverageProfitPerTrade',
    'AverageProfitPerWinningTrade',
    'AverageProfitPerLosingTrade',
    'MaximumProfitInSingleTrade',
    'MinimumProfitInSingleTrade',
    'MaximumDrawdown',
];
