export const Expiry = ['Weekly', 'Next Weekly', 'Monthly'];

export const ExpiryType = {
    Weekly: 'ExpiryType.Weekly',
    'Next Weekly': 'ExpiryType.NextWeekly',
    Monthly: 'ExpiryType.Monthly',
} as const;

export const weeklyExpiry = ExpiryType['Weekly'];
export const nextWeeklyExpiry = ExpiryType['Next Weekly'];
export const monthlyExpiry = ExpiryType['Monthly'];
