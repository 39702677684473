import { BrokerMapping, IST_OFFSET, LOGIN_HOUR_START_UTC, SQOFF_HOUR_START_UTC, STG_HOUR_START_UTC, TRADING_HOUR_END_UTC } from 'constants/Broker';
import { BrokerType } from 'types/Broker';

export const EXECUTION_DEPLOY_BUFFER = 2;

export const getCurrentUTCTime = () => {
    const timestamp = new Date();
    const hr = timestamp.getUTCHours();
    const min = timestamp.getUTCMinutes();
    return hr * 60 + min;
};

export const getCurrentISTDateTime = () => {
    const timestamp = new Date();
    const currentOffset = timestamp.getTimezoneOffset();
    return new Date(timestamp.getTime() + (IST_OFFSET + currentOffset) * 60000);
};

export const IS_IN_BROKER_LOGIN_HOUR = () => {
    const current_time = getCurrentUTCTime();
    if (LOGIN_HOUR_START_UTC < TRADING_HOUR_END_UTC) {
        return current_time >= LOGIN_HOUR_START_UTC && current_time <= TRADING_HOUR_END_UTC;
    } else {
        return current_time >= LOGIN_HOUR_START_UTC || current_time <= TRADING_HOUR_END_UTC;
    }
};

export const IS_IN_ACTIVATE_STRATEGY_HOUR = () => {
    const current_time = getCurrentUTCTime();
    if (STG_HOUR_START_UTC < TRADING_HOUR_END_UTC) {
        return current_time >= STG_HOUR_START_UTC && current_time <= TRADING_HOUR_END_UTC;
    } else {
        return current_time >= STG_HOUR_START_UTC || current_time <= TRADING_HOUR_END_UTC;
    }
};

export const IS_IN_SQ_OFF_HOURS = () => {
    const current_time = getCurrentUTCTime();
    if (SQOFF_HOUR_START_UTC < TRADING_HOUR_END_UTC) {
        return current_time >= SQOFF_HOUR_START_UTC;
    } else {
        return current_time >= SQOFF_HOUR_START_UTC || current_time <= TRADING_HOUR_END_UTC;
    }
};

export const getCurrentUTCTimeStamp = () => {
    const timestamp = new Date();
    return timestamp.getTime();
};

export const misActivation = (exit_time: number) => {
    if (exit_time >= 15 * 60 + 24) {
        return 'For MIS Orders, Exit time should be strictly less than 15:24';
    }
    return false;
};

export const timeTillMktStart = () => {
    const current_time = getCurrentISTDateTime();
    const weekday = current_time.getDay();
    if (weekday === 0 || weekday === 6) {
        return 0;
    }
    const marketStart = new Date(current_time.getFullYear(), current_time.getMonth(), current_time.getDate(), 9, 15, 0, 0);
    return Math.round(Math.max(0, marketStart.getTime() - current_time.getTime()) / 1000);
};

export const isBrokerLoggedIn = (broker: BrokerType) => {
    const logged_until_time = new Date(broker?.logged_until)?.getTime();
    return getCurrentUTCTimeStamp() < logged_until_time;
};

export const getBrokerIdFromBid = (bid: string, brokerList: BrokerType[]) => {
    const reqBroker = Object.keys(BrokerMapping).filter((key) => BrokerMapping[key]?.tag === bid);
    if (reqBroker.length === 0) {
        return null;
    }
    const brokerId = brokerList?.find((broker) => broker.broker === reqBroker[0])?._id;
    return brokerId;
};
