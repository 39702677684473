import { createSlice } from '@reduxjs/toolkit';

const initialOptionChainState = {};

export const optionchainSlice = createSlice({
    name: 'optionchain',
    initialState: initialOptionChainState,
    reducers: {
        updateStore(state, action) {
            Object.keys(action.payload).reduce((acc, underlying) => {
                const chainData = action.payload[underlying];
                const { options, futures } = chainData.reduce(
                    (acc1, item) => {
                        if (item.InstrumentType == 0) {
                            acc1.futures[item.Expiry] = item;
                        } else {
                            if (!Object.keys(acc1.options).includes(item.Expiry)) {
                                acc1.options[item.Expiry] = {};
                            }
                            if (!Object.keys(acc1.options[item.Expiry]).includes(String(item.Strike))) {
                                acc1.options[item.Expiry][item.Strike] = {};
                            }
                            if (item.InstrumentType === -1) {
                                acc1.options[item.Expiry][item.Strike].put = item;
                            } else {
                                acc1.options[item.Expiry][item.Strike].call = item;
                            }
                        }
                        return acc1;
                    },
                    { options: {}, futures: {} }
                );

                state[underlying] = { options, futures };
            }, {});
        },
    },
});

export const optionchainActions = optionchainSlice.actions;
