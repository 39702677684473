import { ChevronArrowDown } from 'assets';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getLocalStorageNudge } from 'utils/NudgeUtils';
import { Tooltip } from '../Tooltip';
import NudgeTooltip from '../Tooltip/NudgeTooltip';

type SelectProps = {
    label?: string;
    secondaryLabel?: string;
    items: string[] | number[];
    valueMap: { [key: string]: any };
    defaultValue?: string;
    isInLegList?: boolean;
    tooltipContent?: string;
    disabled?: boolean;
    background?: boolean;
    display?: boolean;
    type?: 'vertical' | 'horizontal';
    inputClass?: string;
    nudgeMap?: { [key: string]: string };
    defaultNudgeKey?: string;
    minimal?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    iconClass?: string;
};

export const Select = React.forwardRef(
    (
        {
            label,
            secondaryLabel,
            items,
            valueMap,
            defaultValue,
            onChange,
            isInLegList,
            tooltipContent,
            disabled,
            background,
            display,
            type,
            inputClass,
            nudgeMap,
            defaultNudgeKey,
            minimal = false,
            iconClass,
        }: SelectProps,
        ref
    ) => {
        const [nudgeKey, setNudgeKey] = useState<string | undefined>(undefined);
        const inputRef = useRef<HTMLSelectElement>(null);
        const getValue = () => {
            return inputRef.current!.value;
        };
        const setValue = (val: string) => {
            inputRef.current!.value = val;
            if (nudgeMap) {
                handleNudge(val);
            }
        };

        const handleChange = (event: ChangeEvent<any>) => {
            if (onChange) {
                onChange(event);
            }
            if (nudgeMap) {
                handleNudge(event.target.value);
            }
        };

        const handleNudge = (val: string) => {
            const key = defaultNudgeKey ?? val;
            const isDisabled = getLocalStorageNudge(key);
            if (!isDisabled && nudgeMap && Object.keys(nudgeMap).includes(val)) {
                setNudgeKey(val);
            } else {
                setNudgeKey(undefined);
            }
        };

        useEffect(() => {
            if (defaultValue !== undefined) {
                inputRef.current!.value = defaultValue;
            }
        }, [defaultValue]);

        useImperativeHandle(ref, () => {
            return { getValue: getValue, setValue: setValue };
        });
        return (
            <div
                id={`select_${label}`}
                className={classNames('flex items-center gap-2', {
                    hidden: !display,
                    'justify-between': isInLegList,
                    'flex-col !items-start': type === 'vertical',
                    'pointer-events-none opacity-80': disabled,
                })}>
                {label && (
                    <label className={classNames('flex items-center gap-2 text-xsm font-normal leading-6')}>
                        {label}
                        {tooltipContent && <Tooltip info={tooltipContent} />}
                    </label>
                )}
                <div className='relative w-max'>
                    <select
                        title='input-select'
                        disabled={disabled}
                        className={classNames('w-max cursor-pointer appearance-none rounded py-2  text-xs', 'md:text-xs/4', inputClass, {
                            'border border-tertiaryBlue-900 bg-tertiaryBlue-900 text-white': background,
                            'border border-primaryBlue-200 text-primaryBlack-800': !background,
                            'border-0': minimal,
                        })}
                        ref={inputRef}
                        onClick={(e) => e.stopPropagation()}
                        onChange={handleChange}>
                        {items?.length === 0 && (
                            <option value='' disabled>
                                No Options
                            </option>
                        )}
                        {items.map((item) => (
                            <option value={valueMap[item]} key={item} className='w-max'>
                                {item}
                            </option>
                        ))}
                    </select>
                    <ChevronArrowDown
                        className={classNames('z-1 pointer-events-none absolute right-0 top-2.5 mr-3 h-4 w-4 ', 'md:top-2', iconClass, {
                            'bg-tertiaryBlue-900 text-white': background,
                            'bg-white': !background,
                            hidden: minimal,
                        })}
                    />
                    {nudgeMap && nudgeKey && (
                        <NudgeTooltip nudgeKey={defaultNudgeKey ?? nudgeKey} content={nudgeMap[nudgeKey]} setNudgeKey={setNudgeKey} />
                    )}
                </div>
                {secondaryLabel && <label className='text-sm font-normal leading-5'>{secondaryLabel}</label>}
            </div>
        );
    }
);
